import styles from '../../css/assessment.module.css';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import helpers from './helpers';

const LoadAssessment = ({ assessmentActions }) => {
    const location = useLocation();
    const { assessmentId } = useParams();
    const history = useHistory();

    const params = new URLSearchParams(location.search);

    let targetPage = helpers.urlBuilder('checkpoint');
    if (params.has('target')) {
        targetPage = params.get('target');
    }

    const redirector = to => {
        history.replace(
            to || helpers.appendAssessmentId(targetPage, assessmentId)
        );
    };

    useEffect(
        () => assessmentActions.loadAssessmentState(assessmentId, redirector),
        []
    );

    return (
        <div className={styles.loading}>
            <div className={styles.loadingTitle}>
                Retrieving your information.
            </div>
            <div className={styles.loadingIndicator} />
        </div>
    );
};

export default LoadAssessment;
