"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.topic = exports.TOPIC_PAGES = void 0;
var _engine = require("./engine");
/* *
 *
 * Below we will build construct the actual assessment flow.
 *
 * We'll start with the topics, topic pages, then build out the categories and
 * questions.
 *
 * */

var topic = exports.topic = {
  economicCrisis: 'handling a financial or economic crisis',
  benefitsEffectiveness: 'the effectiveness of my employee benefits',
  benefitsAffording: 'affording my employee benefits',
  employeeMotivation: 'motivating and retaining my key employees',
  keyPersonLoss: 'protecting myself and my business from the financial impact of an owner or key employee death or disability',
  // eslint-disable-line max-len
  businessContinuity: 'my plan for ensuring the business continues after I’m gone',
  financialIndependence: 'my plan for being financially independent as I transition out of the business',
  // eslint-disable-line max-len
  familySecurity: "my family's financial security if I become chronically ill or die",
  disability: "having enough income if I'm too sick or injured to work",
  estateEquality: 'my plans to equally/fairly distribute my estate to my heirs',
  estateExposure: 'my estate tax exposure'
};
var TOPIC_PAGES = exports.TOPIC_PAGES = [(0, _engine.topicPage)('top-concerns-and-priorities', 'My top concerns and priorities are...', [topic.benefitsAffording, topic.estateExposure, topic.benefitsEffectiveness, topic.businessContinuity, topic.disability, topic.economicCrisis, topic.financialIndependence, topic.employeeMotivation, topic.familySecurity, topic.keyPersonLoss, topic.estateEquality], 'To get your personalized report, tell us a little bit about your business.')];