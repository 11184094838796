import * as util from '../../util';
import helpers from './helpers';
import flow from '@principal/business-needs-assessment-tool-helpers';

const saveAssessmentState = (update, assessmentState) => {
    // This needs to be a wrapped in a thunk since promises begin execution right away.
    const saveAssessmentPromiseThunk = () =>
        fetch(`/needs/api/v1/needs/assessment`, {
            method: 'POST',
            mode: 'cors',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                id: assessmentState.syncState.id,
                topics: assessmentState.topics,
                answers: assessmentState.answers,
                advisor: assessmentState.advisor,
                writeCount: assessmentState.syncState.writeCount,
                assessmentId: assessmentState.assessmentId,
                timestamps: assessmentState.timestamps,
                createdAt: assessmentState.createdAt
            })
        })
            .then(res => res.json())
            .then(res => {
                update(assessmentState => {
                    if (assessmentState.syncState.writeCount > res.writeCount) {
                        return assessmentState;
                    }

                    const saveError = res.success
                        ? null
                        : { retrying: true, msg: res.message };

                    return {
                        ...assessmentState,
                        syncState: {
                            ...assessmentState.syncState,
                            writeCount: res.writeCount,
                            saveError,
                            modified: false
                        }
                    };
                });

                // If the save was not a success, throw an error so util.retry
                // can try again.
                if (!res.success) {
                    throw new Error(res.message);
                }
            });

    util.retry(saveAssessmentPromiseThunk).catch(err => {
        update(assessmentState => {
            return {
                ...assessmentState,
                syncState: {
                    ...assessmentState.syncState,
                    saveError: { retrying: false, msg: `${err}` },
                    modified: false
                }
            };
        });
    });
};

const loadAssessmentState = (update, assessmentId, redirector) => {
    fetch(
        `/needs/api/v1/needs/assessment/${assessmentId}`,
        {
            method: 'GET',
            mode: 'cors',
            headers: { 'content-type': 'application/json' }
        }
    )
        .then(res => res.json().then(json => [json, res]))
        .then(([json, res]) => {
            if (res.status === 404) {
                // If saved assessment is NOT found, use their given
                // assessmentIdParam and redirect the user to start of assessment.
                update(assessmentState => ({
                    ...assessmentState,
                    syncState: {
                        ...assessmentState.syncState,
                        firstLoad: false,
                        id: assessmentId,
                        modified: false
                    }
                }));
                return redirector(
                    helpers.appendAssessmentId(
                        helpers.topicUrlBuilder(flow.TOPIC_PAGES[0].url),
                        assessmentId
                    )
                );
            }

            if (json.success) {
                // If saved assessment IS found,
                // load 'er up! and redirect back to the page the user was on.
                update(assessmentState => ({
                    ...assessmentState,
                    syncState: {
                        ...assessmentState.syncState,
                        firstLoad: false,
                        writeCount: json.body.writeCount,
                        id: assessmentId,
                        modified: false,
                        loadError: null
                    },
                    topics: json.body.topics,
                    answers: json.body.answers,
                    advisor: json.body.advisor,
                    assessmentId: json.body.assessmentId,
                    timestamps: json.body.timestamps,
                    createdAt: json.body.createdAt
                }));
                return redirector();
            }

            update(assessmentState => ({
                ...assessmentState,
                syncState: {
                    ...assessmentState.syncState,
                    firstLoad: false,
                    id: assessmentId,
                    modified: false,
                    loadError: json.message
                }
            }));
            return redirector(helpers.urlBuilder('loading-error'));
        })
        .catch(err => {
            update(assessmentState => ({
                ...assessmentState,
                syncState: {
                    ...assessmentState.syncState,
                    id: assessmentId,
                    modified: false,
                    loadError: `${err}`
                }
            }));
            return redirector(helpers.urlBuilder('loading-error'));
        });
};

export default { loadAssessmentState, saveAssessmentState };
