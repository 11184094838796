@value principalBlue, pdsLightBlue, pdsDarkGray, pdsMediumGray, pdsMediumLightGray, pdsWarning from './common.module.css';
@value boldFont, largeScreen, smallScreen, hugeScreen from './common.module.css';
@value centeringBreakpoint: 1000px;
@value mobileBreakpoint: 550px;

/* Checkpoint Page */
.checkpoint {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
}

@media all and (max-width: largeScreen) {
    .checkpoint {
        font-size: 16px;
    }
}

.title {
    composes: title from './assessment.module.css';
    padding-top: 60px;
    line-height: 1.4;
    font-size: 2.2em;
}

.instructions {
    color: pdsDarkGray;
    width: 100%;

    text-align: center;

    margin: 35px;
}

/* Categories List */
.categories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    margin: auto;
    max-width: 1450px;
    min-width: 100%;
}

@media all and (min-width: hugeScreen) {
    .categories {
        margin: auto -300px;
    }
}

.category {
    max-width: 450px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: 1px solid pdsMediumLightGray;
    border-radius: 6px;

    margin: 10px 15px;
    padding: 30px;
}

.categoryImage {
    margin: 0px;
    padding: 0px;

    height: 100px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.categoryImage > img {
    max-height: 75px;
    max-width: 75px;
}

.categoryTitle {
    margin: 0px;
    min-height: 75px;
    width: 100%;
    text-align: center;
    font-size: 1.6em;
    font-family: boldFont;
}

.timeToComplete {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    font-size: 0.9em;
    text-align: center;
    flex-direction: row;
}
.etcText {
    padding-left: 13px;
    padding-top: 3px;
}

.categoryAction {
    width: 370px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.categoryAction a {
    justify-content: center;
}

@media all and (max-width: largeScreen) {
    .category {
        margin: 15px 5px;
        padding: 15px 5px;
    }

    .categoryAction {
        margin: 15px 0;
    }
}

@media all and (max-width: mobileBreakpoint) {
    .categoryAction {
        width: 90%;
    }
}

.categoryTopics {
    flex: 1;

    display: flex;
    flex-direction: column;
}

.categoryTopicList {
    margin-top: 20px;
    flex: 1;

    display: flex;
    flex-direction: column;
}

.topicRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    margin: 5px 0px 0px;
    padding: 5px 5px 0px;

    border-top: 1px solid pdsMediumGray;
}

.topicRow:first-child {
    border-top: 0px;
}

.topicSelected {
    width: 50px;

    margin-top: 5px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.topicRowDescription {
    color: pdsDarkGray;
    font-size: 1em;
    width: 100%;
}

.topicRowDescription.selected {
    font-family: boldFont;
}

@media all and (max-width: mobileBreakpoint) {
    .topicRow {
        padding: 3px;
        margin: 3px;
    }

    .topicSelected {
        width: 20px;
        margin-right: 5px;
        margin-left: 0px;
    }

    .topicRowDescription {
        margin-right: 5px;
    }
}

/* Useful info box */
.infobox {
    border: 1px solid pdsMediumLightGray;
    border-radius: 6px;

    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 20px;
    margin: 25px 0px;
}

.infoboxTitle {
    color: pdsDarkGray;
    font-size: 28px;

    margin: 10px 0px;
}

.infoboxInfoEmphasis {
    color: pdsDarkGray;
    font-size: 18px;
    text-align: center;

    font-family: boldFont;

    max-width: 80%;
}

.infoboxInfo {
    color: pdsDarkGray;
    font-size: 16px;
    text-align: center;

    max-width: 80%;
}

/* Call to action boxes */
.callsToAction {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;

    align-items: space-between;

    width: 100%;
}

.callToAction {
    border: 1px solid pdsLightBlue;
    border-radius: 6px;

    background: pdsLightBlue;

    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    margin: 10px;
    padding: 15px;
}

.ctaTitle {
    color: pdsDarkGray;
    font-family: boldFont;
    font-size: 24px;
    text-align: center;

    margin: 10px 0px;
}

.ctaDescription {
    color: pdsDarkGray;
    text-align: center;

    font-size: 20px;

    margin: 10px 0px;
    width: 80%;
}

.ctaButtonBox {
    padding: 10px;
}

.backgroundWhite {
    background: white;
}

.downloadPdfLink {
    margin-top: 20px;
}