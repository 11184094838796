@value pdsLightGray, pdsWhite, pdsMediumGray, pdsDarkMidnight, pdsDarkGray from './common.module.css';
@value smallScreen, largeScreen, hugeScreen, boldFont from './common.module.css';

.wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba( 246, 246, 246, .75);
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 100;
}

.modal {
    margin: 1em 0;
    border: 1px solid #e7e7e7;
    border-radius: 2%;
    max-height: 85vh;
    background: pdsWhite;
    box-shadow: 5px 5px 5px pdsMediumGray;
    overflow-y: scroll;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 20px 0;
    padding: 20px 20px 0 20px;
    font-size: 24px;
    font-family: boldFont;
    line-height: 24px;
    color: pdsDarkGray;
}

.content {
    display: flex;
    justify-content: center;
    padding: 0 30px;
    height: 85vh;
    width: 100%;
}

.close {
    background: transparent;
    bottom: 14px;
    border: none;
    cursor: pointer;
    position: relative;
    padding: 0;
    font-size: 1.5em;
}


@media screen and (min-width: largeScreen) {
    .modal {
        max-width: 50vw;
    }
}

@media screen and (min-width: 1024px) {
    .modal {
        max-width: 32vw;
    }
}
