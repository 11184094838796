import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { webAnalytics as wa} from "@principal/web-analytics";
import styles from '../../css/cta-footer.module.css';

import ContactCall from '../../img/contact-phone.png';
import ContactEmail from '../../img/contact-email.png';
import ContactFind from '../../img/contact-find.png';

const FooterCtaCard = ({ text, icon, subText, CtaElement, state, actions }) => (
    <div className={styles.card}>
        <img className={styles.icon} src={icon} alt={text} />
        <div className={styles.cardText}>{text}</div>
        <div className={styles.subText}>{subText}</div>
        <div className={styles.ctaWrapper}>
            <CtaElement state={state} actions={actions} />
        </div>
    </div>
);

FooterCtaCard.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    subText: PropTypes.string.isRequired,
    CtaElement: PropTypes.element.isRequired
};

const ButtonHelp = ({ buttonText, href }) => (
    <Link
        className={[styles.ctaButton, 'pds-button', 'pds-button-primary'].join(
            ' '
        )}
        to={{ pathname: href }}
        target="_blank"
        type="button"
    >
        {buttonText}
    </Link>
);

ButtonHelp.propTypes = {
    buttonText: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
};

const CtaFooter = ({ showContactFormModal }) => (
    <div className={styles.ctaFooter} id="cta-footer">
        <FooterCtaCard
            text="Give us a call."
            icon={ContactCall}
            subText="Business specialists are available Monday - Friday, 8 a.m. to 5 p.m. CT."
            CtaElement={() => (
                <div className={styles.phoneNumber}>
                    <Link to={{ pathname: 'tel:800-986-3343' }} target="_blank">
                        800-986-3343 <br />
                    </Link>
                    <div>Option 3, ext. 11300</div>
                </div>
            )}
        />
        <FooterCtaCard
            text="Email us your question."
            icon={ContactEmail}
            subText="Send us your questions and a business specialist will get back to you."
            CtaElement={() => (
                <ButtonHelp
                    buttonText="Email us your question"
                    href="mailto:connect@principal.com?subject=Business Needs Assessment Question"
                />
            )}
        />
        <FooterCtaCard
            text="Find a financial professional"
            icon={ContactFind}
            subText=""
            CtaElement={() => (
                <button
                    type="button"
                    className={[
                        styles.ctaButton,
                        'pds-button',
                        'pds-button-primary'
                    ].join(' ')}
                    data-gtm="call-to-action"
                    onClick={() => {
                        window.scrollTo(0, 0);
                        showContactFormModal();
                        wa.trackEvent('modalClick', {
                            clicktext: 'Find a financial professional',
                            modaltitle: 'Find a financial professional'
                        });
                    }}
                >
                    Find a financial professional
                </button>
            )}
        />
    </div>
);

export default CtaFooter;
