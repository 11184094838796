@value smallScreen from './common.module.css';

/* Contact Form Modal */
.form {
    display: flex;
}

.formRows {
    display: flex;
    flex-direction: column;
}

.label {
    color: #464646;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
}

.availability {
    color: #767676;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    padding-top: 10px;
}


.formRow {
    padding-bottom: 20px;
}

.nextButton {
    text-align: center;
    margin: 15px 0;
}

/* Confirmation Modal */
.confirmationHero {
    display: block;
    height: auto;
    width: 188px;
    margin: 1em auto;
}

.confirmationMsg, .confirmationCloseBtn {
    text-align: center;
}