/* eslint-disable no-return-assign */
import React from 'react';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { webAnalytics as wa} from "@principal/web-analytics";
import NavControls from '../nav';
import ScrollToTop from './scroll-to-top';
import Modal from '../modal';
import ContactForm from '../contact-form';
import Assessment from '../assessment';
import ContactValues from '../contact-data';

import styles from '../../css/app.module.css';
import stateHelpers from '../../state-helpers';
import Footer from '../footer';

const ASSESSMENT_STATE_ID = 'assessment';

const AppState = {
    Initial: () => ({
        [ASSESSMENT_STATE_ID]: Assessment.State.Initial(),
        ...NavControls.State.Initial('nav'),
        ...Modal.State.Initial('modal'),
        ...ContactForm.State.Initial('schedule')
    }),
    Actions: update => ({
        [ASSESSMENT_STATE_ID]: stateHelpers.mapActions(
            update,
            Assessment.State.Actions
        ),
        ...Modal.State.Actions(update),
        ...NavControls.State.Actions(update),
        ...ContactForm.State.Actions(update),
        requestContact: state => {
            // Build up the request body.
            const body = ContactValues(state);
            fetch(`/needs/api/v1/tasks/cta`, {
                method: 'POST',
                mode: 'cors',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify(body)
            });
        }
    })
};

const ADVISOR_CRUMBS = [
    {
        name: 'Financial Professional',
        url:
            'https://advisors.principal.com/wps/portal/advisor/products/business-solutions/approaches/business-needs-assessment'
    }
];

const NEEDS_CRUMBS = [
    { name: 'Employers', url: 'https://www.principal.com/employers' },
    {
        name: 'Business needs assessment',
        url: 'https://www.principal.com/businessneedsassessment'
    }
];

const showContactFormModal = actions => () =>
    actions.showModalContent(
        'modal',
        () => <span>Find a financial professional</span>,
        // eslint-disable-next-line no-shadow
        (state, actions) => (
            <ContactForm.Component state={state} actions={actions} />
        )
    );

const DisplayFlow = ({ state, actions }) => {
    const location = useLocation();

    const [crumbs, lastCrumb] = (() => {
        const isAdvisorPage = location.pathname === '/needs/assessment/advisor';
        const hasAdvisor = state.assessment && state.assessment.advisor.hasAdvisor;
        const isGuided = hasAdvisor && state.assessment.advisor.isGuided;

        // If Advisor is using the app
        if (isAdvisorPage || isGuided) {
            return [ADVISOR_CRUMBS, 'Assessment'];
        }

        // If potential customer is using link received from advisor.
        if (hasAdvisor) {
            return [[], ''];
        }

        // If user did not get to the app via an advisor.
        return [NEEDS_CRUMBS, 'Assessment'];
    })();


    return (
        <div className={styles.appWrapper}>
            <Switch>
                <Route exact path="/" > <></> </Route>
                <Route path="/needs">
                    <NavControls.Component
                        id="nav"
                        state={state}
                        actions={actions}
                        crumbs={crumbs}
                        lastCrumb={lastCrumb}
                    />
                    <Assessment.Component
                        assessmentState={state[ASSESSMENT_STATE_ID]}
                        assessmentActions={actions[ASSESSMENT_STATE_ID](
                            ASSESSMENT_STATE_ID
                        )}
                        showContactFormModal={showContactFormModal(actions)}
                    />
                </Route>
            </Switch>
        </div>
    );
};

DisplayFlow.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.objectOf(PropTypes.any).isRequired
};

class AppComponent extends React.Component {
    constructor(props) {
        super(props);
        this.skippedFirst = false;

        // Our initial state is the most recent state from the stream.
        this.state = props.states();
    }

    componentDidMount() {
        // Set a stream listener that calls setState on this component any time
        // the model state changes.
        const setState = this.setState.bind(this);
        const { states } = this.props; // This is for eslint...
        states.map(state => setState(state));
        wa.trackPageView({
            content: {
                businessline: 'benefits-and-protection',
                intendedaudience: 'financial-professional, individual',
                purpose: 'engagement'
            },
            page: {
                author: 'Team43', 
                compliancedate: '', 
                documenttype: 'webapp',
                language: 'english'
          },
        });
    }

    render() {
        const state = this.state;
        const { actions } = this.props;
        return (
            <main className={styles.mainApp}>
                <BrowserRouter>
                    <ScrollToTop />
                    <DisplayFlow state={state} actions={actions} />
                    <Footer.Component />
                </BrowserRouter>
                <Modal.Component id="modal" state={state} actions={actions} />
            </main>
        );
    }
}

AppComponent.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    states: PropTypes.func.isRequired
};

export default {
    State: AppState,
    Component: AppComponent
};
