"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.retirementIncomeSufficient = exports.retirementIncomeSourcesPublicForProfit = exports.retirementIncomeSourcesPrivateForProfit = exports.retirementIncomeSourcesNonProfit = exports.illnessesSafety = exports.illnessesPlans = exports.estateUpToDate = exports.estatePlanning = exports.estateExposure = exports.estateEquitable = exports["default"] = void 0;
var _engine = require("../engine");
var _categories = require("../categories");
var _helpers = require("./helpers");
var _profile = require("./profile");
var GROUP_TITLE = 'Protect your lifestyle';

/* * * * * * * * * *
 * Questions - Protect your lifestyle
 * * * * * * * * * */

var retirementIncomeSourcesAnswers = [(0, _engine.answer)('retirement-plan', 'My qualified retirement plan (i.e. 401(k) or profit sharing)'), (0, _engine.answer)('rental-properties', 'Rental properties'), (0, _engine.answer)('personal-investments', 'Personal investments'), (0, _engine.answer)('benefit-plan', 'My nonqualified key employee benefit plan'), (0, _engine.answer)('unsure', 'I’m not sure')];
var retirementIncomeSourcesHelper = function retirementIncomeSourcesHelper(id, answerList, filter) {
  return (0, _engine.checkboxQuestion)(id, _categories.CAT_LIFESTYLE, GROUP_TITLE, 'Which of the following sources will contribute to your retirement income?', answerList.concat(retirementIncomeSourcesAnswers), filter);
};
var retirementIncomeSourcesNonProfit = exports.retirementIncomeSourcesNonProfit = retirementIncomeSourcesHelper('retirement-income-sources-non-profit', [], function (answers) {
  return (0, _engine.checkAnswer)(answers, _profile.businessType, 'non-profit');
});
var retirementIncomeSourcesPublicForProfit = exports.retirementIncomeSourcesPublicForProfit = retirementIncomeSourcesHelper('retirement-income-sources-public', [(0, _engine.answer)('sale-of-shares', 'Shares or stock I own')], function (answers) {
  return (0, _engine.checkAnswer)(answers, _profile.businessType, 'profit') && (0, _engine.checkAnswer)(answers, _profile.businessPublic, 'yes');
});
var retirementIncomeSourcesPrivateForProfit = exports.retirementIncomeSourcesPrivateForProfit = retirementIncomeSourcesHelper('retirement-income-sources-private', [(0, _engine.answer)('sale-of-business', 'Sale of my business')], function (answers) {
  return (0, _engine.checkAnswer)(answers, _profile.businessType, 'profit') && (0, _engine.checkAnswer)(answers, _profile.businessPublic, 'no');
});
var retirementIncomeSufficient = exports.retirementIncomeSufficient = (0, _engine.radioQuestion)('retirement-income-sufficient', _categories.CAT_LIFESTYLE, GROUP_TITLE, 'Have you had an analysis done to determine whether these retirement income sources are sufficient to meet your projected needs?', _helpers.YES_NO, function (answers) {
  var questions = [retirementIncomeSourcesNonProfit, retirementIncomeSourcesPublicForProfit, retirementIncomeSourcesPrivateForProfit];
  var atLeastOneAnswered = questions.some(function (qstn) {
    return (0, _engine.isAnswered)(qstn, (0, _engine.getAnswer)(answers, qstn));
  });
  var notJustUnsure = questions.map(function (qstn) {
    return !(0, _engine.checkboxOnlyWithAnswers)(answers, qstn, ['unsure']);
  }).every(function (a) {
    return a;
  });
  if (atLeastOneAnswered && notJustUnsure) return true;
  return false;
});
var estatePlanning = exports.estatePlanning = (0, _engine.radioQuestion)('estate-planning', _categories.CAT_LIFESTYLE, GROUP_TITLE, 'Do the plans you have in place help to ensure your estate goes to your loved ones?', _helpers.YES_NO_UNSURE.concat([(0, _engine.answer)('no-estate-plans', "I don't currently have any estate plans in place")]));
var estateUpToDate = exports.estateUpToDate = (0, _engine.radioQuestion)('estate-plans-up-to-date', _categories.CAT_LIFESTYLE, GROUP_TITLE, 'When were your estate plans last reviewed?', _helpers.TWO_FIVE_OVER, function (answers) {
  return (0, _engine.checkAnswer)(answers, estatePlanning, 'yes');
});
var estateEquitable = exports.estateEquitable = (0, _engine.radioQuestion)('estate-equitable', _categories.CAT_LIFESTYLE, GROUP_TITLE, 'Are you comfortable with the distribution of your estate between family members?', _helpers.YES_NO_UNSURE);
var estateExposure = exports.estateExposure = (0, _engine.radioQuestion)('estate-exposure', _categories.CAT_LIFESTYLE, GROUP_TITLE, 'Have you had an analysis done on your potential Estate Tax exposure?', _helpers.YES_NO_UNSURE);
var illnessesSafety = exports.illnessesSafety = (0, _engine.radioQuestion)('illnesses-safety', _categories.CAT_LIFESTYLE, GROUP_TITLE, 'Do you have solutions in place to protect against an unexpected death and chronic illness that may impact you and your family’s financial security?', _helpers.YES_NO_UNSURE);
var illnessesPlans = exports.illnessesPlans = (0, _engine.radioQuestion)('illnesses-plans', _categories.CAT_LIFESTYLE, GROUP_TITLE, 'Do you have plans in place to protect yourself and your business from an unexpected illness or injury?', _helpers.YES_NO_UNSURE);
var _default = exports["default"] = [retirementIncomeSourcesNonProfit, retirementIncomeSourcesPublicForProfit, retirementIncomeSourcesPrivateForProfit, retirementIncomeSufficient, estatePlanning, estateUpToDate, estateEquitable, estateExposure, illnessesSafety, illnessesPlans];