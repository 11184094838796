"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _business = _interopRequireDefault(require("./business"));
var _employees = _interopRequireDefault(require("./employees"));
var _lifestyle = _interopRequireDefault(require("./lifestyle"));
var _categories = require("../categories");
var _helpers = require("./helpers");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var generate = function generate(categoryId, answers) {
  switch (categoryId) {
    case _categories.CAT_BUSINESS:
      return (0, _business["default"])(answers);
    case _categories.CAT_EMPLOYEES:
      return (0, _employees["default"])(answers);
    case _categories.CAT_LIFESTYLE:
      return (0, _lifestyle["default"])(answers);
    default:
      throw Error('Error: Category not found!');
  }
};
var _default = exports["default"] = {
  generate: generate,
  scoreHelper: _helpers.scoreHelper,
  SCORE: _helpers.SCORE,
  ICONS: _helpers.ICONS,
  getBusinessName: _helpers.getBusinessName
};