"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transferType = exports.sellToWho = exports.keyEmployeeLoss = exports.financiallyProtected = exports["default"] = exports.buySellImplemented = exports.buySellCurrent = exports.businessRecentlyValued = exports.businessRecentValue = exports.businessExitPlan = exports.businessExitETA = exports.businessEstimatedValue = void 0;
var _engine = require("../engine");
var _categories = require("../categories");
var _helpers = require("./helpers");
var _profile = require("./profile");
var GROUP_TITLE = 'Protect your business';

/* * * * * * * * * *
 * Questions - Protect your business
 * * * * * * * * * */

var businessExitETA = exports.businessExitETA = (0, _engine.radioQuestion)('business-exit-eta', _categories.CAT_BUSINESS, GROUP_TITLE, 'How long until you plan to transition out of this business?', [(0, _engine.answer)('1-5', '1-5 years'), (0, _engine.answer)('5-10', '5-10 years'), (0, _engine.answer)('10+', '10+ years'), (0, _engine.answer)('no-plan', 'I don’t intend to sell or transfer my business right now')], _profile.privateForProfit);
var businessExitPlan = exports.businessExitPlan = (0, _engine.checkboxQuestion)('business-exit-plan', _categories.CAT_BUSINESS, GROUP_TITLE, 'When the time is right, what will transitioning your business look like for you?', [(0, _engine.answer)('consulting', 'Consulting with your current company for a period of time'), (0, _engine.answer)('new-company', 'Creating a new company'), (0, _engine.answer)('reduced-hours', 'Staying with your current company but reducing your hours'), (0, _engine.answer)('traditional-retirement', 'Traditional retirement'), (0, _engine.answer)('workforce', 'Moving away from business ownership and (re)joining the workforce'), (0, _engine.answer)('selling-business', 'Selling my business')], _profile.privateForProfit);
var businessRecentlyValued = exports.businessRecentlyValued = (0, _engine.radioQuestion)('business-recently-valued', _categories.CAT_BUSINESS, GROUP_TITLE, 'Have you recently had your business valued?', _helpers.YES_NO, _profile.privateForProfit);
var VALUE_ANSWERS = [(0, _engine.answer)('0-500', '$0 - $500,000'), (0, _engine.answer)('500-1000', '$500,001 - $1,000,000'), (0, _engine.answer)('1000-3000', '$1,000,001 - $3,000,000'), (0, _engine.answer)('3000-5000', '$3,000,001 - $5,000,000'), (0, _engine.answer)('5000+', '$5,000,001+'), (0, _engine.answer)('unsure', "I'm not sure")];
var businessRecentValue = exports.businessRecentValue = (0, _engine.radioQuestion)('recent-value', _categories.CAT_BUSINESS, GROUP_TITLE, 'What is the provided value?', VALUE_ANSWERS, function (answers) {
  return (0, _engine.checkAnswer)(answers, businessRecentlyValued, 'yes');
});
var businessEstimatedValue = exports.businessEstimatedValue = (0, _engine.radioQuestion)('estimated-value', _categories.CAT_BUSINESS, GROUP_TITLE, 'What is your estimated value?', VALUE_ANSWERS, function (answers) {
  return (0, _engine.checkAnswer)(answers, businessRecentlyValued, 'no');
});
var buySellImplemented = exports.buySellImplemented = (0, _engine.radioQuestion)('buy-sell-inplace', _categories.CAT_BUSINESS, GROUP_TITLE, 'Do you have a buy-sell agreement in place?', _helpers.YES_NO_UNSURE, _profile.privateForProfit);
var buySellCurrent = exports.buySellCurrent = (0, _engine.radioQuestion)('buy-sell-current', _categories.CAT_BUSINESS, GROUP_TITLE, 'When was it last reviewed or updated?', _helpers.TWO_FIVE_OVER, function (answers) {
  return (0, _engine.checkAnswer)(answers, buySellImplemented, 'yes');
});
var transferType = exports.transferType = (0, _engine.radioQuestion)('type-of-transfer', _categories.CAT_BUSINESS, GROUP_TITLE, 'How do you intend to transfer (sell or gift) your business?', [(0, _engine.answer)('sell', 'Sell'), (0, _engine.answer)('gift', 'Gift to family member(s)'), (0, _engine.answer)('combo', 'Combination gift and sale')], _profile.privateForProfit);
var sellToWho = exports.sellToWho = (0, _engine.checkboxQuestion)('sell-to-who', _categories.CAT_BUSINESS, GROUP_TITLE, 'To whom will you sell it?', [(0, _engine.answer)('third-party', 'Third party'), (0, _engine.answer)('key-employee', 'Key employee(s)'), (0, _engine.answer)('shareholders', 'Shareholder(s)'), (0, _engine.answer)('sell-to-family', 'Family member(s)')], function (answers) {
  return (0, _engine.checkAnswer)(answers, transferType, 'sell') || (0, _engine.checkAnswer)(answers, transferType, 'combo');
});
var financiallyProtected = exports.financiallyProtected = (0, _engine.radioQuestion)('financially-protected', _categories.CAT_BUSINESS, GROUP_TITLE, 'Do you feel confident your business would be financially protected in the event of a death, disability, bankruptcy, divorce or departure of you or another owner?', _helpers.YES_NO_UNSURE, _profile.privateForProfit);
var keyEmployeeLoss = exports.keyEmployeeLoss = (0, _engine.radioQuestion)('key-employee-loss', _categories.CAT_BUSINESS, GROUP_TITLE, 'Is the business financially protected in the event of a loss of a key employee due to death, disability or termination of employment?', _helpers.YES_NO_UNSURE);
var _default = exports["default"] = [businessRecentlyValued, businessRecentValue, businessEstimatedValue, buySellImplemented, buySellCurrent, businessExitETA, businessExitPlan, transferType, sellToWho, financiallyProtected, keyEmployeeLoss];