/* eslint-disable react/prop-types */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import { webAnalytics as wa } from '@principal/web-analytics';
import PropTypes from 'prop-types';

import flow from '@principal/business-needs-assessment-tool-helpers';

import styles from '../../css/assessment-question.module.css';

import formatText from './text-formatter';

import ProgressBar from './progress';
import { BackButton, NextButton } from './nav-buttons';
import Tooltip from '../tooltip/tooltip';

import { stateOptions } from '../../constants/index';

const CheckboxAnswer = ({ questionId, answer, userAnswer, answerAction }) => (
    <div className={[styles.checkboxWrapper, 'pds-checkbox'].join(' ')}>
        <input
            name={`${questionId}-${answer.id}`}
            id={`${questionId}-${answer.id}`}
            type="checkbox"
            onChange={() => {
                answerAction(questionId, answer.id);
                wa.trackEvent('modalClick', {
                    clicktext: 'type-radio',
                    modaltitle: 'Checkbox-Answer'
                });
            }}
            checked={userAnswer && userAnswer[answer.id] ? 'checked' : false}
        />
        <label htmlFor={`${questionId}-${answer.id}`}>
            {answer.description}
        </label>
    </div>
);

CheckboxAnswer.defaultProps = { userAnswer: {} };

CheckboxAnswer.propTypes = {
    questionId: PropTypes.string.isRequired,
    answer: PropTypes.shape({
        id: PropTypes.string,
        description: PropTypes.string
    }).isRequired,
    /* eslint-disable react/require-default-props */
    userAnswer: PropTypes.objectOf(PropTypes.bool).isRequired,
    answerAction: PropTypes.func.isRequired
};

const RadioAnswer = ({ questionId, answer, userAnswer, answerAction }) => (
    <div className="pds-radio">
        <input
            name={questionId}
            id={`${questionId}-${answer.id}`}
            type="radio"
            onChange={() => {
                answerAction(questionId, answer.id);
                wa.trackEvent('modalClick', {
                    clicktext: 'type-radio',
                    modaltitle: 'radio-answer'
                });
            }}
            checked={answer.id === userAnswer}
        />
        <label htmlFor={`${questionId}-${answer.id}`}>
            {answer.description}
        </label>
    </div>
);

RadioAnswer.propTypes = {
    questionId: PropTypes.string.isRequired,
    answer: PropTypes.shape({
        id: PropTypes.string,
        description: PropTypes.string
    }).isRequired,
    /* eslint-disable react/require-default-props */
    userAnswer: PropTypes.string,
    answerAction: PropTypes.func.isRequired
};

const SelectAnswer = ({ questionId, userAnswer, answerAction }) => (
    <div className={[styles.inputWrapper, 'pds-select'].join(' ')}>
        <select
            id={`${questionId}-answer`}
            onChange={evt => {
                answerAction(questionId, evt.target.value);
                wa.trackEvent('modalClick', {
                    clicktext: 'userAnswer',
                    modaltitle: 'select-answer'
                });
            }}
            value={userAnswer}
            defaultValue=""
        >
            <option value="" disabled={true}>
                Select State
            </option>
            {stateOptions.map(state => {
                return (
                    <option key={state.key} value={state.value}>
                        {state.label}
                    </option>
                );
            })}
        </select>
    </div>
);

SelectAnswer.propTypes = {
    questionId: PropTypes.string.isRequired,
    /* eslint-disable react/require-default-props */
    userAnswer: PropTypes.string,
    answerAction: PropTypes.func.isRequired
};

const InputAnswer = ({ questionId, userAnswer, answerAction }) => (
    <div className={[styles.inputWrapper, 'pds-input'].join(' ')}>
        <input
            name={questionId}
            id={`${questionId}-answer`}
            type="text"
            onChange={evt => {
                answerAction(questionId, evt.target.value);
                wa.trackEvent('modalClick', {
                    clicktext: 'type-text',
                    modaltitle: 'input-answer'
                });
            }}
            value={userAnswer ? userAnswer : ''}
        />
    </div>
);

InputAnswer.propTypes = {
    questionId: PropTypes.string.isRequired,
    /* eslint-disable react/require-default-props */
    userAnswer: PropTypes.string,
    answerAction: PropTypes.func.isRequired
};

const Answers = ({
    questionId,
    questionType,
    answers,
    userAnswer,
    answerAction
}) => {
    if (questionType === flow.QUESTION_TYPE.INPUT) {
        return (
            <InputAnswer
                questionId={questionId}
                userAnswer={userAnswer}
                answerAction={answerAction}
            />
        );
    }

    if (questionType === flow.QUESTION_TYPE.SELECT) {
        return (
            <SelectAnswer
                questionId={questionId}
                userAnswer={userAnswer}
                answerAction={answerAction}
            />
        );
    }

    const AnswerComponent =
        questionType === flow.QUESTION_TYPE.CHECKBOX
            ? CheckboxAnswer
            : RadioAnswer;
    return (
        <div>
            {answers.map(answer => (
                <AnswerComponent
                    key={answer.id}
                    questionId={questionId}
                    answer={answer}
                    userAnswer={userAnswer}
                    answerAction={answerAction}
                />
            ))}
        </div>
    );
};

Answers.propTypes = {
    questionId: PropTypes.string.isRequired,
    questionType: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            description: PropTypes.string
        })
    ).isRequired,
    userAnswer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.objectOf(PropTypes.bool)
    ]).isRequired,
    answerAction: PropTypes.func.isRequired
};

const QuestionBody = ({ id, type, question, instructions }) => {
    if (type === flow.QUESTION_TYPE.INPUT) {
        return (
            <label
                htmlFor={`${id}-answer`}
                className={[styles.body, styles.inputQuestion].join(' ')}
            >
                {formatText(question)}
            </label>
        );
    }

    if (type === flow.QUESTION_TYPE.SELECT) {
        return (
            <label
                htmlFor={`${id}-answer`}
                className={[styles.body, styles.inputQuestion].join(' ')}
            >
                {formatText(question)}
            </label>
        );
    }

    return (
        <div className={styles.bodyWrapper}>
            <div className={styles.body}>
                <span>{formatText(question)}</span>
                <span className={styles.tooltip}>
                    {instructions.type === flow.INSTRUCTIONS_TYPE.TOOLTIP ? (
                        <Tooltip.Component
                            text={instructions.data.text}
                            position={instructions.data.position}
                        />
                    ) : null}
                </span>
            </div>
            {type !== flow.QUESTION_TYPE.CHECKBOX ? null : (
                <div className={styles.selectAll}>Select all that apply</div>
            )}
        </div>
    );
};

QuestionBody.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired
};

const NavButtons = ({ testMode, nextUrl, question, userAnswer }) =>
    testMode ? (
        <div className={styles.controls} />
    ) : (
        <div className={styles.controls}>
            <BackButton />
            <NextButton
                nextUrl={nextUrl}
                disabled={!flow.isAnswered(question, userAnswer)}
            />
        </div>
    );

export const QuestionAndAnswers = ({
    userAnswer,
    question,
    nextUrl,
    answerAction,
    testMode
}) => {
    const instructions = question.instructions(userAnswer);

    return (
        <div className={styles.question}>
            <h1 className={styles.category}>{question.group}</h1>
            <QuestionBody
                id={question.id}
                type={question.type}
                question={question.question}
                instructions={instructions}
            />
            <Answers
                questionId={question.id}
                questionType={question.type}
                answers={question.answers}
                userAnswer={userAnswer}
                answerAction={answerAction}
            />
            {instructions.type === flow.INSTRUCTIONS_TYPE.SUBTEXT ? (
                <div className={styles.instructions}>
                    {question.instructions(userAnswer).data.text}
                </div>
            ) : null}
            <NavButtons
                userAnswer={userAnswer}
                question={question}
                nextUrl={nextUrl}
                testMode={testMode}
            />
        </div>
    );
};

const Question = ({
    question,
    userAnswer,
    nextUrl,
    answerAction,
    questionIndex,
    questionCount,
    testMode
}) => (
    <div className={styles.wrapper}>
        <ProgressBar
            pos={questionIndex + (userAnswer === undefined ? 0 : 1)}
            count={questionCount}
        />
        <QuestionAndAnswers
            question={question}
            nextUrl={nextUrl}
            answerAction={answerAction}
            userAnswer={userAnswer}
            testMode={testMode}
        />
    </div>
);

Question.propTypes = {
    question: PropTypes.shape({
        answers: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                description: PropTypes.string
            })
        ).isRequired,
        category: PropTypes.string.isRequired,
        filter: PropTypes.func.isRequired,
        group: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        instructions: PropTypes.func,
        question: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }).isRequired,
    userAnswer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.objectOf(PropTypes.bool)
    ]).isRequired,
    nextUrl: PropTypes.string.isRequired,
    answerAction: PropTypes.func.isRequired,
    questionIndex: PropTypes.number.isRequired,
    questionCount: PropTypes.number.isRequired,
    testMode: PropTypes.bool.isRequired
};

export default Question;
