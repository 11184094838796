@value principalBlue, regularFont, largeScreen from './common.module.css';

.nav {
    width: 100%;
    padding-top: 16px;
}

.back {
    color: principalBlue;
    font-size: 14px;
    line-height: 17px;
    font-family: regularFont;
    cursor: pointer;
    display: inline-block;
    margin: 20px 0 0 10px;
}

@media all and (min-width: largeScreen) {
    .back {
        margin-left: 30px;
    }
}

.back::before {
    font-family: "FontAwesome";
    content: "\f104";
    font-size: 26px;
    position: relative;
    top: 3px;
    margin-right: 10px;
}

.back:hover {
    text-decoration: none;
}

