@value pdsDarkGray, pdsMediumDarkGray, pdsMediumGray, boldFont, smallScreen, largeScreen from './common.module.css';

ul {
    padding: 0px;
    margin: 0px;
}

.wrapper {
    display: flex;
    flex-direction: column;

    color: pdsDarkGray;
    font-size: 18px;
}

@media all and (max-width: largeScreen) {
    .wrapper {
        font-size: 16px;
    }
}

@media all and (max-width: smallScreen) {
    .wrapper {
        font-size: 15px;
    }
}

.editAnswers {
    align-self: flex-end;
}

.title {
    composes: sectionTitle from './common.module.css';
    font-size: 2.7em;
    margin-bottom: 0px;
}

.subTitle {
    composes: sectionSubtitle from './common.module.css';
    font-size: 1.7em;
    margin: 0px;
    margin-bottom: 40px;
}

@media all and (max-width: smallScreen) {
    .subTitle {
        padding-bottom: 20px;
    }
}

/*Score Card and Bullet Points*/

.overallScoreAndBulletpointsBar {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;

    margin: 65px -50vw;

    padding: 0px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media all and (max-width: smallScreen) {
    .overallScoreAndBulletpointsBar {
        padding: 0px;
    }
}

.overallScoreAndBulletpointsBar.green {
    background: #eef8f5;
}

.overallScoreAndBulletpointsBar.yellow {
    background: #fdfae5;
}

.overallScoreAndBulletpointsBar.orange {
    background: #fff6ed;
}

.overallScoreAndBulletPoints {
    max-width: 1000px;

    display: flex;
    flex-direction: row;
    justify-content: center;

    padding: 5px;
}

/*Score Card*/

.scoreCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-width: 450px;

    background: white;
    border: 1px solid pdsMediumGray;
    border-radius: 10px;

    padding: 50px;

    margin: -60px 0px;
}

.scoreCardBadge {
    max-width: 120px;
    max-height: 120px;
}

@media all and (max-width: largeScreen) {
    .overallScoreAndBulletPoints {
        max-width: 500px;
        flex-direction: column;
        padding: 0px;
    }

    .scoreCard {
        margin-bottom: 20px;
        padding-bottom: 10px;
        min-width: 400px;
    }

    .scoreCardBadge {
        max-width: 75px;
        max-height: 75px;
    }
}

@media all and (max-width: smallScreen) {
    .scoreCard {
        padding: 30px 20px;
        min-width: 100%;
        border-radius: 0px;
        border-right: 0px;
        border-left: 0px;
    }
}

.scoreCardTitle {
    margin: 30px 20px;
    max-width: 250px;

    font-family: boldFont;
    font-size: 2em;
    text-align: center;
    line-height: 1.4;
}

.scoreCardSubTitle {
    text-align: center;
}

/* Bullet Points */

.bulletPointList {
    display: flex;
    flex-direction: column;
    justify-content: center;

    list-style: none;

    padding-left: 40px;
}

.bulletPoint {
    display: flex;
    padding: 20px 15px 20px 0px;
}

.bulletPoint img {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.bulletPoint span {
    flex: 1;
}

/* Calls to Action */

.ctaSectionsWrapper {
    margin-top: 30px;
}

@media all and (max-width: largeScreen) {
    .ctaSectionsWrapper {
        margin: 10px 0px;
    }
}

.ctaSectionsTitle {
    width: 100%;
    text-align: center;
    font-size: 2em;
    font-family: boldFont;
}

/* CtaSection */

.ctaSection {
    list-style: none;
    margin-top: 50px;
}

.ctaSection:last-of-type {
    margin-bottom: 50px;
}

.ctaSectionTitle {
    display: flex;
    align-items: center;

    border-bottom: 2px solid pdsMediumDarkGray;
}

.ctaSectionTitle h4 {
    font-size: 1.6em;
    font-family: boldFont;
    margin: 15px;
    padding-top: 5px;
}

.ctaSectionTitle img {
    width: 30px;
    height: 30px;
}

.ctaSectionDescription {
    font-size: 1em;
}

.ctaCards {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 30px;
}

.pdfInfoText {
    width: 35%;
    marginVertical: 5;
    font-size: 0.75em !important;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
}

/* CtaCard */

.ctaCard {
    width: 100%;
    border: 1px solid pdsMediumGray;
    border-radius: 8px;

    padding: 0px 20px;
    margin-top: 15px;
    list-style: none;
}

@media all and (max-width: largeScreen) {
    .ctaCard {
        padding: 0px 15px;
    }
}

.ctaCardTitle {
    display: flex;
    align-items: center;
}

.ctaCardTitle h5 {
    font-size: 1.2em;
    font-family: boldFont;
    padding-right: 10px;
}

.ctaCardLinks {
    list-style: none;
    padding: 0px;
}

.ctaCardSubText {
    font-size: 1em;
    margin: 5px 0px 25px 0px;
    width: 95%;
}

.ctaCardTooltip {
    margin-bottom: 3px;
    margin-left: 20px;
}

@media all and (max-width: largeScreen) {
    .ctaCardTooltip {
        display: none;
    }
}

.ctaLink {
    display: flex;
    align-items: flex-start;
    padding-bottom: 20px;
}

.ctaLink a {
    font-size: 0.9em;
    border-bottom: 0px;
    padding: 0px 20px;
}

.ctaLinkPlainText {
    font-size: 0.9em;
    border-bottom: 0px;
    padding: 0px 20px;
}

.ctaLinkImgWrapper {
    min-width: 34px;
    display: flex;
    justify-content: center;
}

.ctaLinkImgWrapper img {
    max-width: 34px;
    max-height: 26px;

    margin-top: 5px;
}

.checkpoint {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 80px 0px 0px 0px;
}

@media all and (max-width: largeScreen) {
    .checkpoint {
        margin: 0;
    }
}

.checkpoint h3 {
    font-size: 2em;
    text-align: center;
    margin-top: 0px;
}

.checkpoint a {
    margin: 15px auto;
}
