import flow from '@principal/business-needs-assessment-tool-helpers';

// State helpers
const isModified = assessmentState => assessmentState.syncState.modified;

const markModified = assessmentState => {
    assessmentState.syncState = {
        ...assessmentState.syncState,
        writeCount: assessmentState.syncState.writeCount + 1,
        modified: true
    };
    return assessmentState;
};

// Question helpers
const toggleCompletion = (topics, topic, completion) => {
    if (topics[topic] === undefined) {
        topics[topic] = {};
    }
    topics[topic][completion] = !topics[topic][completion];
    return topics;
};

const completionState = (assessmentState, topic, completion) => {
    if (assessmentState.topics[topic] === undefined) {
        return false;
    }

    return assessmentState.topics[topic][completion];
};

const concernToggler = (assessmentActions, topic) => concern =>
    assessmentActions.toggleCompletion(topic, concern);

const concernStateChecker = (assessmentState, topic) => concern =>
    completionState(assessmentState, topic, concern);

const getQuestions = (categoryId, answers) => {
    const profileQuestions = flow.getQuestionsForCategory(
        flow.CAT_PROFILE,
        flow.QUESTIONS,
        answers
    );

    profileQuestions.answered = () =>
        flow.getAnsweredQuestions(profileQuestions, answers);
    profileQuestions.unanswered = () =>
        flow.getUnansweredQuestions(profileQuestions, answers);

    const categoryQuestions = flow.getQuestionsForCategory(
        categoryId,
        flow.QUESTIONS,
        answers
    );

    categoryQuestions.answered = () =>
        flow.getAnsweredQuestions(categoryQuestions, answers);
    categoryQuestions.unanswered = () =>
        flow.getUnansweredQuestions(categoryQuestions, answers);

    return {
        profileQuestions,
        categoryQuestions
    };
};

// URL Helpers
const appendAssessmentId = (url, assessmentId) =>
    assessmentId ? `${url}?assessmentId=${assessmentId}` : url;

const urlBuilder = url => `/needs/assessment/${url}`;

const loadUrlBuilder = (id, targetPage) =>
    targetPage
        ? urlBuilder(`load/${id}?target=${encodeURIComponent(targetPage)}`)
        : urlBuilder(`load/${id}`);

const topicUrlBuilder = url => urlBuilder(`topics/${url}`);

const categoryUrlBuilder = categoryId => urlBuilder(`category/${categoryId}`);

const summaryUrlBuilder = categoryId =>
    urlBuilder(`category/${categoryId}/business-profile-summary`);

const resultsUrlBuilder = categoryId =>
    urlBuilder(`category/${categoryId}/results`);

const questionUrlBuilder = (categoryId, questionId) =>
    urlBuilder(`category/${categoryId}/${questionId}`);

const nextQuestionHelper = (categoryId, nextQuestion) =>
    nextQuestion === undefined || nextQuestion === null
        ? resultsUrlBuilder(categoryId)
        : questionUrlBuilder(categoryId, nextQuestion.id);

const nextPageHelper = nextPage =>
    nextPage === undefined
        ? urlBuilder('checkpoint')
        : topicUrlBuilder(nextPage.url);

export default {
    isModified,
    markModified,
    toggleCompletion,
    completionState,
    appendAssessmentId,
    urlBuilder,
    loadUrlBuilder,
    nextPageHelper,
    concernToggler,
    concernStateChecker,
    topicUrlBuilder,
    categoryUrlBuilder,
    summaryUrlBuilder,
    getQuestions,
    resultsUrlBuilder,
    questionUrlBuilder,
    nextQuestionHelper
};
