"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.intersperse = exports.delimitWithCommasAndAnAnd = exports.capitalize = void 0;
// e.g. intersperse("-", ["foo","bar","baz"]).join('') == "foo-bar-baz"
var intersperse = exports.intersperse = function intersperse(separator, list) {
  return list.reduce(function (acc, item, index) {
    if (list.length - 1 !== index) {
      return acc.concat([item, separator]);
    }
    // Last item
    acc.push(item);
    return acc;
  }, []);
};

// "x" OR "x and y" OR "x, y, and z"
var delimitWithCommasAndAnAnd = exports.delimitWithCommasAndAnAnd = function delimitWithCommasAndAnAnd(plans) {
  if (plans.length === 2) {
    return [plans[0], ' and ', plans[1]].join('');
  } else if (plans.length > 2) {
    var modifiedPlans = intersperse(',  ', plans);
    var lastPlan = modifiedPlans.pop();
    return modifiedPlans.concat(['and ', lastPlan]).join('');
  }
  return plans[0];
};
var capitalize = exports.capitalize = function capitalize(str) {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};