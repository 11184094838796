import React from 'react';

const formatters = {
    link: (text, href, target) => (
        <a key={href} href={href} target={target}>
            {text}
        </a>
    ),
    bold: text => (
        <span key={text} style={{ fontFamily: 'FS Elliot Web Bold' }}>
            {text}
        </span>
    ),
    text: text => <span key={text}>{text}</span>,
    sm: () => <span key={'sm'}>&#8480;</span>
};

const formatText = text => (typeof text === 'string' ? text : text(formatters));

export default formatText;
