"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.CAT_PROFILE = exports.CAT_LIFESTYLE = exports.CAT_EMPLOYEES = exports.CAT_BUSINESS = void 0;
var _engine = require("./engine");
var _topics = require("./topics");
/* *
 *
 * Below we will construct the actual assessment flow.
 *
 * We'll start with the topics, topic pages, then build out the categories and
 * questions.
 *
 * */

var CAT_PROFILE = exports.CAT_PROFILE = 'business-profile';
var CAT_BUSINESS = exports.CAT_BUSINESS = 'protect-your-business';
var CAT_EMPLOYEES = exports.CAT_EMPLOYEES = 'protect-your-employees';
var CAT_LIFESTYLE = exports.CAT_LIFESTYLE = 'protect-your-lifestyle';
var categories = {
  protectBusiness: (0, _engine.category)(CAT_BUSINESS, 'Protecting your business', '2-5', 'business protection', 'Buy-sell planning and funding, Protecting your business from the loss of a key employee and Income protection', [_topics.topic.economicCrisis, _topics.topic.businessContinuity, _topics.topic.financialIndependence, _topics.topic.keyPersonLoss, _topics.topic.employeeMotivation]),
  protectEmployees: (0, _engine.category)(CAT_EMPLOYEES, 'Protecting your employees', '3', 'employee protection', 'Employee benefits for all employees, Qualified retirement plans (401(k)/403(b) and Employee benefits for select (key) employees', [_topics.topic.benefitsEffectiveness, _topics.topic.benefitsAffording, _topics.topic.employeeMotivation, _topics.topic.keyPersonLoss, _topics.topic.economicCrisis]),
  protectLifestyle: (0, _engine.category)(CAT_LIFESTYLE, 'Protecting your lifestyle', '5', 'lifestyle protection', 'Income protection, Planning for financial independence as you transition away from business (retirement or to explore another opportunity) and Legacy and estate planning', [_topics.topic.keyPersonLoss, _topics.topic.financialIndependence, _topics.topic.familySecurity, _topics.topic.disability, _topics.topic.estateEquality, _topics.topic.estateExposure])
};
var _default = exports["default"] = Object.values(categories);