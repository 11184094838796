/* Appointment Entry Styling */
.entry {
    width: 100%;
}

.fieldDescription {
    composes: fieldDescription from './common.module.css';
}

.error {
    overflow-wrap: break-word;
    word-wrap: break-word;
}