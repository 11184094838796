const stateChecker = checker => {
    const helper = (required, props, propName, ...rest) => {
        if (required && props[propName][props.id] == null) {
            if (props[propName][props.id] === null) {
                return new Error(
                    `Required property, ${propName}['${props.id}'], was null.`
                );
            }

            return new Error(
                `Required property, ${propName}['${props.id}'], was undefined.`
            );
        }

        return checker(props[propName], props.id, ...rest);
    };

    const chainableChecker = helper.bind(null, false);
    chainableChecker.isRequired = helper.bind(null, true);

    return chainableChecker;
};

export default stateChecker;
