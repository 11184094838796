import React from 'react';
import Advisor from '../assessment/advisor';

const Footer = () => {
    return (
        <footer className="pds-unauthenticated-footer">
            <section className="pds-unauthenticated-footer-row">
                <div
                    className="pds-unauthenticated-footer-legal"
                    style={{
                        marginLeft: '50px',
                        marginRight: '50px',
                        marginBottom: '40px'
                    }}
                >
                    <Advisor.AdvisorDisclaimer />
                </div>
                <div className="pds-unauthenticated-footer-social-links">
                    <span>
                        <a
                            className="pds-link-unstyled"
                            data-gtm="navigation-footer"
                            aria-label="Principal on Facebook"
                            href="https://www.facebook.com/PrincipalFinancial"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img alt="" className="facebook" />
                        </a>
                        <a
                            className="pds-link-unstyled"
                            data-gtm="navigation-footer"
                            aria-label="Principal on Twitter"
                            href="https://www.twitter.com/Principal"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img alt="" className="twitter" />
                        </a>
                        <a
                            className="pds-link-unstyled"
                            data-gtm="navigation-footer"
                            aria-label="Principal on LinkedIn"
                            href="https://www.linkedin.com/company/principalfinancialgroup"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img alt="" className="linkedin" />
                        </a>
                        <a
                            className="pds-link-unstyled"
                            data-gtm="navigation-footer"
                            aria-label="Principal on YouTube"
                            href="https://www.youtube.com/user/PrincipalFinancial"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img alt="" className="youtube" />
                        </a>
                    </span>
                </div>
                <nav>
                    <ul className="pds-unauthenticated-footer-nav-inline">
                        <li>
                            <a
                                className="pds-link-unstyled"
                                data-gtm="navigation-footer"
                                href="https://www.principal.com/terms-of-use"
                            >
                                Terms of Use
                            </a>
                        </li>
                        <li>
                            <a
                                className="pds-link-unstyled"
                                data-gtm="navigation-footer"
                                href="https://www.principal.com/products-services-disclosures"
                            >
                                Disclosures
                            </a>
                        </li>
                        <li>
                            <a
                                className="pds-link-unstyled"
                                data-gtm="navigation-footer"
                                href="https://www.principal.com/privacy-policies"
                            >
                                Privacy
                            </a>
                        </li>
                        <li>
                            <a
                                className="pds-link-unstyled"
                                data-gtm="navigation-footer"
                                href="https://www.principal.com/security-policies"
                            >
                                Security
                            </a>
                        </li>
                        <li>
                            <a
                                className="pds-link-unstyled"
                                data-gtm="navigation-footer"
                                href="https://www.principal.com/about-us/our-company/policies/report-fraud-or-unethical-conduct"
                            >
                                Report Fraud
                            </a>
                        </li>
                    </ul>
                </nav>
                <div className="pds-unauthenticated-footer-legal">
                    <p>
                        &copy;
                        <span id="pds-unauthenticated-footer-year" />, Principal
                        Financial Services, Inc.
                    </p>
                    <p>
                        Securities offered through Principal Securities, Inc.,
                        <a
                            className="pds-link-unstyled"
                            data-gtm="navigation-footer"
                            href="http://www.sipc.org"
                        >
                            member SIPC
                        </a>
                    </p>
                </div>
            </section>
        </footer>
    );
};

export default {
    Component: Footer
};
