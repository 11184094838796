.pointerCursor {
    cursor: pointer;
}

.backButtonText {
    padding-left: 16px;
}

.nextButtonText {
    padding: 0px  8px;
}

.temporaryButtonHack:disabled {
    background-color: #e8e8e8;
    border: 1px solid #e8e8e8;
    color: #9d9ea4;
    cursor: not-allowed;
}