import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../css/assessment-progress.module.css';

const ProgressBar = ({ pos, count }) => {
    pos = Number.isNaN(pos) ? 0 : pos;
    count = Number.isNaN(count) ? 0 : count;

    return (
        <div className={styles.progress}>
            <div className={styles.bar}>
                <div className={styles.completed} style={{ flex: pos }}>
                    {' '}
                </div>
                <div className={styles.remaining} style={{ flex: count - pos }}>
                    {' '}
                </div>
            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    pos: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired
};

export default ProgressBar;
