import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { webAnalytics as wa} from "@principal/web-analytics";
import IconFile from '../../img/icon-file.png';
import styles from '../../css/assessment-download-pdf.module.css';

const DownloadPDF = ({ title, categoryId, assessmentId, saveError }) => {
    const [downloadPDFText, setDownloadPDFText] = useState(
        'Download PDF of results of this assessment'
    );

    const linkBodyHelper = text => (
        <div className="row">
            <img src={IconFile} alt="" />
            <span className={styles.pdfLinkText}>{text}</span>
        </div>
    );

    if (saveError) {
        if (saveError.retrying) {
            return linkBodyHelper('Generating PDF...');
        }
        return linkBodyHelper('Sorry! Error generating PDF');
    }

    return (
        <div>
            <Link
                id="download-pdf"
                className="pds-link-unstyled"
                to={{
                    pathname: `/needs/api/v1/needs/results/${categoryId}/${assessmentId}/pdf`
                }}
                download={`${title
                    .toLowerCase()
                    .replace(/ /g, '-')}-${assessmentId}.pdf`}
                target="_blank"
                data-gtm="download"
                data-type="pdf"
                onClick={() => {
                    setDownloadPDFText('Downloading PDF...');
                    setTimeout(
                        () =>
                            setDownloadPDFText(
                                'Download PDF of results of this assessment'
                            ),
                        2000
                    );
                    wa.trackEvent('modalClick', {
                        clicktext: 'Downloading PDF....',
                        modaltitle: 'Download PDF of results of this assessment'
                    });
                }}
            >
                {linkBodyHelper(downloadPDFText)}
            </Link>
        </div>
    );
};

export default DownloadPDF;
