@value principalBlue, pdsLightBlue, pdsDarkGray, pdsMediumGray, pdsMediumLightGray, pdsWarning from './common.module.css';
@value boldFont, largeScreen from './common.module.css';

.title {
    composes: sectionTitle from './common.module.css';

    font-size: 45px;
}

.preTitle {
    composes: sectionSubtitle from './common.module.css';
    font-size: 22px;
    font-weight: lighter;
    padding: 40px 0px 20px 0px;
    margin: 0px;
}

.atopic {
    composes: sectionSubtitle from './common.module.css';

    font-size: 22px;
    padding-bottom: 20px;
}

@media all and (max-width: largeScreen) {
    .title {
        font-size: 30px;
        padding: 15px;
    }
    .preTitle {
        font-size: 18px;
    }
    .atopic {
        font-size: 18px;
    }
}

/* Assessment Section */

.assessment {
    max-width: 1000px;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media all and (max-width: largeScreen) {
    .assessment {
        width: 100%;
        min-width: 300px;
    }
}

.controls {
    margin: 20px;
    width: 400px;
    display: flex;
    flex-direction: row;

    justify-content: space-evenly;
    align-items: center;
}

.backButton {
    cursor: pointer;
}

.controls > div {
    padding: 5px 15px;
}

.statements {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.statement > div {
    margin: 10px;

    padding: 3px 15px;

    border: 1px solid principalBlue;

    border-radius: 15px;

    color: principalBlue;

    user-select: none;
    cursor: pointer;

    transition-property: background, color, border-color;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
}

.statement > div:focus,
.statement > div:hover {
    outline: none;
    border: 2px solid pdsWarning;
    margin: 9px;
}

@media all and (max-width: largeScreen) {
    .statement {
        margin: 15px;

        height: 225px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .statement > div {
        margin: 0px;

        position: relative;

        padding: 30px;

        width: 220px;
        height: 220px;

        border: 1px solid principalBlue;
        border-radius: 50%;

        color: principalBlue;

        display: flex;
        align-items: center;
        justify-content: center;

        text-align: center;

        transition-property: top, bottom, width, height, background, color;
        transition-duration: 400ms;
        transition-timing-function: ease-in-out;
    }

    .statement.small > div {
        width: 130px;
        height: 130px;
    }

    .statement.medium > div {
        width: 180px;
        height: 180px;
    }

    .statement > div:focus,
    .statement > div:hover {
        margin: 0px;
    }
}

.selected > div {
    /*TODO Make this color a constant*/
    background: #0091da;
    color: #fff;
    font-family: boldFont;
}

/* Checkpoint Page */
.checkpoint {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkpointInstructions {
    color: pdsDarkGray;
    width: 100%;

    text-align: center;

    font-size: 18px;

    margin: 25px;
}

.categories {
    display: flex;
    flex-direction: column;
}

.category {
    max-width: 1000px;
    min-width: 400px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    margin: 10px 0px;
    padding: 15px 10px;
}

.categoryInfo {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.categoryIcon {
    background: pdsMediumGray;

    color: white;
    font-family: boldFont;
    border-radius: 50%;

    margin-right: 15px;
    height: 60px;
    width: 60px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 40px;
}

.categoryTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: 0px;

    color: pdsDarkGray;
    font-family: boldFont;
    font-size: 22px;
}

.categoryDescription {
    color: pdsDarkGray;
}

.categoryWhy {
    color: principalBlue;
}

.categoryAction {
    width: 220px;
    margin-left: 10px;
}

@media all and (max-width: largeScreen) {
    .category {
        display: flex;
        flex-direction: column;
        justify-content: center;

        margin: 15px 5px;
        padding: 15px 5px;

        width: 100%;
        min-width: 300px;
        max-width: 450px;
    }

    .categoryInfo {
        align-items: center;
        justify-content: center;
    }

    .categoryTitle {
        text-align: center;
    }

    .categoryWhy {
        text-align: center;
        margin: 15px 0;
    }

    .categoryAction {
        margin: 15px 0;
    }
}

/* Useful info box */
.infobox {
    border: 1px solid pdsLightBlue;
    border-radius: 6px;

    background: pdsLightBlue;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 20px;
    margin: 25px 0px;
}

.infoboxTitle {
    color: pdsDarkGray;
    font-size: 28px;

    margin: 10px 0px;
}

.infoboxInfoEmphasis {
    color: pdsDarkGray;
    font-size: 18px;
    text-align: center;

    font-family: boldFont;

    max-width: 80%;
}

.infoboxInfo {
    color: pdsDarkGray;
    font-size: 16px;
    text-align: center;

    max-width: 80%;
}

/* Call to action boxes */
.callsToAction {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;

    align-items: space-between;

    width: 100%;
}

.callToAction {
    border: 1px solid pdsMediumLightGray;
    border-radius: 6px;

    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 10px;
    padding: 15px;
}

.ctaTitle {
    color: pdsDarkGray;
    font-family: boldFont;
    font-size: 22px;

    margin: 10px 0px;
}

.ctaDescription {
    color: pdsDarkGray;
    text-align: center;

    font-size: 16px;

    margin: 10px 0px;
}

.ctaButtonBox {
    padding: 10px;
}

/* Loading Indicator and Error Pages */

.loading {
    width: 100%;
    max-width: 600px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: center;

    padding: 40px 5px;
}

.loadingTitle {
    text-align: center;

    color: pdsDarkGray;
    font-size: 22px;

    margin: 30px 5px;
}

.loadingMessage {
    text-align: center;

    color: pdsDarkGray;
}

.loadingErrorLabel {
    font-family: boldFont;
    padding-right: 10px;
}

.loadingIndicator {
    composes: loading from './loading.module.css';
}

/* Advisor Launch Page Styles */

.cards {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: largeScreen) {
    .cards {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/*TEST HELPER STYLING */
.testWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100vw;
}

.testCategoriesNav {
    display: flex;
    flex-direction: row;
}

.testCategoriesNav a {
    width: 200px;
    font-size: 12px;
    margin: 0px 5px;
}

.testQuestionColumn {
    display: flex;
    flex-direction: column;
    transform: scale(0.8);

    width: 50vw;
    max-width: 650px;
}

.testResultsColumn {
    overflow-y: scroll;
    overflow-x: scroll;
    height: 100vh;
    max-width: 1150px;
    transform: scale(0.8);
    position: sticky;
    top: 0px;
}
