/* Name Entry Styling */
.entry {
    width: 100%;
}

.formRows {
    display: flex;
    flex-direction: column;
}

.formRow {
    padding-bottom: 20px;
}

.fieldDescription {
    composes: fieldDescription from './common.module.css';
}