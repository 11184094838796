@value principalBlue from './common.module.css';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 50px;

  width: 100%;
}

.loading:after {
  content: " ";
  width: 64px;
  height: 64px;

  margin: 8px;
  border-radius: 50%;
  border: 6px solid principalBlue;
  border-color: principalBlue transparent principalBlue transparent;

  animation: lds-dual-ring 1.75s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
