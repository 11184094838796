import React from 'react';
import PropTypes from 'prop-types';

import stateChecker from '../../state-checker';

import styles from '../../css/multiple-entry.module.css';

const validate = state => {
    if (!state.options.hasOwnProperty(state.value)) { /* eslint no-prototype-builtins: 0 */
        state.isValid = false;
        state.hasError = true;
        state.errorMsg = `${state.title} is not valid`;
        return state;
    }

    state.isValid = true;
    state.hasError = false;
    state.errorMsg = null;
    return state;
};


const set = (state, input) => {
    if (input === '') {
        state.value = null;
        return validate(state);
    }
    state.value = input;

    return validate(state);
};

const MultipleInputState = {
    Initial: (id, userParams) => {
        const params = {
            options: null,
            allowNA: false,
            title: null,
            ...userParams,
            value: null // If the default value is invalid don't use it.
        };
        let state = {
            // User params
            ...params,
            // Internal state
            isValid: params.value != null && (
                !userParams
                || (userParams.value === null || userParams.value === undefined)
            ),
            hasError: false,
            errorMsg: null
        };
        if (userParams
            && (userParams.value !== null || userParams.value !== undefined)
        ) {
            state = set(state, userParams.value);
        }

        if (id !== null && id !== undefined) {
            return { [id]: state };
        }

        return state;
    },
    set,
    get: ({ value }) => (value),
    validate,
    isValid: ({ isValid }) => isValid,
    getErrorState: ({ hasError, errorMsg }) => ({ hasError, errorMsg }),
    isEmpty: ({ value }) => (value === null || value.length === 0),
    Actions: update => ({
        validateMultiple: id => update({ [id]: state => validate(state) }),
        setMultiple: (id, input) => update({ [id]: state => set(state, input) })
    })
};

const MultipleInputComponent = (
    { id, state, actions, onChange, onFocusChange }
) => (
    <div className={styles.entry}>
        <select id={id}
            value={state[id].value}
            aria-label="Time"
            onChange={evt => {
                actions.setMultiple(id, evt.target.value);
                if (onChange) { onChange(evt); }
            }}
            onFocus={evt => {
                if (onFocusChange) { onFocusChange(true, evt); }
            }}
            onBlur={evt => {
                actions.validateMultiple(id);
                if (onFocusChange) { onFocusChange(false, evt); }
            }}
        >
            {Object.entries(state[id].options).map(
                ([key, { label }]) => {
                    return (
                        <option key={`${id}-${key}`}
                            value={key}
                        >
                            {label}
                        </option>
                    );
                }
            )}
        </select>
    </div>
);


MultipleInputComponent.defaultProps = { onChange: null, onFocusChange: null };

const actionPropTypes = PropTypes.shape(
    {
        setMultiple: PropTypes.func.isRequired,
        validateMultiple: PropTypes.func.isRequired
    }
);

const statePropTypes = PropTypes.shape(
    {
        value: PropTypes.string,
        isValid: PropTypes.bool.isRequired,
        hasError: PropTypes.bool.isRequired,
        errorMsg: PropTypes.string
    }
);

MultipleInputComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: actionPropTypes.isRequired,
    state: stateChecker( // eslint-disable-line react/require-default-props
        statePropTypes.isRequired
    ),
    onChange: PropTypes.func,
    onFocusChange: PropTypes.func
};


export default {
    State: MultipleInputState,
    Component: MultipleInputComponent,
    statePropTypes,
    actionPropTypes
};
