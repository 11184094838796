import React from 'react';
import PropTypes from 'prop-types';
import { webAnalytics as wa} from "@principal/web-analytics";
import NameEntry from '../name-input';
import TextEntry from '../text-input';
import EmailEntry from '../email-input';
import PhoneEntry from '../phone-input';
import ZipCodeEntry from '../zip';
import AppointmentEntry from '../appointment-input';
import MultipleEntry from '../multiple-input';
import BusinessNameInput from '../business-name-input';
import CityInput from '../city-input';
import Label from '../label';


import styles from '../../css/contact-form.module.css';

import {
  CONTACT_NAME,
  CONTACT_BUSINESS_NAME,
  CONTACT_CITY,
  CONTACT_EMAIL,
  CONTACT_PHONE,
  CONTACT_ZIP_CODE,
  CONTACT_APPOINTMENT,
  CONTACT_HOUR_KEY,
  CONTACT_HOUR_OPTIONS,
  CONTACT_STATE,
  CONTACT_STATE_OPTIONS
} from '../../constants';


const isValid = (state) => {
    const scheduleACallValid = TextEntry.State.isValid(state[CONTACT_NAME].first)
        && TextEntry.State.isValid(state[CONTACT_NAME].last)
        && EmailEntry.State.isValid(state[CONTACT_EMAIL])
        && PhoneEntry.State.isValid(state[CONTACT_PHONE])
        && AppointmentEntry.State.isValid(state[CONTACT_APPOINTMENT])
        && BusinessNameInput.State.isValid(state[CONTACT_BUSINESS_NAME])
        && CityInput.State.isValid(state[CONTACT_CITY])
        && ZipCodeEntry.State.isValid(state[CONTACT_ZIP_CODE]);

    return scheduleACallValid;
};

const validateForm = (state, actions) => {
    // Trigger validation runs.
    actions.validateName(CONTACT_NAME);
    actions.validateEmail(CONTACT_EMAIL);
    actions.validatePhone(CONTACT_PHONE);
    actions.validateZip(CONTACT_ZIP_CODE);
    actions.validateAppointment(CONTACT_APPOINTMENT);
    actions.validateCity(CONTACT_CITY);
    actions.validateBusinessName(CONTACT_BUSINESS_NAME);
    return isValid(state);
};

const ContactFormState = {
    Initial: () => ({
        [CONTACT_NAME]: NameEntry.State.Initial(),
        ...EmailEntry.State.Initial(CONTACT_EMAIL),
        ...PhoneEntry.State.Initial(CONTACT_PHONE),
        ...ZipCodeEntry.State.Initial(CONTACT_ZIP_CODE),
        ...AppointmentEntry.State.Initial(CONTACT_APPOINTMENT, { title: 'Date' }),
        ...MultipleEntry.State.Initial(CONTACT_HOUR_KEY, {
            options: CONTACT_HOUR_OPTIONS,
            value: 'between-8am-11am',
            title: 'Time',
            allowNull: false
        }),
        ...MultipleEntry.State.Initial(CONTACT_STATE, {
          options: CONTACT_STATE_OPTIONS,
          value: 'IA',
          title: 'State',
          allowNull: false
      }),
      ...BusinessNameInput.State.Initial(CONTACT_BUSINESS_NAME),
      ...CityInput.State.Initial(CONTACT_CITY, {
          title: 'City',
          type: 'text',
          allowNull: false
      })
    }),
    isValid,
    validateForm,
    Actions: (update) => (
        {
            ...NameEntry.State.Actions(update),
            ...EmailEntry.State.Actions(update),
            ...PhoneEntry.State.Actions(update),
            ...ZipCodeEntry.State.Actions(update),
            ...MultipleEntry.State.Actions(update),
            ...AppointmentEntry.State.Actions(update),
            ...BusinessNameInput.State.Actions(update),
            ...CityInput.State.Actions(update)
        }
    )
};

const Confirmation = ({ actions }) => (
    <div>
        <div className={`pds-typography-small ${styles.confirmationMsg}`}> Thank you, your request has been received. </div>
        <div className={styles.confirmationCloseBtn}>
            <button type="button"
                className="pds-button pds-button-primary"
                onClick={() => actions.hideModal('modal')}
            >
                Close
            </button>
        </div>
    </div>
);

Confirmation.propTypes = {
    actions: PropTypes.shape().isRequired
};

const ContactForm = ({ state, actions }) => (
    <form className={styles.form}>
        <div className={styles.formRows}>
            <div className={styles.formRow}>
                <BusinessNameInput.Component state={state}
                    id={`${CONTACT_BUSINESS_NAME}`}
                    actions={actions}
                />
            </div>
            <div className={styles.formRow}>
                <Label.Component htmlFor={`${CONTACT_STATE}`}
                    label="State"
                    required
                />
                <MultipleEntry.Component state={state}
                    id={CONTACT_STATE}
                    actions={actions}
                />
            </div>
            <div className={styles.formRow}>
                <ZipCodeEntry.Component state={state}
                    id={CONTACT_ZIP_CODE}
                    actions={actions}
                />
            </div>
            <div className={styles.formRow}>
               <CityInput.Component state={state} id={`${CONTACT_CITY}`} actions={actions} />
            </div>

            <Label.Component htmlFor="Business Contact Information"
                label="Business Contact Information"
                required
            />
            <NameEntry.Component state={state}
                id={CONTACT_NAME}
                actions={actions}
            />
            <div className={styles.formRow}>
                <PhoneEntry.Component state={state}
                    id={CONTACT_PHONE}
                    actions={actions}
                />
            </div>
            <div className={styles.formRow}>
                <EmailEntry.Component state={state}
                    id={CONTACT_EMAIL}
                    actions={actions}
                />
            </div>
            <Label.Component htmlFor="appointment"
                label="When is a good time to call?"
                required
            />
            <span className={styles.availability}>Available weekdays 7 a.m. to 7 p.m. CT</span>
            <div className={styles.formRow}>
                <label className={styles.label} htmlFor={`multiple-${CONTACT_APPOINTMENT}`}>
                    Date
                </label>
                <AppointmentEntry.Component state={state}
                    id={CONTACT_APPOINTMENT}
                    actions={actions}
                />
            </div>
            <div className={styles.formRow}>
                <label className={styles.label} htmlFor={`multiple-${CONTACT_HOUR_KEY}`}>
                    Time
                </label>
                <MultipleEntry.Component state={state}
                    id={CONTACT_HOUR_KEY}
                    actions={actions}
                />
            </div>
            <div className={styles.nextButton}>
                <button className="pds-button pds-button-primary"
                    type="submit"
                    disabled={!isValid(state)}
                    onClick={evt => {
                        evt.preventDefault();
                        actions.requestContact(state);
                        actions.showModalContent('modal', () => 'Thank You', () => <Confirmation actions={actions} />);
                        window.dataLayer.push({
                            event: 'e_formInteraction',
                            eventType: 'submit',
                            formAttributes: {
                                name: 'Connect with a representative'                            }
                        });
                        wa.trackEvent('formSubmit', {
                          lead: 'yes',
                          name:  'Find a financial professional',
                          type: 'contact-prospect'
                        });
                    }}
                >
                    Submit
                </button>
            </div>
        </div>
    </form>
);

ContactForm.propTypes = {
    advisor: PropTypes.bool,
    actions: PropTypes.shape().isRequired,
    state: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types, max-len
};

export default {
    State: ContactFormState,
    Component: ContactForm
};
