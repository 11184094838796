import React from 'react';

import PropTypes from 'prop-types';

import styles from '../../css/breadcrumbs.module.css';


const BreadcrumbState = ({
    Initial: () => ({}),
    Actions: () => ({})
});


const Crumb = ({ name, url }) => (
    <li>
        {url === null || url.length === 0
            ? <div aria-current="page">{name}</div>
            : <a href={url}><div>{name}</div></a>
        }
    </li>
);

Crumb.defaultProps = { url: '' };

Crumb.propTypes = { name: PropTypes.string.isRequired, url: PropTypes.string };


const BreadcrumbComponent = ({ crumbs }) => (
    <div className={styles.breadcrumbs}>
        <div>
            <ul>
                {crumbs.map(({ name, url }) => (
                    <Crumb key={`${name}${url}`}
                        name={name}
                        url={url}
                    />
                ))}
            </ul>
        </div>
    </div>
);

BreadcrumbComponent.propTypes = (
    { crumbs: PropTypes.arrayOf(PropTypes.shape(Crumb.propTypes)).isRequired }
);


export default {
    State: BreadcrumbState,
    Component: BreadcrumbComponent
};
