import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import flow from '@principal/business-needs-assessment-tool-helpers';
import { webAnalytics as wa } from '@principal/web-analytics';

import CtaFooter from './cta-footer';
import DownloadPDF from './download-pdf';

import styles from '../../css/assessment-checkpoint.module.css';

import SelectedImg from '../../img/topic-selected.png';

import CompleteImage from '../../img/category-completed.png';
import BusinessImage from '../../img/category-business.png';
import EmployeeImage from '../../img/category-employees.png';
import LifestyleImage from '../../img/category-lifestyle.png';

const MaybeImg = ({ img, alt, display }) =>
    display === true ? <img src={img} alt={alt} /> : null;

MaybeImg.propTypes = {
    img: PropTypes.string,
    alt: PropTypes.string,
    display: PropTypes.bool
};

const CategoryTopic = ({ topic, selected }) => (
    <div className={styles.topicRow}>
        <div className={styles.topicSelected}>
            <MaybeImg img={SelectedImg} alt="Yes" display={selected} />
        </div>
        <div
            className={[
                styles.topicRowDescription,
                selected ? styles.selected : ''
            ].join(' ')}
        >
            {topic}
        </div>
    </div>
);

CategoryTopic.propTypes = {
    topic: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired
};

const getTopicState = (userChoices, category, topic) => {
    if (userChoices[category] === undefined) {
        return false;
    }

    return userChoices[category][topic];
};

const topicSorter = (lhs, rhs) => {
    if (lhs.selected && !rhs.selected) {
        return -1;
    } else if (!lhs.selected && rhs.selected) {
        return 1;
    }

    if (lhs.topic > rhs.topic) {
        return 1;
    }

    return -1;
};

const CategoryTopics = ({ topics, userChoices }) => {
    const sortedTopics = topics
        .map(topic => ({
            topic,
            selected: getTopicState(userChoices, flow.TOPIC_PAGES[0].url, topic)
        }))
        .sort(topicSorter);

    return (
        <div className={styles.categoryTopicList}>
            {sortedTopics.map(({ topic, selected }) => (
                <CategoryTopic key={topic} topic={topic} selected={selected} />
            ))}
        </div>
    );
};

CategoryTopics.propTypes = {
    topics: PropTypes.arrayOf(PropTypes.string).isRequired,
    userChoices: PropTypes.objectOf(PropTypes.objectOf(PropTypes.bool))
        .isRequired
};

const getImage = (category, completed) => {
    if (completed) {
        return CompleteImage;
    }

    switch (category) {
        case flow.CAT_BUSINESS:
            return BusinessImage;
        case flow.CAT_EMPLOYEES:
            return EmployeeImage;
        case flow.CAT_LIFESTYLE:
            return LifestyleImage;
    }
};

const Category = ({
    id,
    title,
    completed,
    timeToComplete,
    actionButton,
    topics,
    userChoices,
    assessmentId,
    saveError
}) => (
    <div className={styles.category}>
        <div className={styles.categoryImage}>
            <img src={getImage(id, completed)} alt="" />
        </div>
        <h2 className={styles.categoryTitle}>{title}</h2>
        <div className={styles.categoryAction}>
            {actionButton}
            {completed ? (
                <div className={styles.downloadPdfLink}>
                    <DownloadPDF
                        title={title}
                        categoryId={id}
                        assessmentId={assessmentId}
                        saveError={saveError}
                    />
                </div>
            ) : (
                <div className={styles.timeToComplete}>
                    <Icon.Clock />
                    <span className={styles.etcText}>
                        ~{timeToComplete} minutes
                    </span>
                </div>
            )}
        </div>
        <div className={styles.categoryTopics}>
            <CategoryTopics topics={topics} userChoices={userChoices} />
        </div>
    </div>
);

Category.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    timeToComplete: PropTypes.string.isRequired,
    topics: PropTypes.arrayOf(PropTypes.string).isRequired,
    userChoices: PropTypes.objectOf(PropTypes.objectOf(PropTypes.bool))
        .isRequired,
    assessmentId: PropTypes.string.isRequired
};

// TODO This will be added later
//
// const Infobox = () => (
//     <div className={styles.infobox}>
//         <div className={styles.infoboxTitle}>Just the facts</div>
//         <div className={styles.infoboxInfoEmphasis}>
//             Only 11% of buy-sell agreements include a mandatory trigger for
//             Retirement.
//         </div>
//         <div className={styles.infoboxInfo}>
//             Including a mandatory trigger for retirement in your buy-sell
//             agreement is critical for your business.
//         </div>
//     </div>
// );

const ComeBackLater = ({ comebackUrl }) => {
    if (!comebackUrl) {
        return null;
    }

    return (
        <div className={styles.callToAction}>
            <h3 className={styles.ctaTitle}>Come back to this later</h3>
            <div className={styles.ctaDescription}>
                Save what you’ve done and email the link to yourself so you can
                come back to it later. Or, if you’re done, share the link with
                others.
            </div>
            <div>
                <div className={styles.ctaButtonBox}>
                    <a
                        className="pds-button pds-button-secondary"
                        href={`mailto:?subject=Your%20Information%20from%20Principal%20Business%20Needs%20Assessment&body=We%E2%80%99re%20glad%20you%E2%80%99re%20using%20the%20Principal%C2%AE%20Business%20Needs%20Assessment%20to%20uncover%20ideas%20that%20help%20protect%20the%20financial%20well-being%20of%20your%20business.%0D%0A%0D%0AYour%20information%20is%20saved%20so%20you%20can%3A%0D%0A%E2%80%A2%20Complete%20your%20assessments%20later.%0D%0A%E2%80%A2%20Share%20your%20completed%20assessments%20with%20others.%0D%0A%0D%0ARetrieve%20your%20information:%20${comebackUrl}`}
                    >
                        Email link
                    </a>
                </div>
            </div>
        </div>
    );
};

ComeBackLater.propTypes = { comebackUrl: PropTypes.string.isRequired };

const buildLabel = (actionPhrase, complete) => {
    if (complete) {
        return (
            <>
                View your
                <span className="sr-only">{` ${actionPhrase} assessment`}</span>
                &nbsp;results
            </>
        );
    }

    return (
        <>
            Take
            <span className="sr-only">{` ${actionPhrase}`}</span>
            &nbsp;assessment
        </>
    );
};

const categoryActionButton = (
    id,
    actionPhrase,
    complete,
    urlBuilder,
    assessmentActions
) => (
    <Link
        type="button"
        id={`${id}-${complete ? 'view-results' : 'take-assessment'}`}
        className={`pds-button pds-button-${
            complete ? 'secondary' : 'primary'
        }`}
        onClick={
            !complete
                ? () => {
                    assessmentActions.updateTimestamps(id);
                    wa.trackEvent('modalClick', {
                        clicktext: 'take-assessment',
                        modaltitle: 'AssessmentFlow'
                    });   
                    wa.trackEvent('pageNavigationClick', {
                        clicktext: 'take-assessment',
                        navigationsection: 'call to action'
                    });                
                  }
                : () => {
                    wa.trackEvent('modalClick', {
                        clicktext: 'view-results',
                        modaltitle: 'ViewResults'
                    });
                    wa.trackEvent('pageNavigationClick', {
                        clicktext: 'view-results',
                        navigationsection: 'call to action'
                    });
                  }
        }
        to={urlBuilder(!complete ? id : `${id}/results`)}
        data-gtm="call-to-action"
    >
        {buildLabel(actionPhrase, complete)}
    </Link>
);

const AssessmentsPendingHeader = () => (
    <>
        <h1 className={styles.title}>
            Get personalized results for whichever area(s) you choose.
        </h1>
        <div className={styles.instructions}>
            You'll get an assessment of how you're doing and suggested next
            steps.
        </div>
    </>
);

const AssessmentsCompleteHeader = () => (
    <>
        <h1 className={styles.title}>You have finished your assessments!</h1>
        <div className={styles.instructions}>
            You can download your results now if needed. Work with your
            financial professional to discuss next steps.
        </div>
    </>
);

const Checkpoint = ({
    urlBuilder,
    userChoices,
    comebackUrl,
    categoryStates,
    showContactFormModal,
    advisor,
    assessmentId,
    saveError,
    assessmentActions
}) => (
    <div className={styles.checkpoint}>
        {Object.values(categoryStates).every(v => v) ? (
            <AssessmentsCompleteHeader />
        ) : (
            <AssessmentsPendingHeader />
        )}
        <div className={styles.categories}>
            {Object.values(flow.CATEGORIES).map(
                ({ id, title, actionPhrase, timeToComplete, topics }) => (
                    <Category
                        key={id}
                        id={id}
                        completed={categoryStates[id]}
                        title={title}
                        timeToComplete={timeToComplete}
                        actionButton={categoryActionButton(
                            id,
                            actionPhrase,
                            categoryStates[id],
                            urlBuilder,
                            assessmentActions
                        )}
                        topics={topics}
                        userChoices={userChoices}
                        assessmentId={assessmentId}
                        saveError={saveError}
                    />
                )
            )}
        </div>
        {saveError ? null : <ComeBackLater comebackUrl={comebackUrl} />}
        {advisor.hasAdvisor ? null : (
            <CtaFooter showContactFormModal={showContactFormModal} />
        )}
    </div>
);

Checkpoint.defaultProps = { comebackUrl: '' };

Checkpoint.propTypes = {
    comebackUrl: PropTypes.string,
    urlBuilder: PropTypes.func.isRequired,
    userChoices: PropTypes.objectOf(PropTypes.objectOf(PropTypes.bool))
        .isRequired,
    assessmentId: PropTypes.string.isRequired
};

export default { Component: Checkpoint };
