import React from 'react';
import PropTypes from 'prop-types';

import stateChecker from '../../state-checker';

import Label from '../label';
import TextInput from '../text-input';
import ErrorMessage from '../error-message';
import stateHelpers from '../../state-helpers';

import styles from '../../css/name-entry.module.css';

const NameInputState = {
    Initial: () => ({
        ...TextInput.State.Initial('first', {
            title: 'First name',
            type: 'text',
            allowNull: false
        }),
        ...TextInput.State.Initial('last', {
            title: 'Last name',
            type: 'text',
            allowNull: false
        })
    }),
    getName: TextInput.State.get,
    isValid: ({ first, last, isValid }) => (
        TextInput.State.isValid(first)
        && TextInput.State.isValid(last)
        && isValid
    ),
    Actions: (update) => ({
        nameActions: stateHelpers.mapActions(
            update, TextInput.State.Actions
        )
    })
};


const NameInputComponent = ({ id, state, actions }) => (
    <>
        <div className={styles.formRow}>
            <div className={styles.entry}>
                <Label.Component htmlFor="text-first"
                    label="First name"
                    required
                />
                <TextInput.Component id="first" state={state[id]} actions={actions.nameActions(id)} />
                <ErrorMessage hasError={state[id].first.hasError}
                    errorMsg={state[id].first.errorMsg}
                />
            </div>
        </div>
        <div className={styles.formRow}>
            <div className={styles.entry}>
                <Label.Component htmlFor="text-last"
                    label="Last name"
                    required
                />
                <TextInput.Component id="last" state={state[id]} actions={actions.nameActions(id)} />
                <ErrorMessage hasError={state[id].last.hasError}
                    errorMsg={state[id].last.errorMsg}
                />
            </div>
        </div>
    </>
);

NameInputComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: TextInput.actionPropTypes.isRequired,
    /* eslint-disable react/require-default-props */
    state: stateChecker(PropTypes.shape({
        first: TextInput.statePropTypes.isRequired,
        last: TextInput.statePropTypes.isRequired
    })) /* eslint-enable react/require-default-props */
};


export default {
    State: NameInputState,
    Component: NameInputComponent
};
