import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../css/tooltip.module.css';

const TooltipComponent = ({ text, position }) =>
    !text ? null : (
        <span
            className={styles.tooltip}
            data-tooltip={text}
            data-tooltip-position={position}
            tabIndex={0}
        >
            i
        </span>
    );

TooltipComponent.defaultProps = { position: '' };

TooltipComponent.propTypes = {
    text: PropTypes.string.isRequired,
    position: PropTypes.string
};

export default { Component: TooltipComponent };
