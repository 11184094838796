import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import AppointmentEntry from '../appointment-input';
import NameEntry from '../name-input';
import PhoneEntry from '../phone-input';
import EmailEntry from '../email-input';
import BuisnessNameInput from '../business-name-input';
import CityInput from '../city-input';
import ZipCodeEntry from '../zip'
import MultipleEntry from '../multiple-input';

import convertDate, {
    CONTACT_HOUR_OPTIONS,
    CONTACT_APPOINTMENT,
    CONTACT_HOUR_KEY,
    CONTACT_NAME,
    CONTACT_PHONE,
    CONTACT_EMAIL,
    CONTACT_CITY,
    CONTACT_STATE,
    CONTACT_ZIP_CODE,
    CONTACT_BUSINESS_NAME
} from '../../constants';


const callBackRfc = (callbackDate, callbackTime) => {
    callbackDate = convertDate(callbackDate);
    const time = DateTime.fromISO(callbackDate, { hour: CONTACT_HOUR_OPTIONS[callbackTime].hour });
    return time.toUTC().toString();
};

const formatNameInput = s => {
    if (typeof s !== 'string') return '';
    let nameString = s.charAt(0).toUpperCase() + s.slice(1);
    nameString = nameString.replace(/`/g, '\'');
    return nameString;
};

const formatPhoneInput = phoneInput => phoneInput.replace(/\(|-|\s|\)/g, '');

const contactValues = (state) => {
    const {assessment: {syncState: {id}}} = state || {};

    const generatePDFLink = category => `https://life.employers.principal.com/needs/api/v1/needs/results/protect-your-${category}/${id}/pdf`

    const rfcTime = callBackRfc(
        AppointmentEntry.State.getAppointment(state[CONTACT_APPOINTMENT]),
        MultipleEntry.State.get(state[CONTACT_HOUR_KEY])
    );
    return {
        case: {
            description: `
            ### RESULTS PDF LINKS ### \n
            Protect Business Results PDF: ${generatePDFLink('business')} \n
            Protect Employees Results PDF: ${generatePDFLink('employees')} \n
            Protect Lifestyle Results PDF: ${generatePDFLink('lifestyle')} \n
            Callback Date: ${new Date(rfcTime).toLocaleDateString()} ${new Date(rfcTime).toLocaleTimeString()}
            `,
            callBackDate: rfcTime,
            business: {
                businessName: BuisnessNameInput.State.getBusinessName(state[CONTACT_BUSINESS_NAME]),
                city: CityInput.State.getCity(state[CONTACT_CITY]),
                state: MultipleEntry.State.get(state[CONTACT_STATE]),
                zipCode: ZipCodeEntry.State.getZip(state[CONTACT_ZIP_CODE]).toString()
            },
            businessContact: {
                firstName: formatNameInput(NameEntry.State.getName(state[CONTACT_NAME].first)),
                lastName: formatNameInput(NameEntry.State.getName(state[CONTACT_NAME].last)),
                phoneNumber: formatPhoneInput(PhoneEntry.State.getPhone(state[CONTACT_PHONE])),
                emailAddress: EmailEntry.State.getEmail(state[CONTACT_EMAIL]).toLowerCase()
            }
        },
        GUID: uuidv4()
    };
};

export default contactValues;
