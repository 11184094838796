"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _topics = require("./topics");
var _categories = _interopRequireWildcard(require("./categories"));
var _questions = _interopRequireWildcard(require("./questions"));
var _results = _interopRequireDefault(require("./results"));
var _engine = require("./engine");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n["default"] = e, t && t.set(e, n), n; }
var _default = exports["default"] = {
  TOPIC_PAGES: _topics.TOPIC_PAGES,
  // Assessment Flow
  CATEGORIES: _categories["default"],
  CAT_PROFILE: _categories.CAT_PROFILE,
  CAT_BUSINESS: _categories.CAT_BUSINESS,
  CAT_EMPLOYEES: _categories.CAT_EMPLOYEES,
  CAT_LIFESTYLE: _categories.CAT_LIFESTYLE,
  QUESTIONS: _questions["default"],
  // Engine
  QUESTION_TYPE: _engine.QUESTION_TYPE,
  INSTRUCTIONS_TYPE: _engine.INSTRUCTIONS_TYPE,
  answers: _engine.answers,
  getAnsweredQuestions: _engine.getAnsweredQuestions,
  getHumanAnswer: _engine.getHumanAnswer,
  getQuestionsForCategory: _engine.getQuestionsForCategory,
  getUnansweredQuestions: _engine.getUnansweredQuestions,
  isAnswered: _engine.isAnswered,
  isPrivateForProfit: _questions.isPrivateForProfit,
  setAnswer: _engine.setAnswer,
  toggleCheckbox: _engine.toggleCheckbox,
  // Result
  RESULT: _results["default"]
};