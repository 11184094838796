/* eslint-disable max-len */

import flow from '@principal/business-needs-assessment-tool-helpers';

// CONTACT Helper Methods
export { default } from './convert-date-helper';

export const CONTACT_CITY = 'contact:city';
export const CONTACT_BUSINESS_NAME = 'contact:business_name';
export const CONTACT_NAME = 'contact:name';
export const CONTACT_EMAIL = 'contact:email';
export const CONTACT_PHONE = 'contact:phone';
export const CONTACT_ZIP_CODE = 'contact:zip';
export const CONTACT_APPOINTMENT = 'contact:appointment';
export const CONTACT_HOUR_KEY = 'contact:appointment:hour';
export const CONTACT_STATE = 'contact:state';

export const CONTACT_HOUR_OPTIONS = {
    'between-8am-11am': {
        label: 'Between 8:00 AM - 11:00 AM CT',
        hour: 8
    },
    'between-11am-1pm': {
        label: 'Between 11:00 AM - 1:00 PM CT',
        hour: 11
    },
    'between-1pm-4pm': {
        label: 'Between 1:00 PM - 4:00 PM CT',
        hour: 13
    },
    'between-4pm-5pm': {
        label: 'Between 4:00 PM - 5:00 PM CT',
        hour: 16
    },
    'between-5pm-7pm': {
        label: 'Between 5:00 PM - 7:00 PM CT',
        hour: 17
    }
};

// STATES
export const CONTACT_STATE_OPTIONS = {
    AL: {
        label: 'Alabama'
    },
    AK: {
        label: 'Alaska'
    },
    AZ: {
        label: 'Arizona'
    },
    AR: {
        label: 'Arkansas'
    },
    CA: {
        label: 'California'
    },
    CO: {
        label: 'Colorado'
    },
    CT: {
        label: 'Connecticut'
    },
    DE: {
        label: 'Delaware'
    },
    DC: {
        label: 'District Of Columbia'
    },
    FL: {
        label: 'Florida'
    },
    GA: {
        label: 'Georgia'
    },
    HI: {
        label: 'Hawaii'
    },
    ID: {
        label: 'Idaho'
    },
    IL: {
        label: 'Illinois'
    },
    IN: {
        label: 'Indiana'
    },
    IA: {
        label: 'Iowa'
    },
    KS: {
        label: 'Kansas'
    },
    KY: {
        label: 'Kentucky'
    },
    LA: {
        label: 'Louisiana'
    },
    ME: {
        label: 'Maine'
    },
    MD: {
        label: 'Maryland'
    },
    MA: {
        label: 'Massachusetts'
    },
    MI: {
        label: 'Michigan'
    },
    MN: {
        label: 'Minnesota'
    },
    MS: {
        label: 'Mississippi'
    },
    MO: {
        label: 'Missouri'
    },
    MT: {
        label: 'Montana'
    },
    NE: {
        label: 'Nebraska'
    },
    NV: {
        label: 'Nevada'
    },
    NH: {
        label: 'New Hampshire'
    },
    NJ: {
        label: 'New Jersey'
    },
    NM: {
        label: 'New Mexico'
    },
    NY: {
        label: 'New York'
    },
    NC: {
        label: 'North Carolina'
    },
    ND: {
        label: 'North Dakota'
    },
    OH: {
        label: 'Ohio'
    },
    OK: {
        label: 'Oklahoma'
    },
    OR: {
        label: 'Oregon'
    },
    PA: {
        label: 'Pennsylvania'
    },
    RI: {
        label: 'Rhode Island'
    },
    SC: {
        label: 'South Carolina'
    },
    SD: {
        label: 'South Dakota'
    },
    TN: {
        label: 'Tennessee'
    },
    TX: {
        label: 'Texas'
    },
    UT: {
        label: 'Utah'
    },
    VT: {
        label: 'Vermont'
    },
    VA: {
        label: 'Virginia'
    },
    WA: {
        label: 'Washington'
    },
    WV: {
        label: 'West Virginia'
    },
    WI: {
        label: 'Wisconsin'
    },
    WY: {
        label: 'Wyoming'
    }
};

export const stateOptions = Object.entries(CONTACT_STATE_OPTIONS).map(([key, value]) => ({
    label: value.label,
    value: value.label,
    key,
  }));

const categoryTitle = {
    [flow.CAT_PROFILE]: 'Protect Business',
    [flow.CAT_BUSINESS]: 'Protect Business',
    [flow.CAT_EMPLOYEES]: 'Protect Employees',
    [flow.CAT_LIFESTYLE]: 'Protect Lifestyle'
};

const pathBuilder = path => `/needs/assessment/${path}`;

const topicPaths = Object.fromEntries(
    flow.TOPIC_PAGES.map(topic => [
        pathBuilder(`topics/${topic.url}`),
        topic.statement
    ])
);

const questionPaths = flow.QUESTIONS.reduce((acc, question) => {
    if (question.category !== flow.CAT_PROFILE) {
        acc[pathBuilder(`category/${question.category}/${question.id}`)] = `${
            categoryTitle[question.category]
        }: ${question.question}`;
        return acc;
    }

    [flow.CAT_BUSINESS, flow.CAT_EMPLOYEES, flow.CAT_LIFESTYLE].forEach(
        categoryId => {
            acc[
                pathBuilder(`category/${categoryId}/${question.id}`)
            ] = `${categoryTitle[categoryId]}: ${question.question}`;
        }
    );

    return acc;
}, {});

const resultsPaths = Object.fromEntries(
    flow.CATEGORIES.map(category => [
        pathBuilder(`category/${category.id}/results`),
        `${categoryTitle[category.id]}: Assessment Results`
    ])
);

export const PAGE_MAP = {
    // BASE PATH
    '/': 'Business Needs Assessment',
    [pathBuilder('checkpoint')]: 'Checkpoint',
    ...topicPaths,
    ...questionPaths,
    ...resultsPaths,
    [pathBuilder('advisor')]: 'Advisor Landing',
    [pathBuilder('info')]: 'Client Landing'
};
