@value  regularFont, boldFont, pdsLightGray, pdsMediumGray, smallScreen, largeScreen from './common.module.css';

.ctaFooter {
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 16px;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 420px;
    width: 100%;
    max-width: 300px;

    margin: 15px 0px;

    background: pdsLightGray;
}

@media all and (max-width: largeScreen) {
    .ctaFooter {
        flex-direction: column;
        font-size: 14px;
    }

    .card {
        max-width: none;
        height: 380px;
    }
}

.cardText {
    flex: 0;
    padding: 0px 15px;
    height: 130px;

    text-align: center;
    font-family: boldFont;
    font-size: 1.4em;
}

.icon {
    max-width: 200px;
    margin-bottom: 30px;
    margin-top: 40px;
    width: 50%;
}

.subText {
    text-align: center;
    font-family: regularFont;
    font-size: 1em;
    margin-top: 10px;
    padding: 0px 10px;
}

.ctaWrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    height: fit-content;
    padding-bottom: 40px;
}

.ctaButton {
    border: none;
    font-size: 1em;
    margin-bottom: 10px;

}

.phoneNumber {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.phoneNumber a {
    border: none;
    text-align: center;
    font-family: boldFont;
    font-size: 1.6em;
}

