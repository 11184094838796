"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showBullet = exports.scoreHelper = exports.indefiniteArticleize = exports.hasAnswer = exports.getBusinessName = exports.generateCtaSections = exports.generateBulletPoints = exports.ctaSection = exports.ctaLink = exports.ctaCardWithTooltip = exports.ctaCard = exports.bullet = exports.averageScore = exports.answeredButNot = exports.answered = exports.SCORE = exports.ICONS = void 0;
var _engine = require("../engine");
var _profile = require("../questions/profile");
var getBusinessName = exports.getBusinessName = function getBusinessName(answers) {
  return (0, _engine.getAnswer)(answers, _profile.businessName);
};
var SCORE = exports.SCORE = {
  GREEN: 1.0,
  YELLOW: 0.5,
  ORANGE: 0
};
var answered = exports.answered = function answered(question, answerId) {
  return function (answers) {
    var answer = (0, _engine.getAnswer)(answers, question);
    if (answer && question.type === _engine.QUESTION_TYPE.CHECKBOX) {
      return !!answer[answerId];
    }
    return answer === answerId;
  };
};
var answeredButNot = exports.answeredButNot = function answeredButNot(question, answerId) {
  return function (answers) {
    var answer = (0, _engine.getAnswer)(answers, question);
    if (question.type === _engine.QUESTION_TYPE.CHECKBOX) {
      return !answer[answerId];
    }
    return answer && answer !== answerId;
  };
};
var hasAnswer = exports.hasAnswer = function hasAnswer(question) {
  return function (answers) {
    var answer = (0, _engine.getAnswer)(answers, question);
    if (answer === null || answer === undefined) {
      return false;
    }
    return true;
  };
};
var averageScore = exports.averageScore = function averageScore(bulletPoints) {
  return bulletPoints.reduce(function (prior, bulletPoint) {
    return prior + bulletPoint.score * bulletPoint.weight;
  }, 0) / bulletPoints.reduce(function (prior, bulletPoint) {
    return prior + bulletPoint.weight;
  }, 0);
};

// Bullet Point helpers
var bullet = exports.bullet = function bullet(description, score) {
  var weight = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return {
    factor: description,
    score: score,
    weight: weight
  };
};
var showBullet = exports.showBullet = function showBullet(bulletData) {
  return function (answers) {
    if (!bulletData) return false;
    return bulletData.tests.every(function (test) {
      return test(answers);
    });
  };
};
var generateBulletPoints = exports.generateBulletPoints = function generateBulletPoints(answers, bulletPointTestSuites) {
  // Each test suite should only return one bullet point
  var runTestSuite = function runTestSuite(testSuite) {
    return testSuite(answers).reduce(function (bullets, currentBullet) {
      if (showBullet(currentBullet)(answers)) {
        bullets.push(currentBullet.bullet);
      }
      return bullets;
    }, []);
  };
  var testSuiteResults = bulletPointTestSuites.map(runTestSuite);
  var bulletPoints = [];
  testSuiteResults.forEach(function (result) {
    if (result.length > 1) {
      throw Error("Expected no more than one bullet point per test suite ".concat(result[0].factor));
    }
    if (result[0]) {
      bulletPoints.push(result[0]);
    }
  });
  return bulletPoints;
};

// Call to Action helpers
//
// Sections comprised of cards which are themselves comprised of several links
// pointing to helpful information. Only show a section if it is has cards.
var ctaSection = exports.ctaSection = function ctaSection(score, title, description, ctaCards) {
  var forceShow = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  return {
    score: score,
    title: title,
    description: description,
    ctaCards: ctaCards,
    forceShow: forceShow
  };
};

// generateCtaSections :
// ( answers : answer[], sectionGenerators : (answers => section)[])
// => sections[]
var generateCtaSections = exports.generateCtaSections = function generateCtaSections(answers, sectionGenerators) {
  return sectionGenerators.reduce(function (acc, sectionGenerator) {
    var section = sectionGenerator(answers);
    var ctaCards = section.ctaCards.filter(function (ctaCard) {
      return ctaCard.filter(answers);
    }).map(function (ctaCard) {
      var filteredLinks = ctaCard.ctaLinks.filter(function (link) {
        return link.filter(answers);
      });
      ctaCard.ctaLinks = filteredLinks;
      return ctaCard;
    });
    section.ctaCards = ctaCards;
    if (ctaCards.length > 0 || section.forceShow) {
      acc.push(section);
    }
    return acc;
  }, []);
};

// filter : answers => bool
var ctaCard = exports.ctaCard = function ctaCard(title, ctaLinks, subText) {
  var filter = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function () {
    return true;
  };
  return {
    title: {
      text: title,
      tooltip: null
    },
    ctaLinks: ctaLinks,
    subText: subText,
    filter: filter
  };
};
var ctaCardWithTooltip = exports.ctaCardWithTooltip = function ctaCardWithTooltip(title, tooltip, ctaLinks, subText) {
  var filter = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : function () {
    return true;
  };
  return {
    title: {
      text: title,
      tooltip: tooltip
    },
    ctaLinks: ctaLinks,
    subText: subText,
    filter: filter
  };
};

// "Call to action" - A link within a a CTA Card
var ctaLink = exports.ctaLink = function ctaLink(icon, linkText) {
  var href = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var filter = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function () {
    return true;
  };
  return {
    icon: icon,
    linkText: linkText,
    href: href,
    filter: filter
  };
};
var ICONS = exports.ICONS = {
  FILE: 'file',
  GLASSES: 'glasses',
  BOOK: 'book',
  CALCULATOR: 'calculator',
  PHONE: 'phone',
  PLAY: 'play'
};

/* Helps include indefinite articles (plural or singular) in sentences.
 * Strips out special characters in case the plurality is hidding inside parens.
 * Returns a lowercase version of the original noun.
 *
 * "Third party" -> "a third party"
 * "Shareholder(s)" -> "shareholder(s)"
 * "all the things" -> "all the things"
 *
 * This has edge cases such as data or people, i.e. plurals that don't end in s.
 */
var indefiniteArticleize = exports.indefiniteArticleize = function indefiniteArticleize(noun) {
  if (noun === undefined || noun === null) {
    return noun;
  }
  var lowerCaseNoun = noun.toLowerCase();
  var lettersOnly = lowerCaseNoun.replace(/[^a-zA-Z]/g, '');
  if (lettersOnly.charAt(lettersOnly.length - 1) === 's') {
    return "".concat(lowerCaseNoun);
  }
  return "a ".concat(lowerCaseNoun);
};

/* Score helper takes a mapping of score -> result, and returns
 * a function you call with the score to get your value.
 */
var scoreHelper = exports.scoreHelper = function scoreHelper(scoreMap) {
  if (scoreMap[SCORE.ORANGE] === undefined || scoreMap[SCORE.YELLOW] === undefined || scoreMap[SCORE.GREEN] === undefined) {
    throw Error('You must define values for ORANGE, YELLOW, and GREEN ');
  }
  return function (score) {
    if (score <= 0.3) {
      return scoreMap[SCORE.ORANGE];
    } else if (score <= 0.7) {
      return scoreMap[SCORE.YELLOW];
    }
    return scoreMap[SCORE.GREEN];
  };
};