import React from 'react';
import PropTypes from 'prop-types';

import stateChecker from '../../state-checker';
import styles from '../../css/text-entry.module.css';

const validate = state => {
    if (state.value === null || state.value === undefined) {
        if (!state.allowNull) {
            state.isValid = false;
            state.hasError = true;
            state.errorMsg = `${state.title} can not be empty`;
            return state;
        }

        state.isValid = true;
        state.hasError = false;
        state.errorMsg = null;
        return state;
    }

    if (state.type === 'text' && !state.value.match(/^[a-zA-Z][a-zA-Z-' `]+$/)) {
        state.isValid = false;
        state.hasError = true;
        state.errorMsg = `${state.title} may only contain letters, hyphens, and apostrophes`;
        return state;
    }

    if (state.type === 'text' && !(state.value.length > 1)) {
        state.isValid = false;
        state.hasError = true;
        state.errorMsg = `${state.title} is invalid`;
        return state;
    }

    if (state.type === 'email' && !state.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        state.isValid = false;
        state.hasError = true;
        state.errorMsg = `${state.title} is not valid`;
        return state;
    }

    if (state.type === 'tel' && !state.value.match(/^\(?([0-9]{3})\)?[-\s]?([0-9]{3})[-\s]?([0-9]{4})$/)) {
        state.isValid = false;
        state.hasError = true;
        state.errorMsg = `${state.title} is not valid`;
        return state;
    }


    state.isValid = true;
    state.hasError = false;
    state.errorMsg = null;

    return state;
};


const set = (state, input) => {
    if (input === '') {
        state.value = null;
        return validate(state);
    }
    state.value = input;
    return state;
};

const TextInputState = {
    Initial: (id, userParams) => {
        const params = {
            type: null,
            allowNull: false,
            title: null,
            ...userParams,
            value: null // If the default value is invalid don't use it.
        };
        let state = {
            // User params
            ...params,
            // Internal state
            isValid: params.allowNull && (
                !userParams
                || (userParams.value === null || userParams.value === undefined)
            ),
            hasError: false,
            errorMsg: null
        };
        if (userParams
            && (userParams.value !== null || userParams.value !== undefined)
        ) {
            state = set(state, userParams.value);
        }

        if (id !== null && id !== undefined) {
            return { [id]: state };
        }

        return state;
    },
    set,
    get: ({ value }) => value,
    validate,
    isValid: ({ isValid }) => isValid,
    getErrorState: ({ hasError, errorMsg }) => ({ hasError, errorMsg }),
    isEmpty: ({ value }) => (value === null || value.length === 0),
    Actions: update => ({
        validateText: id => update({ [id]: state => validate(state) }),
        setText: (id, input) => update({ [id]: state => set(state, input) })
    })
};

const TextInputComponent = (
    { id, state, actions, onChange, onFocusChange }
) => (
    <input className={styles.input}
        type={state[id].type}
        inputMode={state[id].type}
        pattern={state[id].pattern}
        placeholder={state[id].placeholder}
        id={`text-${id}`}
        value={state[id].value || ''}
        onChange={
            (evt) => {
                actions.setText(id, evt.target.value);

                if (onChange) { onChange(evt); }
            }
        }
        onFocus={
            (evt) => {
                if (onFocusChange) { onFocusChange(true, evt); }
            }
        }
        onBlur={
            (evt) => {
                actions.validateText(id);

                if (onFocusChange) { onFocusChange(false, evt); }
            }
        }
    />
);


TextInputComponent.defaultProps = { onChange: null, onFocusChange: null };

const actionPropTypes = PropTypes.shape(
    {
        setText: PropTypes.func.isRequired,
        validateText: PropTypes.func.isRequired
    }
);

const statePropTypes = PropTypes.shape(
    {
        value: PropTypes.string,
        isValid: PropTypes.bool.isRequired,
        hasError: PropTypes.bool.isRequired,
        errorMsg: PropTypes.string
    }
);

TextInputComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: actionPropTypes.isRequired,
    state: stateChecker( // eslint-disable-line react/require-default-props
        statePropTypes.isRequired
    ),
    onChange: PropTypes.func,
    onFocusChange: PropTypes.func
};

export default {
    State: TextInputState,
    Component: TextInputComponent,
    statePropTypes,
    actionPropTypes
};
