import React from 'react';
import PropTypes from 'prop-types';


const ErrorMessage = ({ hasError, errorMsg }) => {
    if (!hasError) {
        return null;
    }

    return (<span className="pds-error">{errorMsg}</span>);
};

ErrorMessage.defaultProps = {
    hasError: false,
    errorMsg: null
};

ErrorMessage.propTypes = {
    hasError: PropTypes.bool,
    errorMsg: PropTypes.string
};

export default ErrorMessage;
