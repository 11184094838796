import React from 'react';
import PropTypes from 'prop-types';
import stateChecker from '../../state-checker';

import Label from '../label';
import NumberInput from '../number-input';
import ErrorMessage from '../error-message';

import styles from '../../css/zip.module.css';


const ZipInputState = {
    Initial: id => NumberInput.State.Initial(id, {
        title: 'Zip code',
        min: 0,
        max: 99999,
        allowNull: false,
        allowThousandsSep: false
    }),
    getZip: NumberInput.State.get,
    isValid: NumberInput.State.isValid,
    Actions: NumberInput.State.Actions
};


const ZipInputComponent = ({ state, id, actions }) => (
    <div className={styles.entry}>
        <Label.Component htmlFor={`number-${id}`}
            label="Zip code"
            required
        />
        <div className={styles.zip}>
            <NumberInput.Component id={id} state={state} actions={actions} />
        </div>
        <ErrorMessage hasError={state[id].hasError}
            errorMsg={state[id].errorMsg}
        />
    </div>
);


ZipInputComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: NumberInput.actionPropTypes.isRequired,
    /* eslint-disable react/require-default-props */
    state: stateChecker(
        NumberInput.statePropTypes.isRequired
    ) /* eslint-enable react/require-default-props */
};


export default {
    State: ZipInputState,
    Component: ZipInputComponent
};
