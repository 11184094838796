/* This is a helper to map updates into deeper structures.
 *
 * Generally we prefer keeping a flat top-level model, but in some cases a
 * deeper nested model makes sense. This helper allows actions to be "mapped".
 *
 * Usage:
 *
 * const State = {
 *     Initial: id => ({
 *         [id]: {
 *             value: '',
 *             nested: NestedComponent.State.Initial()
 *         }
 *     }),
 *     Actions: update => ({
 *         setValue: (id, value) => update({ id: { value: value} }),
 *         nestedActions: mapActions(update, NestedComponent.State.Actions)
 *     })
 * }
 *
 * const Component = ({ id, state, actions }) => (
 *     <NestedComponent id="nested"
 *          state={state[id]} // Give this the _parent's_ state.
 *          actions={actions.nestedActions(id)} // Give this the _parent's_ id.
 *     />
 * )
 */
const mapActions = (parentUpdate, actionsToMap) => (
    parentId => actionsToMap(
        newState => parentUpdate({ [parentId]: newState })
    )
);

export default { mapActions };
