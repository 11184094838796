"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sufficientBenefits = exports.retentionPlansOkAndReviewed = exports.retentionPlans = exports.retentionBenefits = exports.recruitAndRetain = exports.otherRetentionBenefits = exports.otherBenefits = exports["default"] = exports.currentBenefits = exports.benefitPlansOkAndReviewed = exports.benefitPlans = void 0;
var _engine = require("../engine");
var _categories = require("../categories");
var _helpers = require("./helpers");
var _profile = require("./profile");
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
var GROUP_TITLE = 'Protect your employees';

/* * * * * * * * * *
 * Questions - Protect your employees
 * * * * * * * * * */

var sufficientBenefits = exports.sufficientBenefits = (0, _engine.radioQuestion)('are-benefits-sufficient', _categories.CAT_EMPLOYEES, GROUP_TITLE, 'Are your benefits attracting and retaining the right employees?', _helpers.YES_NO_UNSURE.concat((0, _engine.answer)('no-benefits-offered', "I don't currently offer any benefits")));
var currentBenefits = exports.currentBenefits = (0, _engine.checkboxQuestion)('current-benefits', _categories.CAT_EMPLOYEES, GROUP_TITLE, 'What benefits do you currently offer?', [(0, _engine.answer)('health', 'Health and wellness benefits'), (0, _engine.answer)('qualified-retirement', 'Qualified retirement plan (i.e. 401(k), 403(b), ESOP, or profit sharing, etc.)'), (0, _engine.answer)('financial-planning', 'Financial planning for employees'), (0, _engine.answer)('group-life', 'Group life insurance'), (0, _engine.answer)('group-disability', 'Group disability insurance'), (0, _engine.answer)('dental', 'Dental insurance'), (0, _engine.answer)('vision', 'Vision insurance'), (0, _engine.answer)('other-benefits', 'Other')], function (answers) {
  return !(0, _engine.checkAnswer)(answers, sufficientBenefits, 'no-benefits-offered');
});
var otherBenefits = exports.otherBenefits = (0, _engine.inputQuestion)('other-benefits-input', _categories.CAT_EMPLOYEES, GROUP_TITLE, 'What "other" benefits do you currently offer?', [], function (answers) {
  return (0, _engine.checkAnswer)(answers, currentBenefits, 'other-benefits');
});
var benefitPlans = exports.benefitPlans = [['health', 'health and wellness'], ['qualified-retirement', 'qualified retirement (i.e. 401(k), 403(b), ESOP, or profit sharing, etc.)'], ['financial-planning', 'employee financial planning'], ['group-life', 'group life insurance'], ['group-disability', 'group disability insurance'], ['dental', 'dental insurance'], ['vision', 'vision insurance'], ['other-benefits', 'other']];
var benefitPlansOkAndReviewed = exports.benefitPlansOkAndReviewed = benefitPlans.map(function (_ref) {
  var _ref2 = _slicedToArray(_ref, 2),
    attribute = _ref2[0],
    name = _ref2[1];
  var planSufficientQuestion = attribute === 'other-benefits' || attribute === 'health' ? "Are your ".concat(attribute === 'other-benefits' ? "\"".concat(name, "\"") : name, " benefits meeting your goals and expectations?") : "Is your ".concat(name, " benefit meeting your goals and expectations?");
  var planReviewedQuestion = attribute === 'other-benefits' || attribute === 'health' ? "When were your ".concat(attribute === 'other-benefits' ? "\"".concat(name, "\"") : name, " benefits last reviewed?") : "When was your ".concat(name, " benefit last reviewed?");
  return [(0, _engine.radioQuestion)("".concat(attribute, "-sufficient"), _categories.CAT_EMPLOYEES, GROUP_TITLE, planSufficientQuestion, _helpers.YES_NO, function (answers) {
    return (0, _engine.checkAnswer)(answers, currentBenefits, attribute);
  }), (0, _engine.radioQuestion)("".concat(attribute, "-reviewed"), _categories.CAT_EMPLOYEES, GROUP_TITLE, planReviewedQuestion, _helpers.TWO_FIVE_OVER, function (answers) {
    return (0, _engine.checkAnswer)(answers, currentBenefits, attribute);
  })];
}).reduce(function (acc, val) {
  return acc.concat(val);
}, []);
var recruitAndRetain = exports.recruitAndRetain = (0, _engine.radioQuestion)('plans-to-recruit-and-retain', _categories.CAT_EMPLOYEES, GROUP_TITLE, function (formatters) {
  return [formatters.text('Are your '), formatters.bold('"key employee" '), formatters.text('benefits effective at helping you recruit and retain your top talent?')];
}, _helpers.YES_NO_UNSURE.concat((0, _engine.answer)('no-benefits-offered', "I don't currently offer any key employee benefits")), function () {
  return true;
}, function () {
  return (0, _engine.tooltip)('Key Employee Defined: These are the employees that if they were suddenly gone tomorrow, a key aspect of the business would change. Perhaps they lead areas that keep the business and processes moving forward. For example, Operations, Relationship Management, Customer Service and Sales. They have a direct and substantial impact to your bottom line.');
});
var bonusPlanAnswer = (0, _engine.answer)('bonus', 'Bonus plan');
var splitDollarAnswer = (0, _engine.answer)('split', 'Split Dollar');
var retentionBenefits = exports.retentionBenefits = (0, _engine.checkboxQuestion)('retention-benefits', _categories.CAT_EMPLOYEES, GROUP_TITLE, 'What key employee benefits do you have in place?', [(0, _engine.answer)('deferred', 'Deferred compensation plan'), (0, _engine.answer)('death-benefit', 'Death Benefit Only (DBO) plan'), splitDollarAnswer, bonusPlanAnswer, (0, _engine.answer)('tuition', 'Tuition reimbursement or student loan repayment plan'), (0, _engine.answer)('retirement-security', 'Key Person Retirement Security (disability plan)'), (0, _engine.answer)('other-retention-benefits', 'Other')], function (answers) {
  return !(0, _engine.checkAnswer)(answers, recruitAndRetain, 'no-benefits-offered') && (0, _engine.checkAnswer)(answers, _profile.businessType, 'profit');
});
var retentionBenefitsNonProfit = (0, _engine.checkboxQuestion)('retention-benefits-non-profit', _categories.CAT_EMPLOYEES, GROUP_TITLE, 'What key employee benefits do you have in place?', [(0, _engine.answer)('457-b', '457(b)'), (0, _engine.answer)('457-f', '457(f)'), splitDollarAnswer, bonusPlanAnswer, (0, _engine.answer)('other-retention-benefits', 'Other')], function (answers) {
  return !(0, _engine.checkAnswer)(answers, recruitAndRetain, 'no-benefits-offered') && (0, _engine.checkAnswer)(answers, _profile.businessType, 'non-profit');
});
var otherRetentionBenefits = exports.otherRetentionBenefits = (0, _engine.inputQuestion)('other-retention-benefits-input', _categories.CAT_EMPLOYEES, GROUP_TITLE, 'What "other" key employee benefits do you currently offer?', [], function (answers) {
  return (0, _engine.checkAnswer)(answers, retentionBenefits, 'other-retention-benefits') || (0, _engine.checkAnswer)(answers, retentionBenefitsNonProfit, 'other-retention-benefits');
});
var retentionPlans = exports.retentionPlans = [['deferred', 'deferred compensation'], ['death-benefit', 'death benefit only (DBO)'], ['457-b', '457(b)'], ['457-f', '457(f)'], ['split', 'split dollar'], ['bonus', 'bonus'], ['tuition', 'tuition reimbursement or student loan repayment'], ['retirement-security', 'key person retirement security'], ['other-retention-benefits', 'other']];
var retentionPlansOkAndReviewed = exports.retentionPlansOkAndReviewed = retentionPlans.map(function (_ref3) {
  var _ref4 = _slicedToArray(_ref3, 2),
    attribute = _ref4[0],
    name = _ref4[1];
  var planSufficientQuestion = attribute === 'other-retention-benefits' ? "Are your \"".concat(name, "\" key employee plans meeting your goals and expectations?") : "Is your ".concat(name, " plan meeting your goals and expectations?");
  var planReviewedQuestion = attribute === 'other-retention-benefits' ? "When were your \"".concat(name, "\" key employee plans last reviewed?") : "When was your ".concat(name, " plan last reviewed?");
  return [(0, _engine.radioQuestion)("".concat(attribute, "-sufficient"), _categories.CAT_EMPLOYEES, GROUP_TITLE, planSufficientQuestion, _helpers.YES_NO, function (answers) {
    return (0, _engine.checkAnswer)(answers, retentionBenefits, attribute) || (0, _engine.checkAnswer)(answers, retentionBenefitsNonProfit, attribute);
  }), (0, _engine.radioQuestion)("".concat(attribute, "-reviewed"), _categories.CAT_EMPLOYEES, GROUP_TITLE, planReviewedQuestion, _helpers.TWO_FIVE_OVER, function (answers) {
    return (0, _engine.checkAnswer)(answers, retentionBenefits, attribute) || (0, _engine.checkAnswer)(answers, retentionBenefitsNonProfit, attribute);
  })];
}).reduce(function (acc, val) {
  return acc.concat(val);
}, []);
var _default = exports["default"] = [sufficientBenefits, currentBenefits, otherBenefits].concat(_toConsumableArray(benefitPlansOkAndReviewed), [recruitAndRetain, retentionBenefits, retentionBenefitsNonProfit, otherRetentionBenefits], _toConsumableArray(retentionPlansOkAndReviewed));