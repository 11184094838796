@value pdsDarkGray, pdsLightGray, pdsWhite, principalBlue, smallScreen from './common.module.css';

/* Tooltip Styles */
.tooltip {
    position: relative;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 12px;
    margin-left: 5px;

    background-color: principalBlue;
    color: pdsWhite;
    text-align: center;

    cursor: pointer;
    transition: all 0.2s ease-out;
}

.tooltip::before, .tooltip::after {
    position: absolute;
    opacity: 0;
    transition: all 0.2s ease-out;
}

.tooltip::before {
    content: "";
    border-width: 10px 12px 0 12px;
    border-style: solid;
    border-color: pdsLightGray transparent;

    /* Position to the right by default */
    left: 19px;
    top: 8px;
    transform: rotate(90deg);
}

.tooltip::after {
    content: attr(data-tooltip);
    z-index: 2;
    width: 300px;
    padding: 15px 25px;
    font-size: 12px;
    color: pdsDarkGray;
    background-color: pdsLightGray;
    border-radius: 4px;
    box-shadow: 2px 2px 4px #00000029;
    pointer-events: none;
    white-space: pre-wrap;

    /* Position to the right by default */
    left: 35px;
    top: calc(-75%);
}

/* Allow tooltip to be forced to top on all screens. */
.tooltip[data-tooltip-position='top']::before {
    left: 0px;
    top: -13px;
    transform: rotate(0deg);
}

.tooltip[data-tooltip-position='top']::after {
    top: auto;
    left: -130px;
    bottom: 35px;
}

/* Allow tooltip to be forced to top on all screens. */
.tooltip[data-tooltip-position='bottom']::before {
    left: 0px;
    bottom: -13px;
    transform: rotate(180deg);
}

.tooltip[data-tooltip-position='bottom']::after {
    top: 35px;
    left: -130px;
    bottom: auto;
}


/* On small screens, put the tooltip above */
@media all and (max-width: smallScreen) {
    .tooltip::before {
        left: -2px;
        top: -10px;
        transform: rotate(0deg);
    }

    .tooltip::after {
        top: auto;
        left: auto;
        bottom: 30px;
        left: -50px;
    }
}

.tooltip:hover::before, .tooltip:hover::after {
  opacity: 1;
}

.tooltip:focus::before, .tooltip:focus::after {
    opacity: 1;
}

