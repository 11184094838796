"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.topicPage = exports.tooltip = exports.toggleCheckbox = exports.subtext = exports.setAnswer = exports.selectQuestion = exports.radioQuestion = exports.isAnswered = exports.inputQuestion = exports.getUnansweredQuestions = exports.getQuestionsForCategory = exports.getHumanAnswer = exports.getAnsweredQuestions = exports.getAnswer = exports.checkboxQuestion = exports.checkboxOnlyWithAnswers = exports.checkAnswer = exports.category = exports.answers = exports.answer = exports.QUESTION_TYPE = exports.INSTRUCTIONS_TYPE = void 0;
var _helpers = require("./helpers");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /* *
 *
 * Helpers to construct topics, categories, questions, and the overall flow.
 * These are used to build the initial 'concerns' (aka 'bubble' pages) which
 * will be shown under the categories they map to.
 *
 * The general theory behind this is that we want users to feel like they're
 * getting a customized experience and we want to help them quickly assess
 * which category might be most relevant to their concerns.
 *
 * Each question is affiliated with one or more categories, one or more topics,
 * and may be dependent upon the answer to other questions.
 *
 * The questions are multi-choice, multi-select, and are scored. Each answer
 * carries a score and the answers to scored questions within a given category
 * are averaged to compute the category score.
 *
 * */
var topicPage = exports.topicPage = function topicPage(url, statement, completions) {
  var preTitle = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  return {
    url: url,
    statement: statement,
    completions: completions,
    preTitle: preTitle
  };
};

/*
 * Categories are groups of questions used to build results pages.
 */

var category = exports.category = function category(id, title, timeToComplete, actionPhrase, description, topics) {
  return {
    id: id,
    title: title,
    timeToComplete: timeToComplete,
    actionPhrase: actionPhrase,
    description: description,
    topics: topics
  };
};

/* * * * * * * * * * * * * *
 * Question "type" helpers.
 * * * * * * * * * * * * * */

var QUESTION_TYPE = exports.QUESTION_TYPE = {
  CHECKBOX: '___multi-answer___',
  RADIO: '___multi-choice___',
  INPUT: '___input___',
  SELECT: '___select___'
};
var INSTRUCTIONS_TYPE = exports.INSTRUCTIONS_TYPE = {
  TOOLTIP: '__tooltip__',
  SUBTEXT: '__subtext__'
};
var validType = function validType(type) {
  return Object.values(QUESTION_TYPE).includes(type);
};

/* The Question data structure is a simple object. The fields map to questions
 * in the following way.
 *
 * http://principal.com/bnaPath/business-owers/id-of-this-question
 *                                  (1)             (2)
 *
 * ------------------------------------------------------
 *     Do you know the value of your business? (3)
 *
 *   Have you recently had your business valued? (4)
 *       [  ]   Yes  (5)
 *       [  ]   No   (5)
 * -----------------------------------------------------
 *                         (6)
 *
 *
 * 1 - The category the question belongs to
 * 2 - Question ID
 * 3 - Question Group
 * 4 - Question Body
 * 5 - Question Answers
 * 6 - The type of this question is SINGLE_ANSWER
 *
 */

var question = function question(type, id, category, group, body, answers, filter, instructions) {
  //
  // QUESTION: Should we just encode these verifications in unit tests to avoid
  // the run-time hit?
  //
  if (!validType(type)) {
    throw "Invalid question type ".concat(type, " for ").concat(id, "!");
  }

  // If the question has no filter always display it.
  if (filter === null || filter === undefined) {
    filter = function filter() {
      return true;
    };
  }
  if (instructions === null || instructions === undefined) {
    instructions = function instructions() {
      return {
        type: null,
        text: ''
      };
    };
  }
  return {
    id: id,
    type: type,
    category: category,
    group: group,
    question: body,
    answers: answers,
    filter: filter,
    instructions: instructions
  };
};
var checkboxQuestion = exports.checkboxQuestion = function checkboxQuestion() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  return question.apply(void 0, [QUESTION_TYPE.CHECKBOX].concat(args));
};
var radioQuestion = exports.radioQuestion = function radioQuestion() {
  for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    args[_key2] = arguments[_key2];
  }
  return question.apply(void 0, [QUESTION_TYPE.RADIO].concat(args));
};
var inputQuestion = exports.inputQuestion = function inputQuestion() {
  for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
    args[_key3] = arguments[_key3];
  }
  return question.apply(void 0, [QUESTION_TYPE.INPUT].concat(args));
};
var selectQuestion = exports.selectQuestion = function selectQuestion() {
  for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
    args[_key4] = arguments[_key4];
  }
  return question.apply(void 0, [QUESTION_TYPE.SELECT].concat(args));
};
var answer = exports.answer = function answer(id, description) {
  return {
    id: id,
    description: description
  };
};
var tooltip = exports.tooltip = function tooltip(text) {
  var position = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'top';
  return {
    type: INSTRUCTIONS_TYPE.TOOLTIP,
    data: {
      text: text,
      position: position
    }
  };
};
var subtext = exports.subtext = function subtext(text) {
  return {
    type: INSTRUCTIONS_TYPE.SUBTEXT,
    data: {
      text: text
    }
  };
};
/*
 * Structures for tracking question answers
 *
 */

/*
 * Question answers are stored as key-value pairs of:
 *    `question-id: answer-id`.
 *
 * For checkbox questions, the answer is stored as an object mapping:
 *    `answer-id: checked-state`.
 *
 */
var answers = exports.answers = function answers(defaults) {
  return _objectSpread({}, defaults);
};
var getAnswer = exports.getAnswer = function getAnswer(answers, question) {
  return answers[question.id];
};
var getHumanAnswer = exports.getHumanAnswer = function getHumanAnswer(answers, question) {
  var checkboxAsList = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var userAnswer = answers[question.id];
  if (userAnswer === undefined || userAnswer === null) {
    return null;
  }
  if (question.type === QUESTION_TYPE.INPUT) {
    return userAnswer;
  }
  if (question.type === QUESTION_TYPE.RADIO) {
    var _answer = question.answers.find(function (answer) {
      return answer.id === userAnswer;
    });
    return _answer ? _answer.description : null;
  }
  if (question.type === QUESTION_TYPE.SELECT) {
    return userAnswer;
  }
  if (question.type === QUESTION_TYPE.CHECKBOX) {
    var _answers = question.answers.filter(function (answer) {
      return userAnswer[answer.id];
    }).map(function (answer) {
      return answer.description;
    });
    if (_answers.length < 1) return null;
    if (checkboxAsList) return _answers;
    return (0, _helpers.delimitWithCommasAndAnAnd)(_answers);
  }
  return null;
};
var checkAnswer = exports.checkAnswer = function checkAnswer(answers, question, answerId) {
  if (question.type === QUESTION_TYPE.CHECKBOX) {
    if (answers[question.id] === undefined || answers[question.id] === null) {
      return false;
    }
    var _answer2 = answers[question.id][answerId];
    return _answer2 ? true : false;
  }
  return answers[question.id] === answerId;
};

// Will return true if answerId's is an empty list and question isn't answered
// or has all false answers.
var checkboxOnlyWithAnswers = exports.checkboxOnlyWithAnswers = function checkboxOnlyWithAnswers(answers, question, answerIds) {
  if (question.type !== QUESTION_TYPE.CHECKBOX) {
    throw Error("".concat(question.id, " is not a checkbox question"));
  }
  var questionsAnswers = answers[question.id];
  if (!questionsAnswers && answerIds.length === 0) {
    return true;
  } else if (!questionsAnswers && answerIds.length > 0) {
    return false;
  } else if (Object.values(questionsAnswers).filter(function (a) {
    return a;
  }).length === answerIds.length && answerIds.every(function (answerId) {
    return questionsAnswers[answerId];
  })) {
    return true;
  }
  return false;
};

// Returns false if the userAnswer is not in the set of allowed answers.
var answerIsValidOption = function answerIsValidOption(question, userAnswer) {
  if (userAnswer === null || userAnswer === undefined) {
    return false;
  }
  if (question.type === QUESTION_TYPE.INPUT) {
    return true;
  }
  if (question.type === QUESTION_TYPE.SELECT) {
    return true;
  }
  var answerIds = Object.fromEntries(question.answers.map(function (answer) {
    return [answer.id, true];
  }));
  if (question.type === QUESTION_TYPE.RADIO) {
    return answerIds[userAnswer];
  }
  return Object.entries(userAnswer).filter(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      _ = _ref2[1];
    return answerIds[key];
  }).length > 0;
};

// Returns false if the userAnswer is not valid.
var isAnswered = exports.isAnswered = function isAnswered(question_, userAnswer) {
  if (userAnswer === null || userAnswer === undefined) {
    return false;
  }
  switch (question_.type) {
    case QUESTION_TYPE.CHECKBOX:
      // returns false if all checkbox items are unchecked (false)
      return Object.values(userAnswer).filter(function (a) {
        return a;
      }).length > 0;
    case QUESTION_TYPE.INPUT:
      return userAnswer.trim() !== '';
    case QUESTION_TYPE.RADIO:
      // This is already handled above in answer null check.
      return true;
    case QUESTION_TYPE.SELECT:
      return userAnswer;
    default:
      return false;
  }
};
var removeInvalidatedAnswers = function removeInvalidatedAnswers(questions, answers) {
  questions.map(function (question) {
    if (!question.filter(answers)) {
      delete answers[question.id];
    }
  });
  return answers;
};
var findQuestion = function findQuestion(questions, questionId) {
  var question = questions.find(function (_ref3) {
    var id = _ref3.id;
    return id === questionId;
  });
  if (!question) {
    throw "Invalid question (".concat(questionId, ").");
  }
  return question;
};
var toggleCheckbox = exports.toggleCheckbox = function toggleCheckbox(questions, answers, questionId, answerId) {
  var question = findQuestion(questions, questionId);
  if (question.type !== QUESTION_TYPE.CHECKBOX) {
    throw "Must use setAnswer for non-CHECKBOX questions (".concat(question.id, ")");
  }
  var questionAnswers = answers[questionId] || {};
  questionAnswers[answerId] = !questionAnswers[answerId];
  if (!answerIsValidOption(question, questionAnswers)) {
    throw "Invalid answer (".concat(answerId, ") for question (").concat(questionId, ")");
  }
  return removeInvalidatedAnswers(questions, _objectSpread(_objectSpread({}, answers), {}, _defineProperty({}, questionId, questionAnswers)));
};
var setAnswer = exports.setAnswer = function setAnswer(questions, answers, questionId, answer) {
  var question = findQuestion(questions, questionId);
  if (question.type === QUESTION_TYPE.CHECKBOX) {
    throw "Must use toggleCheckbox for CHECKBOX questions (".concat(question.id, ")");
  }
  if (!answerIsValidOption(question, answer)) {
    throw "Invalid answer (".concat(answer, ") for question (").concat(questionId, ")");
  }
  return removeInvalidatedAnswers(questions, _objectSpread(_objectSpread({}, answers), {}, _defineProperty({}, questionId, answer)));
};

/*
 * These are the actual question generators.
 *
 */

var getQuestionsForCategory = exports.getQuestionsForCategory = function getQuestionsForCategory(category, questions, answers) {
  var reducer = function reducer(questionsForCategory, question) {
    if (question.category === category && question.filter(answers)) {
      questionsForCategory.push(question);
    }
    return questionsForCategory;
  };
  return questions.reduce(reducer, []);
};
var getAnsweredQuestions = exports.getAnsweredQuestions = function getAnsweredQuestions(questions, answers) {
  return questions.filter(function (question) {
    return isAnswered(question, getAnswer(answers, question));
  });
};
var getUnansweredQuestions = exports.getUnansweredQuestions = function getUnansweredQuestions(questions, answers) {
  return questions.filter(function (question) {
    return !isAnswered(question, getAnswer(answers, question));
  });
};