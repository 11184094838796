@value principalBlue, pdsLightGray, pdsMediumGray from './common.module.css';
@value boldFont from './common.module.css';

.wrapper {
    width: 100%;

    display: flex;
    flex-direction: column;

    justify-content: center;

    font-size: 18px;
}

.summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 1200px;

    border: 1px solid pdsMediumGray;
    border-radius: 5px;

    padding: 25px 75px;
}

.header {
    width: 100%;

    display: flex;
    flex-direction: row;

    align-items: center;

    margin-bottom: 20px;
}

.title {
    composes: title from './assessment.module.css';

    line-height: 1.4;
    font-size: 2.2em;
}

.editLink {
    padding-right: 20px;

    line-height: 1.4;
    font-size: 2.2em;

    margin-bottom: 20px;
}

.instructions {
    padding: 20px;

    font-size: 1.25em;
}

.questions {
    width: 80%;

    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

.question {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 1200px;

    padding: 25px;
}

.questionBody {
    font-family: boldFont;
}

/* * * * * * * * * *
 * Nav Controls
 * * * * * * * * * */

.controls {
    width: 400px;
    margin: 35px;

    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: space-between;
}

.controls > div {
    padding: 5px 15px;
}

.iconLink {
    position: relative;
    right: 10px;
}
