/* Label Styles */
.label {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.isRequired {
    composes: isRequired from './common.module.css';
}

