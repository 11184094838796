@value principalBlue, pdsLightBlue, pdsDarkGray, pdsMediumGray, pdsMediumLightGray, pdsWarning from './common.module.css';
@value regularFont, boldFont, largeScreen, smallScreen from './common.module.css';

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 600px;
    margin: 0 1.25rem;
    padding: 40px 10px;
    box-shadow: 2px 2px 10px 3px pdsMediumLightGray;
    border-radius: 7px;
}

.advisorPageTitle {
    composes: sectionTitle from './common.module.css';
    font-size: 30px;
    line-height: normal;
    margin: 3rem 0 2rem 0;
}

.subCard {
    margin-top: 40px;
    padding: 30px 30px 30px 30px;
    min-height: 600px;
    box-shadow: 2px 2px 10px 3px pdsMediumLightGray;
    border-radius: 7px;
}

.validationMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardTitle {
    composes: sectionTitle from './common.module.css';
    font-size: 30px;
    margin: 0px;
}

.cardText {
    font-size: 18px;
}

.alertBox {
    margin: 30px 0px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.alertText {
    font-size: 13px;
    margin-left: 15px !important;
}

.alertImg {
    width: 25px;
    height: 25px;
}

.advisorForm {
    width: 90%;
}

.copyLinkSection {
    display: flex;
    flex-direction: row;
}

.textarea {
    width: 83%;
    word-wrap: break-word;
    resize: none;
    font-size: 16px;
    border: 1px solid pdsDarkGray;
    font-family: regularFont;
    padding: 10px 10px 0px 10px;
    border-radius: 4px;
    overflow-x: scroll;
    overflow-y: hidden;
}

.createPersonalLink {
    margin-top: 30px;
    height: 45px;
}

.copyButton {
    width: 75px;
    color: principalBlue;
    font-family: boldFont;
    border: 1px solid principalBlue;
    padding: 0 15px;
    border-radius: 4px;
}

.advisorButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 1rem 0 0 0;
}

.smCardText {
    font-size: 13.25px;
    margin: 4.85rem 0 0 0;
}

.getLink {
    display: block;
    text-decoration: underline;
}

.startAssessment {
    margin-top: auto;
    height: 45px;
}

.assessmentColumns {
    margin: 0 auto;
}

.cardImg > img {
    display: flex;
    flex-direction: row;
    margin: 4rem auto;
    width: 50%;
    height: auto;
}

@media screen and (max-width: largeScreen) {
    .card {
        margin: 0 0 1rem 0;
        width: 85%;
        max-width: 500px;
    }

    .startAssessment {
        margin: 0;
    }

    .advisorButtons {
        margin: 1rem 0;
    }

    .smCardText {
        margin: 0;
    }

    .getLink {
        font-size: 18px;
    }
}

@media screen and (max-width: smallScreen) {
    .card {
        width: 100%;
    }
}

.clientLandingCenter {
    text-align: center;
    margin: 0 auto;
}

.clientLandingTitlesLarge {
    font-family: boldFont;
    font-size: 32px;
}

.clientLandingStartButton {
    margin: 0;
}

.clientLandingTitlesSmall {
    font-family: boldFont;
    font-size: 24px;
    text-align: center;
}

.clientLandingContainer {
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.clientLandingSection {
    padding-top: 40px;
    padding-bottom: 40px;
    display: block;
    box-sizing: border-box;
}

.clientLandingImg {
    margin: auto;
    height: auto;
    display: block;
    max-width: 80%;
}

.clientLandingImg.cardImg {
    max-width: auto;
    padding: 1.8em 0;
}

li {
    font-size: 20px;
    padding-bottom: 10px;
}

.assessmentColumns {
    padding-left: 3em;
    padding-right: 3em;
}

.disclaimerText {
    color: #616267;
    text-align: center;
}

.disclaimerNumber {
    padding-left: 15px;
    color: #616267;
    text-align: left;
}
