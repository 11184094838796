import { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const ScrollToTop = ({ location, children }) => {
    const prevRef = useRef();

    useEffect(() => {
        prevRef.current = location;
    });

    if (location !== prevRef.current) {
        window.scrollTo(0, 0);
    }

    return children;
};

ScrollToTop.propTypes = {
    location: PropTypes.shape(
        { pathname: PropTypes.string.isRequired }
    ).isRequired,
    children: PropTypes.node
};

ScrollToTop.defaultProps = { children: null };

export default withRouter(ScrollToTop);
