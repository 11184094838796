import flow from '@principal/business-needs-assessment-tool-helpers';
import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { webAnalytics as wa} from "@principal/web-analytics";
import styles from '../../css/advisor.module.css';
import BulletScoreOrange from '../../img/bullet-orange.png';
import helpers from './helpers';

import BNALaptopImage from '../../img/graphic_screenshot_needsassess.png';
import TypingImage from '../../img/typing.png';

const AdvisorDisclaimer = () => {
    const location = useLocation();

    return (
        <>
            <p className={styles.disclaimerText}>
                This business needs assessment tool is made available as a
                self-help resource for your planning convenience. The results
                from the assessments are based upon your inputs and are not
                intended to be a financial plan or investment advice from the
                Principal Financial Group® but may be used as a general
                guideline to help you make business planning decisions.
                Responsibility for these decisions is assumed by you not the
                Principal Financial Group.
            </p>
            <p className={styles.disclaimerText}>
                {' '}
                All projections, analysis and calculation results are estimates
                and depend on many factors, including the date and assumptions
                you provide , and may not reflect all your sources of income or
                expenditures. In addition, it ignores future transactions or
                changes in tax laws which cannot be anticipated. The reports,
                graphs, and other analysis are dependent upon the accuracy of
                the data you provided.
            </p>
            <p className={styles.disclaimerText}>
                The business assessment is provided with the understanding that
                none of the member companies of Principal are rendering legal,
                accounting, or tax advice. Results may not be used to avoid
                penalties under the Internal Revenue Code. You should consult with
                appropriate counsel or other advisors on all matters pertaining
                to legal, tax, or accounting obligations and requirements.
            </p>
            <p className={styles.disclaimerText}>
                Insurance products issued by Principal National Life Insurance
                Co. (except in NY), Principal Life Insurance Company®, and the
                companies available through the Preferred Product Network, Inc.
                Plan administrative services offered by Principal Life.
                Securities offered through Principal Securities, Inc., member
                SIPC, and/or independent broker/dealers. Referenced companies
                are members of the Principal Financial Group®, Des Moines, IA
                50392.
            </p>
            {location.pathname === '/needs/assessment/advisor' && (
                <p className={styles.disclaimerText}>
                    For financial professional use only. Not for use with
                    consumers or the public. 1419703-112020
                </p>
            )}
            <p className={styles.disclaimerNumber}>2526283-112022</p>
        </>
    );
};

const ClientDisclaimer = () => (
    <p className={styles.disclaimerText}>
        Insurance products issued by Principal National Life Insurance Co.
        (except in NY) and Principal Life Insurance Co. Plan administrative
        services offered by Principal Life. Principal National and Principal
        Life are members of the Principal Financial Group®, Des Moines, IA
        50392. 1419703-112020
    </p>
);

const AlertMessage = ({ text }) => (
    <div className={styles.alertBox}>
        <div className="pds-card pds-alert pds-alert-warning">
            <div className="pds-card-section">
                <img
                    className={styles.alertImg}
                    src={BulletScoreOrange}
                    alt=""
                />
                <p role="alert" className={styles.alertText}>
                    <span className="sr-only">Attention</span>
                    {text}
                </p>
            </div>
        </div>
    </div>
);

const ClientLanding = ({ assessmentId }) => (
    <>
        <section className={styles.clientLandingSection}>
            <div className={styles.clientLandingContainer}>
                <div className="row">
                    <div className={styles.clientLandingCenter}>
                        <h1 className={styles.clientLandingTitlesLarge}>
                            How can you help protect the financial well-being of
                            your business?
                        </h1>
                        <p>
                            Use the Principal<sup>®</sup> Business Needs
                            Assessment to explore best practices to help protect
                            your business, your employees, and your lifestyle.
                        </p>
                        <p>
                            <Link
                                id="take-assessment"
                                to={helpers.appendAssessmentId(
                                    helpers.topicUrlBuilder(
                                        flow.TOPIC_PAGES[0].url
                                    ),
                                    assessmentId
                                )}
                                className={[
                                    styles.clientLandingStartButton,
                                    'pds-button pds-button-primary'
                                ].join(' ')}
                            >
                                Take an assessment to get your personalized
                                report
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.clientLandingContainer}>
                <div className="row">
                    <div className={styles.clientLandingCenter}>
                        <h2 className={styles.clientLandingTitlesLarge}>
                            Why should you take the Business Needs Assessment?
                        </h2>
                        <p>
                            As a business owner, a lot rests on the success of
                            your business. Answering a few questions about your
                            overall business and priorities could uncover ideas
                            to help improve your financial well-being and help
                            reach your business goals.
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <img
                            className={styles.clientLandingImg}
                            src={BNALaptopImage}
                            alt=""
                            title="Computer displaying the needs assessment."
                        />
                    </div>
                </div>
            </div>
            <div className={styles.clientLandingContainer}>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <h3 className={styles.clientLandingTitlesSmall}>
                            Who's this for?
                        </h3>
                        <ul>
                            <li>
                                Privately held and publicly traded businesses
                            </li>
                            <li>For-profit and nonprofit organizations</li>
                            <li>Businesses with any number of employees</li>
                            <li>All industries</li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <h3 className={styles.clientLandingTitlesSmall}>
                            What will you get?
                        </h3>
                        <ul>
                            <li>
                                A personalized report with an analysis of how
                                you are doing and feedback to help you reach
                                your business goals
                            </li>
                            <li>
                                Next steps to help secure your financial
                                well-being
                            </li>
                            <li>
                                Information on topics relevant to helping
                                protect your business from things like buy-sell
                                planning and funding, to plans for recruiting,
                                retaining, rewarding, and retiring your key
                                employees
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section className={styles.clientLandingSection}>
            <div className={styles.clientLandingContainer}>
                <div className="row">
                    <div className={styles.clientLandingCenter}>
                        <h1 className={styles.clientLandingTitlesLarge}>
                            Take control of your business and future with these
                            solutions to help:
                        </h1>
                    </div>
                </div>
            </div>
            <div className={styles.clientLandingContainer}>
                <div className="row">
                    <div
                        className={[
                            styles.assessmentColumns,
                            'col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-offset-0 col-md-4 col-lg-offset-0 col-lg-4 col-xl-4 col-xl-offset-0 phone-padding'
                        ].join(' ')}
                    >
                        <img
                            className={[
                                styles.clientLandingImg,
                                styles.cardImg
                            ].join(' ')}
                            src="https://www.principal.com/sites/default/files/graphic_business_needsassessment_bynder.png"
                            alt=""
                            title="Graphic of a business."
                        />
                        <h3 className={styles.clientLandingTitlesSmall}>
                            Protect your business
                        </h3>
                        <ul>
                            <li>Buy-sell and business transfer strategies</li>
                            <li>Key employee retention benefits</li>
                            <li>Key person protection</li>
                        </ul>
                    </div>
                    <div
                        className={[
                            styles.assessmentColumns,
                            'col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-offset-0 col-md-4 col-lg-offset-0 col-lg-4 col-xl-4 col-xl-offset-0 phone-padding'
                        ].join(' ')}
                    >
                        <img
                            className={[
                                styles.clientLandingImg,
                                styles.cardImg
                            ].join(' ')}
                            src="https://www.principal.com/sites/default/files/graphic_people_needsassessment_bynder.png"
                            alt=""
                            title="Graphic of people."
                        />
                        <h3 className={styles.clientLandingTitlesSmall}>
                            Protect your employees
                        </h3>
                        <ul>
                            <li>Qualified retirement plans</li>
                            <li>Group benefits</li>
                            <li>Key employee retirement benefits</li>
                        </ul>
                    </div>
                    <div
                        className={[
                            styles.assessmentColumns,
                            'col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-offset-0 col-md-4 col-lg-offset-0 col-lg-4 col-xl-4 col-xl-offset-0 phone-padding'
                        ].join(' ')}
                    >
                        <img
                            className={[
                                styles.clientLandingImg,
                                styles.cardImg
                            ].join(' ')}
                            src="https://www.principal.com/sites/default/files/graphic_house_needsassessment_bynder.png"
                            alt=""
                            title="Graphic of a house."
                        />
                        <h3 className={styles.clientLandingTitlesSmall}>
                            Protect your lifestyle
                        </h3>
                        <ul>
                            <li>Business owner strategies</li>
                            <li>Disability insurance</li>
                            <li>Life insurance</li>
                            <li>Legacy and estate planning</li>
                        </ul>
                    </div>
                </div>
                <ClientDisclaimer />
            </div>
        </section>
    </>
);

const mailToLink = (assessmentUrl, advisor) => {
    const body = `Hi [client/prospect name],
    
As a business leader, a lot of things might be on your mind. Maybe it’s the changing economy, recruiting and retaining employees, or your future financial independence.     

With proper planning, you can take control of your concerns. The Principal® Business Needs Assessment will get you started.    

This simple tool allows you to identify your top business concerns and priorities. Then, it provides a personalized report of how you’re doing and suggested next steps to help get you where you want to be.
    
To get started, simply click on your personalized link:
${assessmentUrl}
    
We can schedule a time to go through the assessment together. Or, you can do it on your own and we can discuss next steps after you get your personalized results.
    
Best regards,
    
${advisor.name}
[Your contact information]

Insurance products issued by Principal National Life Insurance Co. (except in NY) and Principal Life Insurance Co. Plan administrative services offered by Principal Life.
Principal National and Principal Life are members of the Principal Financial Group®, Des Moines, IA 50392. 1419703 112020
`;

    const mailToFriendlyBody = encodeURIComponent(body).replace(
        /%0A/g,
        '%0D%0A'
    );
    // mailto has a character limit of ~2000 characters on chrome on windows which can cause the email client to not be prompted and opened
    return `mailto:?cc=${advisor.email}&subject=How%20can%20you%20protect%20the%20financial%20well-being%20of%20your%20business%3F&body=${mailToFriendlyBody}`;
};

const AdvisorCardCTA = ({ advisor, assessmentId, saveError }) => {
    const [buttonText, setButtonText] = useState('Copy');
    const clientAssessmentLink = `${
        window.location.origin
    }${helpers.appendAssessmentId(helpers.urlBuilder('info'), assessmentId)}`;

    const cardHelper = innerEl => (
        <div className={styles.card}>
            <h2 className={styles.cardTitle}> Email Assessment </h2>
            {innerEl}
        </div>
    );
    if (saveError) {
        if (saveError.retrying) {
            return cardHelper(
                <p className={styles.cardText}>Generating assessment link...</p>
            );
        }

        return cardHelper(
            <div>
                <AlertMessage text="Sorry! There was an error generating your client's assessment link." />
                <a
                    // We want to reload the page to reset the state.
                    href="#"
                    onClick={() => window.location.reload()}
                    className={[
                        styles.getLink,
                        'pds-link pds-link-unstyled'
                    ].join(' ')}
                >
                    Let's try refreshing the app...
                </a>
            </div>
        );
    }

    return cardHelper(
        <div>
            <p className={styles.cardText}>
                Send this link to your business client or prospect.
            </p>

            <div className={styles.advisorButtons}>
                <a
                    href={mailToLink(clientAssessmentLink, advisor)}
                    className="pds-button pds-button-primary"
                >
                    Email link
                </a>
            </div>
            <p className={styles.cardText}>
                Or
                <br /> Copy the link below and place it in your own custom
                email.
            </p>
            <div className={styles.copyLinkSection}>
                <label htmlFor="link" className="sr-only">
                    Your personalized assessment link
                </label>
                <textarea
                    className={styles.textarea}
                    id="link"
                    readOnly
                    wrap="off"
                    rows="1"
                    cols="40"
                    value={clientAssessmentLink}
                />
                <button
                    className={styles.copyButton}
                    data-gtm="call-to-action"
                    type="button"
                    onClick={() => {
                        copy(clientAssessmentLink);
                        setButtonText('Copied!');
                        setTimeout(() => setButtonText('Copy'), 1300);
                        wa.trackEvent('modalClick', {
                            clicktext: 'Getting Link...',
                            modaltitle: 'Copy Link'
                        });
                    }}
                >
                    {buttonText}
                </button>
            </div>
            <AlertMessage
                text="Each link is unique and should only be sent to a single
                    recipient. Repeat this process for each email you send."
            />
            <a
                // We want to reload the page to reset the state.
                href="#"
                onClick={() => window.location.reload()}
                className={[styles.getLink, 'pds-link pds-link-unstyled'].join(
                    ' '
                )}
            >
                Get another personalized link
            </a>
        </div>
    );
};

const EmailAssessment = ({
    isGuided,
    setIsGuided,
    validateForm,
    submitForm,
    setIsvalid,
    validationMessage
}) => {
    return (
        <div className="col col-md-6 col-sm-12 col-xs-6 inline-display">
            <div className={styles.subCard}>
                <h2 className={styles.cardTitle}> Email Assessment </h2>
                <p className={styles.cardText}>
                    Send them an email to complete the assessment at their
                    convenience. Results will automatically be sent to you.
                    Start by creating a personalized link for each business
                    client or prospect.
                </p>
                <AlertMessage
                    text="Each link is unique and can only be sent to a single
                    individual. Repeat the link creation process for each email."
                />

                <button
                    type="button"
                    data-gtm="call-to-action"
                    className={[
                        styles.createPersonalLink,
                        'pds-button pds-button-primary'
                    ].join(' ')}
                    onClick={() => {
                        setIsGuided(false);
                        if (!validateForm()) {
                            return;
                        }
                        setIsvalid(true);
                        submitForm(false);
                        wa.trackEvent('formSubmit', {
                            name: 'Create personal link',
                            type: 'contact-prospect',
                            lead: 'yes'
                        });

                    }}
                >
                    Create personal link
                </button>

                <div className="styles.validationMessage">
                    {validationMessage && !isGuided && (
                        <span className="pds-error" id="error">
                            {validationMessage}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

const GuidedAssessment = ({
    advisor,
    isGuided,
    setIsGuided,
    submitForm,
    assessmentId,
    validateForm,
    validationMessage
}) => {
    const history = useHistory();
    advisor.isGuided = false;
    return (
        <div className="col col-md-6 col-sm-12 col-xs-6 inline-display">
            <div className={styles.subCard}>
                <h2 className={styles.cardTitle}> Guided Assessment </h2>
                <p className={styles.cardText}>
                    Meet in person, or schedule a virtual meeting, to complete
                    the assessment together.
                </p>
                <div className={styles.cardImg}>
                    <img src={TypingImage} alt="" />
                </div>
                <button
                    type="button"
                    data-gtm="call-to-action"
                    onClick={() => {
                        setIsGuided(true);
                        if (!validateForm()) {
                            return;
                        }
                        submitForm(true);
                        history.push(
                            helpers.appendAssessmentId(
                                helpers.topicUrlBuilder(
                                    flow.TOPIC_PAGES[0].url
                                ),
                                assessmentId
                            )
                        );
                        wa.trackEvent('formSubmit', {
                            name: 'Start assessment now',
                            type: 'contact-prospect',
                            lead: 'yes'
                        });
                    }}
                    className={[
                        styles.startAssessment,
                        'pds-button pds-button-primary'
                    ].join(' ')}
                >
                    Start assessment now
                </button>

                <div className="styles.validationMessage">
                    {validationMessage && isGuided && (
                        <span className="pds-error" id="error">
                            {validationMessage}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

// Main Component of advisor page which includes E-Mail and Guided Assessment
const MainForm = ({ assessmentActions, advisor, assessmentId, saveError }) => {
    const [advisorName, setAdvisorName] = useState('');
    const [advisorEmail, setAdvisorEmail] = useState('');
    const [validationMessage, setValidationMessage] = useState(null);
    const [isGuided, setIsGuided] = useState(undefined);
    const [isValid, setIsvalid] = useState(false);

    const validEmailPattern = new RegExp(
        '^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$',
        'gmi'
    );
    const emailIsValid = email => validEmailPattern.test(email);
    const nameIsValid = name => name && name !== '';

    const validateForm = () => {
        if (!nameIsValid(advisorName)) {
            setValidationMessage('Please enter your name');
            return false;
        }
        if (!emailIsValid(advisorEmail)) {
            setValidationMessage('Please enter a valid email address');
            return false;
        }

        return true;
    };

    const submitForm = IsGuidedFlag => {
        assessmentActions.setAdvisorInfo(advisorName, advisorEmail, IsGuidedFlag);
    };

    if (
        advisorName !== '' &&
        advisorEmail !== '' &&
        isGuided !== undefined &&
        !isGuided &&
        isValid
    ) {
        return (
            <AdvisorCardCTA
                assessmentId={assessmentId}
                advisor={advisor}
                saveError={saveError}
            />
        );
    }

    return (
        <div className={styles.card}>
            <h1 className={styles.advisorPageTitle}>
                Use one of these two options to work with your client or
                prospect to complete the business needs assessment.
            </h1>
            <form className={styles.advisorForm}>
                {/* Form starts */}
                <div className="row">
                    <div className="col col-md-6 col-sm-12 col-xs-6 inline-display">
                        <div className="pds-labeledInput styles.subCard">
                            <label htmlFor="advisor-name">
                                Financial Professional Name
                                <span
                                    className="pds-label-required"
                                    aria-hidden="true"
                                >
                                    *
                                </span>
                            </label>
                            <input
                                id="advisor-name"
                                type="text"
                                name="advisor-name"
                                value={advisorName || ''}
                                required
                                aria-required="true"
                                onChange={evt =>
                                    setAdvisorName(evt.target.value)
                                }
                                aria-describedby="error"
                                aria-invalid="true"
                            />
                        </div>
                    </div>

                    <div className="col col-md-6 col-sm-12 col-xs-6 inline-display">
                        <div className="pds-labeledInput styles.subCard">
                            <label htmlFor="advisor-email">
                                Financial Professional Email
                                <span
                                    className="pds-label-required"
                                    aria-hidden="true"
                                >
                                    *
                                </span>
                            </label>
                            <input
                                id="advisor-email"
                                type="text"
                                name="advisor-email"
                                value={advisorEmail || ''}
                                required
                                aria-required="true"
                                onChange={evt =>
                                    setAdvisorEmail(evt.target.value)
                                }
                                aria-describedby="error"
                                aria-invalid="true"
                            />
                        </div>
                    </div>
                </div>

                {/* Form ends */}

                {/* Sub Components starts */}
                <div className="row">
                    <EmailAssessment
                        isGuided={isGuided}
                        setIsGuided={setIsGuided}
                        validateForm={validateForm}
                        submitForm={submitForm}
                        setIsvalid={setIsvalid}
                        validationMessage={validationMessage}
                    />

                    <GuidedAssessment
                        advisor={advisor}
                        isGuided={isGuided}
                        setIsGuided={setIsGuided}
                        submitForm={submitForm}
                        assessmentId={assessmentId}
                        validateForm={validateForm}
                        validationMessage={validationMessage}
                    />
                </div>
                {/* Sub Components ends */}
            </form>
        </div>
    );
};

export default {
    MainForm,
    ClientLanding,
    AdvisorDisclaimer
};
