import flow from '@principal/business-needs-assessment-tool-helpers';

import styles from '../../css/assessment.module.css';
import { QuestionAndAnswers } from './question';
import { Link } from 'react-router-dom';
import Results from './results';
import React from 'react';
import helpers from './helpers';

const TestHelper = ({
    allQuestions,
    assessmentId,
    category,
    answers,
    setAnswer,
    toggleCheckbox,
    advisor,
    showContactFormModal
}) => (
    <div className={styles.testWrapper}>
        <div className={styles.testQuestionColumn}>
            {
                allQuestions.reduce(
                    (acc, question, index) => {
                        acc.questions.push(
                            <div
                                key={question.id}
                                style={{
                                    paddingBottom: '20px',
                                    background: acc.disabled
                                        ? '#EEE'
                                        : '#ffffff',
                                    opacity: acc.disabled ? 0.5 : 1
                                }}
                            >
                                <QuestionAndAnswers
                                    question={question}
                                    nextUrl={helpers.appendAssessmentId(
                                        helpers.nextQuestionHelper(
                                            category.id,
                                            allQuestions[index + 1]
                                        ),
                                        assessmentId
                                    )}
                                    testMode
                                    answerAction={
                                        question.type ===
                                        flow.QUESTION_TYPE.CHECKBOX
                                            ? toggleCheckbox
                                            : setAnswer
                                    }
                                    userAnswer={answers[question.id]}
                                />
                            </div>
                        );

                        if (!answers[question.id]) {
                            acc.disabled = true;
                        }
                        return acc;
                    },
                    { disabled: false, questions: [] }
                ).questions
            }
        </div>
        <div className={styles.testResultsColumn}>
            <div className={styles.testCategoriesNav}>
                {Object.values(flow.CATEGORIES).map(cat => (
                    <Link
                        key={cat.id}
                        type="button"
                        className="pds-button pds-button-primary"
                        to={helpers.questionUrlBuilder(
                            cat.id,
                            'app-validation-test'
                        )}
                    >
                        Test {cat.actionPhrase}
                    </Link>
                ))}
            </div>
            <Results
                assessmentId={assessmentId}
                categoryId={category.id}
                answers={answers}
                advisor={advisor}
                showContactFormModal={showContactFormModal}
            />
        </div>
    </div>
);

export default TestHelper;
