import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import stateChecker from '../../state-checker';

import styles from '../../css/modal.module.css';

const ModalState = {
    Initial: id => {
        const state = {
            showing: false,
            body: null,
            header: null
        };
        if (id) {
            return { [id]: state };
        }
        return state;
    },
    Actions: update => ({
        showModal: id => update({ [id]: { showing: true } }),
        hideModal: id => update({ [id]: { showing: false } }),
        setModalContent: (id, header, body) =>
            update({
                [id]: { header: () => header, body: () => body }
            }),
        showModalContent: (id, header, body) =>
            update({
                // We wrap header/body in a thunk (parameterless function) because
                // if a field is a function, mergerino will execute it.
                // Since header/body are functions with the interface `function (state, actions) {..}`,
                // we need to wrap the function within a function.
                //
                // See mergerino behavior for fields as functions. https://github.com/fuzetsu/mergerino#usage-guide
                // "If you want to replace a property based on its current value, use a function."
                [id]: { showing: true, header: () => header, body: () => body }
            })
    })
};

const Modal = ({ id, state, actions }) => {
    if (!state[id].showing) {
        return null;
    }

    useEffect(() => {
        const handler = evt =>
            evt.keyCode === 27 ? actions.hideModal(id) : null;
        window.addEventListener('keydown', handler);
        return () => window.removeEventListener('keydown', handler);
    }, []);

    return (
        <div className={styles.wrapper}>
            <div id="modal-content" className={styles.modal}>
                <div className={styles.header}>
                    {state[id].header(state, actions)}
                    <button
                        type="button"
                        className={styles.close}
                        aria-label="close"
                        onClick={evt => {
                            actions.hideModal(id);
                            evt.preventDefault();
                        }}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className={styles.content}>
                    {state[id].body(state, actions)}
                </div>
            </div>
        </div>
    );
};

Modal.defaultProps = {
    header: () => null, // eslint-disable-line react/default-props-match-prop-types, max-len
    content: () => null // eslint-disable-line react/default-props-match-prop-types, max-len
};

Modal.propTypes = {
    id: PropTypes.string.isRequired,
    state: stateChecker(
        PropTypes.shape({
            showing: PropTypes.bool.isRequired,
            header: PropTypes.func,
            content: PropTypes.func
        })
    ).isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired
};

export default {
    State: ModalState,
    Component: Modal
};
