import React from 'react';
import PropTypes from 'prop-types';

import stateChecker from '../../state-checker';

import Label from '../label';
import TextInput from '../text-input';
import ErrorMessage from '../error-message';

import styles from '../../css/text-entry.module.css';

const EmailInputState = {
    Initial: id => TextInput.State.Initial(id, {
        title: 'Email',
        type: 'email',
        allowNull: false
    }),
    getEmail: TextInput.State.get,
    isValid: TextInput.State.isValid,
    Actions: update => {
        const textInputActions = TextInput.State.Actions(update);

        return {
            ...textInputActions,
            validateEmail: textInputActions.validateText
        };
    }
};

const EmailInputComponent = ({ id, state, actions }) => (
    <div className={styles.entry}>
        <Label.Component htmlFor={`text-${id}`}
            label="Email"
            required
        />
        <div className={styles.input}>
            <TextInput.Component id={id} state={state} actions={actions} />
        </div>
        <ErrorMessage hasError={state[id].hasError}
            errorMsg={state[id].errorMsg}
        />
    </div>
);

EmailInputComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: TextInput.actionPropTypes.isRequired,
    /* eslint-disable react/require-default-props */
    state: stateChecker(
        TextInput.statePropTypes.isRequired
    ) /* eslint-enable react/require-default-props */
};


export default {
    State: EmailInputState,
    Component: EmailInputComponent
};
