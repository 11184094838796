const assert = (condition, msg) => {
    if (!condition) throw new Error(msg);
};

// The code below is extracted from the firebase-js-sdk to give us firestore doc
// ID generation w/o bringing in the whole library.
const randomBytes = nBytes => {
    assert(nBytes >= 0, `Expecting non-negative nBytes, got: ${nBytes}`);
    const bytes = new Uint8Array(nBytes);
    crypto.getRandomValues(bytes);
    return bytes;
};

export const newDocId = () => {
    // Alphanumeric characters
    const chars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    // The largest byte value that is a multiple of `char.length`.
    const maxMultiple = Math.floor(256 / chars.length) * chars.length;
    assert(
        maxMultiple > 0 && maxMultiple < 256,
        `Expect maxMultiple to be (0, 256), but got ${maxMultiple}`
    );

    let autoId = '';
    const targetLength = 20;
    while (autoId.length < targetLength) {
        const bytes = randomBytes(40);
        for (let i = 0; i < bytes.length; ++i) {
            // Only accept values that are [0, maxMultiple), this ensures they can
            // be evenly mapped to indices of `chars` via a modulo operation.
            if (autoId.length < targetLength && bytes[i] < maxMultiple) {
                autoId += chars.charAt(bytes[i] % chars.length);
            }
        }
    }
    assert(autoId.length === targetLength, `Invalid auto ID: ${  autoId}`);

    return autoId;
};

// Promise helpers
export const wait = interval =>
    new Promise(resolve => setTimeout(resolve, interval));

// Retries a promise a given maximum retry count and delays between retries with
// an exponential backoff
export const retry = async (promiseThunk, retryCount = 6, retryDelay = 200) => {
    try {
        return await promiseThunk();
    } catch (error) {
        if (retryCount === 0) {
            throw new Error(error);
        }
        await wait(retryDelay);
        return await retry(promiseThunk, retryCount - 1, retryDelay * 2);
    }
};
