@value principalBlue, pdsLightGray from './common.module.css';

/* * * * * * * * * *
 * Progress Bar
 * * * * * * * * * */

.progress {
    width: calc(100% - 30px);
    height: 15px;

    margin: 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.bar {
    height: 100%;
    width: 100%;

    border-radius: 10px;
    background: pdsLightGray;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;
}

.completed {
    height: 100%;
    flex: 2;

    border-radius: 10px;
    background: principalBlue;
}

.remaining {
    height: 100%;
    flex: 1;
}
