import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../css/assessment.module.css';
import { BackButton, NextButton } from './nav-buttons';

const SHORT_STATEMENT_LEN = 30;
const MEDIUM_STATEMENT_LEN = 70;

const Controls = ({ nextPage }) => {
    return (
        <div className={styles.controls}>
            <BackButton />
            <NextButton nextUrl={nextPage} />
        </div>
    );
};

Controls.propTypes = {
    nextPage: PropTypes.string.isRequired
};

const AreaOfConcern = ({
    preTitle,
    title,
    topicName,
    completions,
    nextPage,
    concernToggler,
    concernStateChecker
}) => {
    const concernClassHelper = concern => {
        const cssClasses = [styles.statement];

        const length = concern.length;
        if (length < SHORT_STATEMENT_LEN) {
            cssClasses.push(styles.small);
        } else if (length < MEDIUM_STATEMENT_LEN) {
            cssClasses.push(styles.medium);
        }

        if (concernStateChecker(concern)) {
            cssClasses.push(styles.selected);
        }

        return { classNames: cssClasses.join(' ') };
    };

    return (
        <div className={styles.assessment}>
            <div className={styles.preTitle}>{preTitle}</div>
            <h1 className={styles.title}>{title}</h1>
            <div className={styles.atopic}>{topicName}</div>
            <div className={styles.statements}>
                {completions.map(concern => {
                    const { classNames } = concernClassHelper(concern);
                    return (
                        <div key={concern} className={classNames}>
                            <div
                                role="checkbox"
                                tabIndex="0"
                                aria-checked={concernStateChecker(concern)}
                                onKeyDown={evt => {
                                    if (evt.keyCode === 13) {
                                        concernToggler(concern);
                                    }
                                }}
                                onClick={() => concernToggler(concern)}
                            >
                                {concern}
                            </div>
                        </div>
                    );
                })}
            </div>
            <Controls nextPage={nextPage} />
        </div>
    );
};

AreaOfConcern.propTypes = {
    preTitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    topicName: PropTypes.string.isRequired,
    completions: PropTypes.arrayOf(PropTypes.string).isRequired,
    nextPage: PropTypes.string.isRequired,
    concernToggler: PropTypes.func.isRequired,
    concernStateChecker: PropTypes.func.isRequired
};

export default AreaOfConcern;
