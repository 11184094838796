"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.privateForProfit = exports["default"] = exports.businessType = exports.businessPublic = exports.businessName = exports.businessEmployeeCount = exports.businessCount = void 0;
var _engine = require("../engine");
var _helpers = require("./helpers");
var _categories = require("../categories");
var GROUP_TITLE = 'Business profile';

/* * * * * * * * * *
 * Questions - Business Profile
 * * * * * * * * * */

var businessType = exports.businessType = (0, _engine.radioQuestion)('business-type', _categories.CAT_PROFILE, GROUP_TITLE, 'What type of business do you have?', [(0, _engine.answer)('profit', 'For profit'), (0, _engine.answer)('non-profit', 'Not for profit')]);
var businessPublic = exports.businessPublic = (0, _engine.radioQuestion)('public-company', _categories.CAT_PROFILE, GROUP_TITLE, 'Is your business publicly traded?', _helpers.YES_NO, function (answers) {
  return (0, _engine.checkAnswer)(answers, businessType, 'profit');
});
var privateForProfit = exports.privateForProfit = function privateForProfit(answers) {
  return (0, _engine.checkAnswer)(answers, businessType, 'profit') && (0, _engine.checkAnswer)(answers, businessPublic, 'no');
};
var businessCount = exports.businessCount = (0, _engine.radioQuestion)('business-count', _categories.CAT_PROFILE, GROUP_TITLE, 'How many businesses do you own?', [(0, _engine.answer)('one', 'I own one business'), (0, _engine.answer)('multiple', 'I own multiple businesses')], privateForProfit);

// TODO: Add question information / instructions section. Here annotate that this is for _their_ reference only.
// TODO: Add information that is responsive to their answers. WHen they put in the name, display "Please complete the remaining questions as ______".
var businessName = exports.businessName = (0, _engine.inputQuestion)('business-name', _categories.CAT_PROFILE, GROUP_TITLE, 'What is your company name?', [], undefined, function (businessNameAnswer) {
  return businessNameAnswer && businessNameAnswer.trim() ? (0, _engine.subtext)("Please answer all remaining questions as ".concat(businessNameAnswer.trim(), ". The company name will be printed on your results page.  This will allow you to easily identify which business the assessment is for, particularly for those who have multiple businesses.")) : (0, _engine.subtext)("You'll answer all remaining questions as this company. The company name will be printed on your results page.  This will allow you to easily identify which business the assessment is for, particularly for those who have multiple businesses.");
});
var businessState = (0, _engine.selectQuestion)('business-state', _categories.CAT_PROFILE, GROUP_TITLE, 'Where is your company headquartered?', []);
var businessOwners = (0, _engine.radioQuestion)('business-owners', _categories.CAT_PROFILE, GROUP_TITLE, 'How many owners does your business have?', [(0, _engine.answer)('just-me', 'Has one owner, me'), (0, _engine.answer)('multiple', 'Has multiple owners')], privateForProfit);
var businessFamily = (0, _engine.radioQuestion)('family-business', _categories.CAT_PROFILE, GROUP_TITLE, 'Is your business family-owned (all owners are related/relatives)?', _helpers.YES_NO, function (answers) {
  return privateForProfit(answers) && (0, _engine.checkAnswer)(answers, businessOwners, 'multiple');
});
var businessExistence = (0, _engine.radioQuestion)('business-existed-for', _categories.CAT_PROFILE, GROUP_TITLE, 'How long has the business been in existence?', [(0, _engine.answer)('transitioning-to-me', 'Is transitioning to me (less than 1 year)'), (0, _engine.answer)('just-starting', 'I’m just starting out (1-3 years in business)'), (0, _engine.answer)('growing-steadily', 'Growing steadily (3-10 years in business)'), (0, _engine.answer)('well-established', 'Well established (11+ years in business)'), (0, _engine.answer)('transitioning-from-me', 'Is in the process of transitioning to someone else')]);
var businessEntityType = (0, _engine.radioQuestion)('entity-type', _categories.CAT_PROFILE, GROUP_TITLE, 'What type of legal entity is your business?', [(0, _engine.answer)('corporation', 'Corporation'), (0, _engine.answer)('llc', 'LLC'), (0, _engine.answer)('partnership', 'Partnership'), (0, _engine.answer)('sole-proprietorship', 'Sole proprietorship')], function (answers) {
  return (0, _engine.checkAnswer)(answers, businessType, 'profit');
}, function () {
  return (0, _engine.tooltip)("Corporations vs. Limited Liability Company (LLC): One of the biggest differences between corporations and LLCs is the way they are taxed. An LLC is taxed as a pass-through entity unless they choose to be taxed as a C corporation. A passthrough entity means that the profits of the business are \"passed through\" to the owners (called members).\r\n \r\n" + 'C corporation vs. S corporation: The biggest difference between C and S corporations is taxes. C corporations pay tax on their income, plus you pay tax on whatever income you receive as an owner or employee. An S corporation doesn\'t pay tax. Instead, you and the other owners report the company revenue as personal income.', 'bottom');
});
var businessTaxCorp = (0, _engine.radioQuestion)('corp-tax-class', _categories.CAT_PROFILE, GROUP_TITLE, 'How is your corporation taxed?', [(0, _engine.answer)('c-corporation', 'C Corporation'), (0, _engine.answer)('s-corporation', 'S Corporation')], function (answers) {
  return (0, _engine.checkAnswer)(answers, businessEntityType, 'corporation');
});
var businessTaxLLC = (0, _engine.radioQuestion)('llc-tax-class', _categories.CAT_PROFILE, GROUP_TITLE, 'How is your LLC taxed?', [(0, _engine.answer)('partnership', 'Partnership'), (0, _engine.answer)('c-corporation', 'C Corporation'), (0, _engine.answer)('s-corporation', 'S Corporation')], function (answers) {
  return (0, _engine.checkAnswer)(answers, businessEntityType, 'llc');
});
var businessEmployeeCount = exports.businessEmployeeCount = (0, _engine.radioQuestion)('number-of-employees', _categories.CAT_PROFILE, GROUP_TITLE, 'How many employees does your business have?', [(0, _engine.answer)('under-ten', '0 - 10'), (0, _engine.answer)('eleven-fifty', '11 - 50'), (0, _engine.answer)('fiftyone-onehundred', '51 - 100'), (0, _engine.answer)('onehundred-twohundred', '101 - 200'), (0, _engine.answer)('twohundred-thousand', '201 - 1,000'), (0, _engine.answer)('over-thousand', '1,001+')]);
var _default = exports["default"] = [businessName, businessState, businessType, businessPublic, businessCount, businessOwners, businessFamily, businessExistence, businessEntityType, businessTaxCorp, businessTaxLLC, businessEmployeeCount];