@value pdsDarkGray, pdsMediumGray, largeScreen from './common.module.css';
@value regularFont, boldFont from './common.module.css';

/* * * * * * * * * *
 * Nav Controls
 * * * * * * * * * */

.controls {
    width: 400px;
    margin: 35px;

    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: space-between;
}

@media all and (max-width: largeScreen) {
    .controls {
        margin-top: 50px;
        width: 250px;
    }
    .controls button {
        padding: 7px 10px;
    }
}

.controls > div {
    padding: 5px 15px;
}

.iconLink {
    position: relative;
    right: 10px;
}

/* * * * * * * * * *
 * Questions
 * * * * * * * * * */

.wrapper {
    width: 100%;

    display: flex;
    flex-direction: column;

    justify-content: center;
}

.question {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 1200px;

    border: 1px solid pdsMediumGray;
    border-radius: 5px;

    padding: 25px;
}

.inputWrapper {
    width: 250px;
}

.checkboxWrapper {
    padding-top: 5px;
}

.instructions {
    margin: 25px;
    color: pdsDarkGray;
    font-size: 15px;
    width: 70%;
    text-align: center;
}

@media all and (max-width: largeScreen) {
    .instructions {
        width: 95%;
    }
}

.category {
    font-size: 25px;
    font-family: boldFont;
    color: pdsDarkGray;
    text-align: center;

    padding: 15px 0px;
    margin: 0;
}

.bodyWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px 0px;
}

.body {
    font-size: 20px;
    color: pdsDarkGray;
    max-width: 750px;
    text-align: center;
}

.inputQuestion {
    font-family: regularFont;
}

.tooltip {
    margin-left: 5px;
}

.selectAll {
    margin: 5px 0px;
    color: pdsDarkGray;
    font-size: 15px;
    width: 100%;
    text-align: center;
}

@media all and (max-width: largeScreen) {
    .tooltip {
        display: none;
    }
}

.backButton {
    cursor: pointer;
}
