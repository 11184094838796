import { DateTime } from 'luxon';

const convertDate = inputDate => {
    let date;
    const datePatterns = ['MM/dd/yyyy', 'M/d/yyyy', 'MM/d/yyyy', 'M/dd/yyyy', 'yyyy-MM-dd'];
    datePatterns.some(pattern => {
        date = DateTime.fromFormat(inputDate, pattern);
        return !date.invalid;
    });
    return date;
};

export default convertDate;
