"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.YES_NO_UNSURE = exports.YES_NO = exports.TWO_FIVE_OVER = void 0;
var _engine = require("../engine");
/* * * * * * * * * *
 * Question Helpers
 * * * * * * * * * */

var YES_NO = exports.YES_NO = [(0, _engine.answer)('yes', 'Yes'), (0, _engine.answer)('no', 'No')];
var YES_NO_UNSURE = exports.YES_NO_UNSURE = [(0, _engine.answer)('yes', 'Yes'), (0, _engine.answer)('no', 'No'), (0, _engine.answer)('unsure', "I'm not sure")];
var TWO_FIVE_OVER = exports.TWO_FIVE_OVER = [(0, _engine.answer)('under-2', 'Less than 2 years ago'), (0, _engine.answer)('2-5', '2 – 5 years ago'), (0, _engine.answer)('over-5', 'More than 5 years ago')];