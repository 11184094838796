import React from 'react';
import PropTypes from 'prop-types';

import stateChecker from '../../state-checker';

import Label from '../label';
import TextInput from '../text-input';
import ErrorMessage from '../error-message';

import styles from '../../css/name-entry.module.css';

const BusinessNameInputState = {
    Initial: id => TextInput.State.Initial(id, {
        title: 'Business name',
        type: 'text',
        allowNull: false
    }),
    getBusinessName: TextInput.State.get,
    isValid: TextInput.State.isValid,
    Actions: update => {
        const textInputActions = TextInput.State.Actions(update);

        return {
            ...textInputActions,
            validateBusinessName: textInputActions.validateText
        };
    }
};

const BusinessNameInputComponent = ({ id, state, actions }) => (
    <div className={styles.entry}>
        <Label.Component htmlFor={`text-${id}`}
            label="Business name"
            required
        />
        <TextInput.Component id={id} state={state} actions={actions} />
        <ErrorMessage hasError={state[id].hasError}
            errorMsg={state[id].errorMsg}
        />
    </div>
);

BusinessNameInputComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: TextInput.actionPropTypes.isRequired,
    /* eslint-disable react/require-default-props */
    state: stateChecker(
        TextInput.statePropTypes.isRequired
    ) /* eslint-enable react/require-default-props */
};


export default {
    State: BusinessNameInputState,
    Component: BusinessNameInputComponent
};
