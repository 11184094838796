import * as Icon from 'react-feather';
import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { webAnalytics as wa } from '@principal/web-analytics';

import styles from '../../css/nav-buttons.module.css';

export const BackButton = () => {
    const history = useHistory();
    return (
        <div
            // TODO Fix linting error about a11y
            role="button"
            style={{ cursor: 'pointer' }}
            onClick={() => {
                history.goBack();
                wa.trackEvent('pageNavigationClick', {
                    clicktext: 'Back',
                    navigationsection: 'call to action'
                });
            }}
        >
            <a className="pds-link-unstyled pds-link-icon-left">
                <span className="pds-link-icon">
                    <Icon.ChevronLeft />
                </span>
                <span className={styles.backButtonText}>Back</span>
            </a>
        </div>
    );
};

export const NextButton = ({ nextUrl, disabled = false }) => {
    const history = useHistory();
    return (
        <button
            type="button"
            onClick={() => {
                history.push(nextUrl);
                wa.trackEvent('pageNavigationClick', {
                    clicktext: 'Next',
                    navigationsection: 'call to action'
                });
            }}
            className={`${styles.temporaryButtonHack} pds-button pds-button-primary`}
            aria-disabled={disabled}
            disabled={disabled}
        >
            <span className={styles.nextButtonText}>Next</span>
            <Icon.ChevronRight />
        </button>
    );
};

NextButton.defaultProps = { disabled: false };

NextButton.propTypes = {
    nextUrl: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};
