import React from 'react';
import PropTypes from 'prop-types';

import stateChecker from '../../state-checker';

import Label from '../label';
import TextInput from '../text-input';
import ErrorMessage from '../error-message';

import styles from '../../css/name-entry.module.css';

const PhoneInputState = {
    Initial: id => TextInput.State.Initial(id, {
        title: 'Phone number',
        type: 'tel',
        allowNull: false,
        placeholder: '(xxx) xxx-xxxx'
    }),
    getPhone: TextInput.State.get,
    isValid: TextInput.State.isValid,
    Actions: update => {
        const textInputActions = TextInput.State.Actions(update);

        return {
            ...textInputActions,
            validatePhone: textInputActions.validateText
        };
    }
};

const PhoneInputComponent = ({ id, state, actions }) => (
    <div className={styles.entry}>
        <Label.Component htmlFor={`text-${id}`}
            label="Phone number"
            required
        />
        <TextInput.Component id={id} state={state} actions={actions} />
        <ErrorMessage hasError={state[id].hasError}
            errorMsg={state[id].errorMsg}
        />
    </div>
);

PhoneInputComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: TextInput.actionPropTypes.isRequired,
    /* eslint-disable react/require-default-props */
    state: stateChecker(
        TextInput.statePropTypes.isRequired
    ) /* eslint-enable react/require-default-props */
};


export default {
    State: PhoneInputState,
    Component: PhoneInputComponent
};
