import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import flow from '@principal/business-needs-assessment-tool-helpers';

import styles from '../../css/assessment-question-summary.module.css';
import ProgressBar from './progress';
import { BackButton, NextButton } from './nav-buttons';

const QuestionSummary = ({ question, answers }) => (
    <div className={styles.question}>
        <div className={styles.questionBody}>{question.question}</div>
        <div className={styles.answer}>
            {flow.getHumanAnswer(answers, question)}
        </div>
    </div>
);

QuestionSummary.propTypes = {
    question: PropTypes.shape({
        answers: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                description: PropTypes.string
            })
        ).isRequired,
        category: PropTypes.string.isRequired,
        filter: PropTypes.func.isRequired,
        group: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        instructions: PropTypes.func,
        question: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }).isRequired,
    answers: PropTypes.objectOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.objectOf(PropTypes.bool)
        ])
    ).isRequired
};

const Summary = ({
    questionBlock,
    answers,
    questionIndex,
    questionCount,
    nextUrl
}) => (
    <div className={styles.wrapper}>
        <ProgressBar pos={questionIndex} count={questionCount} />
        <div className={styles.summary}>
            {questionBlock.map(({ title, questions, editUrl }) => (
                <>
                    <div className={styles.header}>
                        <div className={styles.title}>
                            {title} answer summary
                        </div>
                        <div className={styles.editLink}>
                            <Link className="pds-link-unstyled" to={editUrl}>
                                Edit
                            </Link>
                        </div>
                    </div>
                    <div className={styles.instructions}>
                        You previously answered these questions and we wanted to
                        give you the opportunity to review them. If everything
                        looks correct, click Next to move on otherwise you may
                        edit your prior answers.
                    </div>
                    <div className={styles.questions}>
                        {questions.map(question => (
                            <QuestionSummary
                                key={question.id}
                                question={question}
                                answers={answers}
                            />
                        ))}
                    </div>
                </>
            ))}
            <div className={styles.controls}>
                <BackButton />
                <NextButton nextUrl={nextUrl} disabled={false} />
            </div>
        </div>
    </div>
);

Summary.propTypes = {
    questions: PropTypes.arrayOf(
        PropTypes.shape({
            answers: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string,
                    description: PropTypes.string
                })
            ).isRequired,
            category: PropTypes.string.isRequired,
            filter: PropTypes.func.isRequired,
            group: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            instructions: PropTypes.func,
            question: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired
        })
    ).isRequired,
    answers: PropTypes.objectOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.objectOf(PropTypes.bool)
        ])
    ).isRequired,
    editUrl: PropTypes.string.isRequired,
    nextUrl: PropTypes.string.isRequired,
    questionIndex: PropTypes.number.isRequired,
    questionCount: PropTypes.number.isRequired
};

export default Summary;
