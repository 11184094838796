import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { webAnalytics as wa} from "@principal/web-analytics";

import flow from '@principal/business-needs-assessment-tool-helpers';
import formatText from './text-formatter';

import styles from '../../css/assessment-results.module.css';

import OverallScoreGreenImg from '../../img/overall-green.png';
import OverallScoreYellowImg from '../../img/overall-yellow.png';
import OverallScoreOrangeImg from '../../img/overall-orange.png';

import BulletScoreGreen from '../../img/bullet-green.png';
import BulletScoreYellow from '../../img/bullet-yellow.png';
import BulletScoreOrange from '../../img/bullet-orange.png';

import BulletScoreGreenTransparent from '../../img/bullet-green-transparent.png';
import BulletScoreYellowTransparent from '../../img/bullet-yellow-transparent.png';
import BulletScoreOrangeTransparent from '../../img/bullet-orange-transparent.png';

import IconFile from '../../img/icon-file.png';
import IconGlasses from '../../img/icon-glasses.png';
import IconBook from '../../img/icon-book.png';
import IconCalculator from '../../img/icon-calculator.png';
import IconPhone from '../../img/icon-phone.png';
import IconPlay from '../../img/icon-play.png';
import CtaFooter from './cta-footer';
import Tooltip from '../tooltip';
import DownloadPDF from './download-pdf';

const resultIcons = flow.RESULT.ICONS;
const icons = {
    [resultIcons.FILE]: IconFile,
    [resultIcons.GLASSES]: IconGlasses,
    [resultIcons.BOOK]: IconBook,
    [resultIcons.CALCULATOR]: IconCalculator,
    [resultIcons.PHONE]: IconPhone,
    [resultIcons.PLAY]: IconPlay
};

const Title = ({ title, subTitle }) => (
    <div>
        <h1 className={styles.title} id="your-results">
            {title}
        </h1>
        <div className={styles.subTitle} id="business-name">
            {subTitle}
        </div>
    </div>
);

Title.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired
};

const scoreClass = flow.RESULT.scoreHelper({
    [flow.RESULT.SCORE.GREEN]: styles.green,
    [flow.RESULT.SCORE.YELLOW]: styles.yellow,
    [flow.RESULT.SCORE.ORANGE]: styles.orange
});

const scoreImg = flow.RESULT.scoreHelper({
    [flow.RESULT.SCORE.GREEN]: OverallScoreGreenImg,
    [flow.RESULT.SCORE.YELLOW]: OverallScoreYellowImg,
    [flow.RESULT.SCORE.ORANGE]: OverallScoreOrangeImg
});

const legendImg = flow.RESULT.scoreHelper({
    [flow.RESULT.SCORE.GREEN]: {
        green: BulletScoreGreen,
        yellow: BulletScoreYellowTransparent,
        orange: BulletScoreOrangeTransparent
    },
    [flow.RESULT.SCORE.YELLOW]: {
        green: BulletScoreGreenTransparent,
        yellow: BulletScoreYellow,
        orange: BulletScoreOrangeTransparent
    },
    [flow.RESULT.SCORE.ORANGE]: {
        green: BulletScoreGreenTransparent,
        yellow: BulletScoreYellowTransparent,
        orange: BulletScoreOrange
    }
});

const bulletScoreImg = flow.RESULT.scoreHelper({
    [flow.RESULT.SCORE.GREEN]: BulletScoreGreen,
    [flow.RESULT.SCORE.YELLOW]: BulletScoreYellow,
    [flow.RESULT.SCORE.ORANGE]: BulletScoreOrange
});

const ScoreCard = ({ overallScore, scoreCardBody }) => {
    const { title, subtitle } = flow.RESULT.scoreHelper(scoreCardBody)(
        overallScore
    );

    const legendImage = legendImg(overallScore);

    return (
        <div className={styles.scoreCard}>
            <div>
                <img src={legendImage.green} alt="" />
                &emsp;
                <img src={legendImage.yellow} alt="" />
                &emsp;
                <img src={legendImage.orange} alt="" />
            </div>
            <br />
            <img
                className={styles.scoreCardBadge}
                src={scoreImg(overallScore)}
                alt=""
            />
            <h2 className={styles.scoreCardTitle}>{title}</h2>
            <div className={styles.scoreCardSubTitle}>{subtitle}</div>
        </div>
    );
};

const scoreCardBodyPropType = PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string
});

ScoreCard.propTypes = {
    overallScore: PropTypes.number.isRequired,
    scoreCardBody: scoreCardBodyPropType.isRequired
};

const BulletPoint = ({ bulletPoints }) => (
    <ul className={styles.bulletPointList}>
        {bulletPoints.map(({ factor, score }) => (
            <li key={factor} className={styles.bulletPoint}>
                <img src={bulletScoreImg(score)} alt="" />
                <span>{factor}</span>
            </li>
        ))}
    </ul>
);

const AssessmentResults = ({ overallScore, bulletPoints, scoreCardBody }) => (
    <div
        className={[
            styles.overallScoreAndBulletpointsBar,
            scoreClass(overallScore)
        ].join(' ')}
    >
        <div className={styles.overallScoreAndBulletPoints}>
            <ScoreCard
                overallScore={overallScore}
                scoreCardBody={scoreCardBody}
            />
            <BulletPoint bulletPoints={bulletPoints} />
        </div>
    </div>
);

AssessmentResults.propTypes = {
    bulletPoints: PropTypes.arrayOf(
        PropTypes.shape({ factor: PropTypes.string, score: PropTypes.number })
    ).isRequired,
    overallScore: PropTypes.number.isRequired,
    scoreCardBody: scoreCardBodyPropType.isRequired
};

const CtaLink = ({ icon, linkText, href }) => {
    const target = href && href.startsWith('#') ? '_self' : '_blank';
    return (
        <li className={styles.ctaLink}>
            <span className={styles.ctaLinkImgWrapper}>
                <img src={icons[icon]} alt={icon} />
            </span>
            {href ? (
                <a href={href} target={target}>
                    {formatText(linkText)}
                </a>
            ) : (
                <span className={styles.ctaLinkPlainText}>
                    {formatText(linkText)}
                </span>
            )}
        </li>
    );
};

const CtaCard = ({ title, ctaLinks, subText }) => (
    <li className={styles.ctaCard}>
        <span className={styles.ctaCardTitle}>
            <h5>{title.text}</h5>
            <Tooltip.Component position="bottom" text={title.tooltip} />
        </span>
        <ul className={styles.ctaCardLinks}>
            {ctaLinks.map(({ icon, linkText, href }) => (
                <CtaLink
                    key={linkText}
                    icon={icon}
                    linkText={linkText}
                    href={href}
                />
            ))}
        </ul>
        <p className={styles.ctaCardSubText}>{subText}</p>
    </li>
);

const CtaSection = ({ score, title, description, ctaCards }) => (
    <li key={title} className={styles.ctaSection}>
        <span className={styles.ctaSectionTitle}>
            <img src={bulletScoreImg(score)} alt="" />
            <h4>{title}</h4>
        </span>
        <p className={styles.ctaSectionDescription}>{description}</p>
        <ul className={styles.ctaCards}>
            {ctaCards.map(ctaCard => (
                <CtaCard
                    key={ctaCard.title.text}
                    title={ctaCard.title}
                    ctaLinks={ctaCard.ctaLinks}
                    subText={ctaCard.subText}
                />
            ))}
        </ul>
    </li>
);

const CtaSections = ({ ctaSections }) => (
    <div className={styles.ctaSectionsWrapper}>
        <h3 className={styles.ctaSectionsTitle}>
            {ctaSections.length > 0
                ? `Areas you could improve on and next steps`
                : ``}
        </h3>
        <ul className={styles.ctaSections}>
            {ctaSections.map(({ score, title, description, ctaCards }) => (
                <CtaSection
                    key={title}
                    score={score}
                    title={title}
                    description={description}
                    ctaCards={ctaCards}
                />
            ))}
        </ul>
    </div>
);

const Checkpoint = ({
    title,
    categoryId,
    assessmentId,
    advisor,
    saveError
}) => (
    <div className={styles.checkpoint}>
        <h3 className={styles.ctaSectionsTitle}>
            Ready for the next assessment?
        </h3>
        <Link
            id="choose-another"
            type="button"
            className="pds-button pds-button-primary"
            onClick={wa.trackEvent('pageNavigationClick', {
                clicktext: 'Choose another assessment',
                navigationsection: 'call to action'
            })}
            to={`/needs/assessment/checkpoint?assessmentId=${assessmentId}`}
            data-gtm="call-to-action"
        >
            Choose another assessment
        </Link>
        {title === undefined ? null : (
            <DownloadPDF
                title={title}
                categoryId={categoryId}
                saveError={saveError}
                assessmentId={assessmentId}
            />
        )}
        {advisor.email && !advisor.isGuided ? (
            <div className={styles.pdfInfoText} id="advisor-emailed-results">
                Your Financial Professional has been emailed a copy of your
                results as well.
            </div>
        ) : null}
    </div>
);

const Results = ({
    assessmentId,
    categoryId,
    editUrl,
    answers,
    advisor,
    saveError,
    showContactFormModal,
    assessmentActions,
    assessmentState
}) => {
    document.title = 'Business Needs Assessment Tool';
    try {
        // TODO - whatever initializes this var takes quite a bit of time.
        // setTimeout(() => console.log(KAMPYLE_ONSITE_SDK), 1100);
        if (
            KAMPYLE_ONSITE_SDK && // eslint-disable-line no-undef
            typeof KAMPYLE_ONSITE_SDK.updatePageView === 'function' // eslint-disable-line no-undef
        ) {
            KAMPYLE_ONSITE_SDK.updatePageView(); // eslint-disable-line no-undef
        }
    } catch (err) {
        console.error(err);
    }

    let result;
    try {
        result = flow.RESULT.generate(categoryId, answers);
    } catch (err) {
        console.error(err);
        return (
            <div className={styles.wrapper}>
                <Title title="Error generating assessment results" />
                <Checkpoint
                    categoryId={categoryId}
                    assessmentId={assessmentId}
                />
            </div>
        );
    }

    useEffect(() => {
        const logMessage = `results page: assessment state advisor for ${assessmentId} - ${JSON.stringify(
            advisor
        )}`;
        fetch(`/needs/api/v1/tasks/log`, {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                logMessage
            })
        });
        if (
            !assessmentState.syncState.firstLoad &&
            assessmentState.advisor.resultEmails[categoryId] === null
        ) {
            if (Date.now() - assessmentState.timestamps[categoryId] < 3000) {
                fetch(`/needs/api/v1/tasks/log`, {
                    method: 'POST',
                    headers: { 'content-type': 'application/json' },
                    body: JSON.stringify({
                        logMessage: `assessment ${assessmentId} for ${categoryId} completed under 3 seconds, email to advisor not sent`
                    })
                });
                assessmentActions.sendResultsEmailToAdvisor(
                    categoryId,
                    assessmentId
                );
                assessmentActions.sendTaskToSalesforce(
                    categoryId,
                    assessmentId
                );
            } else {
                assessmentActions.sendResultsEmailToAdvisor(
                    categoryId,
                    assessmentId
                );
                assessmentActions.sendTaskToSalesforce(
                    categoryId,
                    assessmentId
                );
            }
        } else if (
            !assessmentState.syncState.firstLoad &&
            assessmentState.advisor.resultEmails[categoryId] ===
                'task_pending' &&
            assessmentState.syncState.lastNotificationSentAtWriteCount !==
                assessmentState.syncState.writeCount
        ) {
            assessmentActions.sendTaskToSalesforce(categoryId, assessmentId);
        }
    }, [assessmentState.advisor.resultEmails[categoryId]]);

    return (
        <div className={styles.wrapper}>
            <Link
                className={['pds-link-unstyled', styles.editAnswers].join(' ')}
                to={editUrl}
            >
                Edit your answers
            </Link>
            <Title
                title={`Your results: ${result.title}`}
                subTitle={result.subTitle || ''}
            />
            <AssessmentResults
                overallScore={result.overallScore}
                bulletPoints={result.bulletPoints}
                scoreCardBody={result.scoreCardBody}
            />
            <Checkpoint
                title={result.title}
                categoryId={categoryId}
                assessmentId={assessmentId}
                saveError={saveError}
                advisor={advisor}
            />
            <CtaSections ctaSections={result.ctaSections} />
            {advisor.hasAdvisor ? null : (
                <CtaFooter showContactFormModal={showContactFormModal} />
            )}
        </div>
    );
};

Results.propTypes = {
    categoryId: PropTypes.string.isRequired,
    assessmentId: PropTypes.string,
    answers: PropTypes.objectOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.objectOf(PropTypes.bool)
        ])
    ).isRequired
};

export default Results;
