import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { PAGE_MAP } from '../../constants/index';

import Breadcrumb from '../breadcrumb';

import styles from '../../css/nav.module.css';

const resetState = () => ({
    label: 'Back',
    action: () => history => history.goBack()
});

const NavState = {
    Initial: id => {
        const state = {
            label: 'Back',
            action: history => history.goBack()
        };

        if (id) {
            return { [id]: state };
        }

        return state;
    },
    setLabel: (state, label) => ({ ...state, label }),
    setAction: (state, action) => ({ ...state, action }),
    setLabelAction: (state, label, action) => ({
        ...state,
        label,
        action
    }),
    resetState: state => resetState(state),
    Actions: update => ({
        setNavLabel: (id, label) => update({ [id]: { label } }),
        setNavAction: (id, action) =>
            update({ [id]: { action: () => action } }),
        resetNav: id => update({ [id]: resetState({}) })
    })
};

const callRouteChange = function(maybeAdvisorTitle, pathname) {
    window.dataLayer.push({
        event: 'routeChange',
        pagePath: pathname,
        pageTitle: maybeAdvisorTitle + PAGE_MAP[pathname]
    });
};

const NavControls = ({ state, crumbs, lastCrumb }) => {
    const location = useLocation();
    const { pathname } = location;
    const advisor = state.assessment.advisor;
    const maybeAdvisorTitle = (() => {
        if (PAGE_MAP[pathname] === 'Advisor Landing') {
            return 'Advisor - ';
        }
        if (advisor.hasAdvisor && advisor.isGuided) {
            return 'Advisor Guided - ';
        }
        if (advisor.hasAdvisor && !advisor.isGuided) {
            return 'Advisor Non-Guided - ';
        }
        return 'Direct Path - ';
    })();

    useEffect(() => {
        if (PAGE_MAP[pathname]) {
            setTimeout(() => {callRouteChange(maybeAdvisorTitle, pathname)}, 500);
        }
    }, [pathname]);

    return (
        <nav className={styles.nav} aria-label="Breadcrumb">
            <Breadcrumb.Component crumbs={crumbs.concat({ name: lastCrumb })} />
        </nav>
    );
};

NavControls.propTypes = {
    lastCrumb: PropTypes.string.isRequired,
    crumbs: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired
        })
    ).isRequired
};

export default {
    Component: NavControls,
    State: NavState
};
